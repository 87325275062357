import { t } from "i18next";
import { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import HeaderRowWrapper from "@app/components/RowWrapper";
import { RoutePaths } from "@app/routes/index";
import {
    addSelectedSalesChannels,
    getSalesChannel,
    getSelectedSalesChannel,
} from "@app/services/Endpoints/SelectSalesChannel.API";
import { ModalType, SelectSalesChannelErrors } from "@app/types/Common.types";
import { SalesChannel, SalesChannelData, SalesChannels } from "@app/types/SelectSalesChannel.types";
import { AppContext } from "@app/utils/contexts/App.context";
import { FormContext } from "@app/utils/contexts/Form.context";

const SelectSalesChannelContainer: FC = () => {
    const location = useLocation();
    const { triggerShowModal, setActions, isDirty, setIsDirty } = useContext(FormContext);
    const { userPermissions } = useContext(AppContext);
    const [salesChannel, setSalesChannel] = useState<SalesChannel[]>([]);
    const [salesChannelValues, setSalesChannelValues] = useState<Record<string, string>>({});
    const [, setSelectedSalesChannel] = useState<SalesChannels>({});
    const [checked, setChecked] = useState<string[]>([]);
    const [errors, setErrors] = useState<SelectSalesChannelErrors>();
    const navigate = useNavigate();

    const fetchSalesChannel = useCallback(() => {
        getSalesChannel()
            .then((res) => {
                if (res?.data?.data?.length) {
                    const result = JSON.parse(JSON.stringify(res.data.data));
                    result.sort((a: { name: string }, b: { name: string }) =>
                        a?.name.localeCompare(b?.name),
                    );
                    setSalesChannel([...result]);
                    const salesChannelData = res.data.data.reduce(
                        (acc, channel) => ({ ...acc, [channel.id]: "" }),
                        {},
                    );
                    setSalesChannelValues(salesChannelData);
                }
            })
            .catch();
    }, []);

    const fetchSelectedSalesChannel = useCallback(() => {
        getSelectedSalesChannel()
            .then((res) => {
                if (res) {
                    setSelectedSalesChannel(res.data.data.salesChannels);

                    const keys = Object.keys(res.data.data.salesChannels);
                    setChecked(keys);
                    updateSalesChannel1(keys, res.data.data.salesChannels);
                }
            })
            .catch();
    }, []);

    useEffect(() => {
        fetchSalesChannel();
        fetchSelectedSalesChannel();
    }, [fetchSalesChannel, fetchSelectedSalesChannel]);

    const initialWeightageValue = (channel: SalesChannel[]) => {
        const salesChannelData = channel.reduce(
            (acc, channel) => ({ ...acc, [channel.id]: "" }),
            {},
        );
        setSalesChannelValues(salesChannelData);
    };

    const updateSalesChannel1 = (keys: string[], salesChannels: SalesChannels) => {
        setSalesChannelValues((prevSalesChannel1) => {
            const updatedSalesChannel1 = { ...prevSalesChannel1 };
            for (const key of keys) {
                if (
                    Object.prototype.hasOwnProperty.call(salesChannels, key) &&
                    Object.prototype.hasOwnProperty.call(updatedSalesChannel1, key)
                ) {
                    updatedSalesChannel1[key] = salesChannels[key].weightage.toString();
                }
            }
            return updatedSalesChannel1;
        });
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsDirty(true);
        const { name, value } = e.target;
        if (errors?.Weightage?.includes(name)) {
            setErrors({
                ...errors,
                Weightage: errors.Weightage.filter((item) => item !== name),
            });
        }
        setSalesChannelValues((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleCheckAllChange = () => {
        setIsDirty(true);
        setErrors(undefined);
        if (!(checked.length === salesChannel.length)) {
            const allSalesChannel = salesChannel.map((c) => c.id);
            setChecked(allSalesChannel);
        } else {
            setChecked([]);
        }
    };

    const handleSalesChannelChange = (c: SalesChannel) => {
        setIsDirty(true);
        if (errors?.Weightage?.includes(c.id)) {
            setErrors({
                ...errors,
                Weightage: errors.Weightage.filter((item) => item !== c.id),
            });
        }
        if (checked.includes(c.id)) {
            setChecked(checked.filter((item) => item !== c.id));
        } else {
            setChecked([...checked, c.id]);
        }
    };

    const handleCancel = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                    setErrors(undefined);
                    fetchSelectedSalesChannel();
                    initialWeightageValue(salesChannel);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        }
    };

    const handleBack = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                    setErrors(undefined);
                    initialWeightageValue(salesChannel);
                    navigate(`/${RoutePaths.SelectProducts}`, {
                        state: {
                            NavigateBackFromSalesChannel: "NavigateBackFromSalesChannel",
                        },
                    });
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        } else
            navigate(`/${RoutePaths.SelectProducts}`, {
                state: {
                    NavigateBackFromSalesChannel: "NavigateBackFromSalesChannel",
                },
            });
    };

    const handleAddSalesChannel = (channels: SalesChannelData[]) => {
        addSelectedSalesChannels({ channels })
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                setIsDirty(false);
                if (
                    location.state?.NavigateViaSelectProduct ||
                    location.state?.NavigateBackFromBranchSettings
                ) {
                    navigate(`/${RoutePaths.BranchSettings}`, {
                        state: { NavigateViaSelectSalesChannels: "NavigateViaSelectSalesChannels" },
                    });
                }
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleSubmit = () => {
        const channels: SalesChannelData[] = checked.map((channelId) => ({
            salesChannelId: channelId,
            weightage: Number(salesChannelValues[channelId]) || 0,
        }));

        const isAtLeastOneSelected = checked.length > 0;
        const isValuesValid = checked.every((channelId) => !(salesChannelValues[channelId] === ""));
        const isDataValid = isAtLeastOneSelected && isValuesValid;

        if (isDataValid) {
            setErrors(undefined);
            triggerShowModal(true, ModalType.SUBMIT);
            setActions({
                done: () => handleAddSalesChannel(channels),
                cancel: () => triggerShowModal(false, ModalType.SUBMIT),
            });
        } else {
            const currentErrors: SelectSalesChannelErrors = {
                Weightage: [],
                atLeastOneSelected: "",
            };
            if (!isAtLeastOneSelected)
                currentErrors.atLeastOneSelected = "Please select at least one sales channel.";

            if (!isValuesValid) {
                checked.forEach((channelId) => {
                    if (salesChannelValues[channelId] === "") {
                        currentErrors.Weightage.push(channelId);
                    }
                });
            }

            setErrors({ ...errors, ...currentErrors });
        }
    };

    return (
        <HeaderRowWrapper
            heading={`${t("SELECTSALESCHANNEL.HEADING")}`}
            headerName={[
                `${t("SELECTSALESCHANNEL.CHANNEL")}`,
                `${t("SELECTSALESCHANNEL.SERVICEGOALTIME")}`,
            ]}
            checked={checked}
            salesChannel={salesChannel}
            salesChannelValues={salesChannelValues}
            onClickHeaderCheckBox={handleCheckAllChange}
            onClickRowCheckBox={handleSalesChannelChange}
            OnChangeInputFields={handleInputChange}
            OnClickCancel={handleCancel}
            OnClickSubmit={handleSubmit}
            OnClickBack={handleBack}
            submitDisabled={!isDirty}
            error={errors}
            hideSubmit={!(userPermissions && userPermissions["Create_Kitchen Setup"]) || false}
        />
    );
};
export default SelectSalesChannelContainer;
