import { createContext, FC, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { RoutePaths } from "@app/routes/index";
import { Branches } from "@app/types/Service.type";

interface LicenseeContextProviderProps {
    children: ReactNode;
}

interface LicenseeContextOutput {
    selectedBranch?: Branches;
    setSelectedBranch: (_val: Branches) => void;
}

export const LicenseeContext = createContext({} as LicenseeContextOutput);

const LicenseeContextProvider: FC<LicenseeContextProviderProps> = ({ children }) => {
    const navigate = useNavigate();
    const [selectedBranch, setSelectedBranch] = useState<Branches>();

    useEffect(() => {
        const branch = localStorage.getItem("SelectedBranch");
        if (branch) setSelectedBranch(JSON.parse(branch));
        else navigate(`/${RoutePaths.LicenseeBranches}`);
    }, [navigate]);

    return (
        <LicenseeContext.Provider
            value={{
                selectedBranch,
                setSelectedBranch,
            }}
        >
            {children}
        </LicenseeContext.Provider>
    );
};

export default LicenseeContextProvider;
