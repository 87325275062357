import { MasterAPIService } from "@app/services/Methods/api";
import { BranchResponse, passKeyResponse } from "@app/types/API.types";
import { Branch } from "@app/types/Branch.types";

export const getBranchesData = (
    sortBy?: string,
    sortOrder?: number,
    pageNumber?: number,
    pageSize?: number,
    name?: string,
    filters?: string,
) =>
    MasterAPIService.get(
        `/branch/search?${sortBy ? `sortBy=${sortBy}&` : ""}${
            sortOrder ? `sortOrder=${sortOrder}&` : ""
        }${pageNumber ? `pageNumber=${pageNumber}&` : ""}${
            pageSize ? `pageSize=${pageSize}&` : ""
        }${name ? `name=${name}&` : ""}${filters ? filters : ""}`,
    );

export const createBranch = (data: Partial<Branch>) => MasterAPIService.post("branch", data);

export const updateBranch = (data: Partial<Branch>) => MasterAPIService.patch("branch", data);

export const getBranchDetails = (id: string) =>
    MasterAPIService.get<BranchResponse>(`branch/${id}`);

export const getSingleUserBranches = (id: string) =>
    MasterAPIService.get(`branch/GetSingleUserBranches?userId=${id}`);

    
export const getPasskey = (id: string) =>
MasterAPIService.get<passKeyResponse>(`passkey/${id}`);
