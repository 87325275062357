import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MachineContainer from "@app/containers/machine";
import { RoutePaths } from "@app/routes/index";

const EditMachinePage: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location.state?.machineEditData) {
            navigate(RoutePaths.ViewMachines);
        }
    }, [location.state, navigate]);

    return <MachineContainer machine={location.state?.machineEditData} />;
};

export default EditMachinePage;
