import { t } from "i18next";
import { FC, useEffect, useState } from "react";

import Icons from "@app/assets/Icons";
import "./index.scss";

interface PaginationProps {
    pageSize: number;
    totalPages: number;
    onPageChange: (_page: number) => void;
    className?: string;
    sortByObj?: { sortBy: string; sortOrder: number };
    pageNumber?: number;
}

const Pagination: FC<PaginationProps> = ({
    pageSize,
    totalPages,
    className,
    onPageChange,
    sortByObj,
    pageNumber,
}) => {
    const [currentPage, setCurrentPage] = useState(1);

    const onNext = () => {
        setCurrentPage((prevPage: number) => prevPage + 1);
    };

    const onPrevious = () => {
        setCurrentPage((prevPage: number) => prevPage - 1);
    };
    useEffect(() => {
        if (currentPage >= 1 && currentPage <= totalPages) onPageChange(Number(currentPage));
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(1);
    }, [pageSize, sortByObj]);
    useEffect(() => {
        if (pageNumber) setCurrentPage(pageNumber);
    }, [pageNumber]);

    const enterPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (Number(value) <= totalPages) setCurrentPage(Number(event.target.value));
    };

    return (
        <div className={className}>
            <ul className='pagination__container' data-testid='pagination-container'>
                <li
                    className={`pagination__mt4 ${
                        currentPage === 1 || currentPage === 0
                            ? "pagination__container--disabled"
                            : ""
                    }`}
                    onClick={() => setCurrentPage(1)}
                >
                    <img src={Icons.BackArrowIcon} />
                    <img src={Icons.BackArrowIcon} /> {t("PAGINATION.FIRST")}
                </li>
                <li
                    className={`pagination__mt4 ${
                        currentPage === 1 || currentPage === 0
                            ? "pagination__container--disabled"
                            : ""
                    }`}
                    onClick={onPrevious}
                    data-testid='previous-cta'
                >
                    <img src={Icons.BackArrowIcon} /> {t("PAGINATION.PREV")}
                </li>
                <li className='pagination__input-container'>
                    <span>{t("PAGINATION.PAGE")} </span>
                    <input
                        name='current-page'
                        data-testid='current-page'
                        type='text'
                        value={totalPages ? currentPage || " " : "0"}
                        onChange={enterPage}
                    />
                    <span> {t("PAGINATION.OF")} </span>
                    <span data-testid='total-pages'>{totalPages}</span>
                </li>

                <li
                    className={`pagination__mt4 ${
                        currentPage === totalPages || totalPages === 0
                            ? "pagination__container--disabled"
                            : ""
                    }`}
                    onClick={onNext}
                    data-testid='next-cta'
                >
                    {t("PAGINATION.NEXT")} <img src={Icons.ForwardArrowIcon} />
                </li>
                <li
                    className={`pagination__mt4 ${
                        currentPage === totalPages || totalPages === 0
                            ? "pagination__container--disabled"
                            : ""
                    }`}
                    onClick={() => setCurrentPage(totalPages)}
                >
                    {t("PAGINATION.LAST")} <img src={Icons.ForwardArrowIcon} />
                    <img src={Icons.ForwardArrowIcon} />
                </li>
            </ul>
        </div>
    );
};

export default Pagination;
