import { FC, useContext, useEffect, useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";

import Checkbox from "@app/components/inputs/Checkbox";
import { getMachineBaskets, getMachineTypes } from "@app/services/Endpoints/Machine.API";
import { getMachineMake } from "@app/services/Endpoints/SelectMachines.API";
import { getStations } from "@app/services/Endpoints/Station";
import { Station, StationList } from "@app/types/Station.types";
import { FilterContext } from "@app/utils/contexts/Filters.context";

const MachineFilters: FC = () => {
    const {
        handleStatusesFilter,
        statusesFilter,
        setStatusesFilter,
        machineMakesFilter,
        setMachineMakesFilter,
        handleMachineMakesFilter,
        stationsFilter,
        setStationsFilter,
        handleStationsFilter,
        machineTypesFilter,
        setMachineTypesFilter,
        handleMachineTypesFilter,
        basketsFilter,
        setBasketsFilter,
        handleBasketsFilter,
    } = useContext(FilterContext);

    const [machineMake, setMachineMake] = useState<{ machineMake: string; id: string }[]>([]);
    const [machineTypes, setMachineTypes] = useState<{ name: string; id: string }[]>([]);
    const [cookingStations, setCookingStations] = useState<Station[]>([]);
    const [machineBaskets, setMachineBaskets] = useState<number[]>([]);
    const [key, setKey] = useState<string | undefined | null>();

    useEffect(() => {
        getMachineMake().then((res) => setMachineMake(res.data.data));

        getMachineTypes()
            .then((res) => setMachineTypes([...res.data.data]))
            .catch(() => setMachineTypes([]));

        getStations().then((res) => {
            const cookingStations =
                res?.data?.data?.find((station: StationList) => station.stationType === "Cooking")
                    .stations ?? [];
            setCookingStations([...cookingStations]);
        });
        getMachineBaskets()
            .then((res) => setMachineBaskets([...res.data.data]))
            .catch(() => setMachineBaskets([]));
    }, []);

    return (
        <Accordion
            activeKey={key}
            onSelect={(k) => setKey(String(k))}
            flush
            className='filters__item'
        >
            <Accordion.Item eventKey='0' className=''>
                <Accordion.Header className='filters__item-header'>
                    <span>Status</span>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            position: "absolute",
                            right: "20%",
                        }}
                        variant='text'
                        onClick={(e) => {
                            e.stopPropagation();
                            setStatusesFilter(undefined);
                        }}
                    >
                        Clear
                    </Button>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        <Col xs={12} className='filters__checkbox'>
                            <Checkbox
                                label='Active'
                                checked={!!statusesFilter?.includes(true)}
                                onClick={() => handleStatusesFilter(true)}
                            />
                        </Col>
                        <Col xs={12} className='filters__checkbox'>
                            <Checkbox
                                label='Inactive'
                                checked={!!statusesFilter?.includes(false)}
                                onClick={() => handleStatusesFilter?.(false)}
                            />
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
                <Accordion.Header className='filters__item-header'>
                    <span>Machine Make</span>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            position: "absolute",
                            right: "20%",
                        }}
                        variant='text'
                        onClick={() => setMachineMakesFilter(undefined)}
                    >
                        Clear
                    </Button>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        {machineMake?.map((item, index) => (
                            <Col key={index} xs={12} className='filters__checkbox'>
                                <Checkbox
                                    label={item.machineMake}
                                    checked={!!machineMakesFilter?.includes(item.machineMake)}
                                    onClick={() => handleMachineMakesFilter(item.machineMake)}
                                />
                            </Col>
                        ))}
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='2'>
                <Accordion.Header className='filters__item-header'>
                    <span>Station</span>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            position: "absolute",
                            right: "20%",
                        }}
                        variant='text'
                        onClick={() => setStationsFilter(undefined)}
                    >
                        Clear
                    </Button>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        {cookingStations?.map((item, index) => (
                            <Col key={index} xs={12} className='filters__checkbox'>
                                <Checkbox
                                    label={item.displayName}
                                    checked={!!stationsFilter?.includes(item.stationId)}
                                    onClick={() => handleStationsFilter?.(item.stationId)}
                                />
                            </Col>
                        ))}
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='3'>
                <Accordion.Header className='filters__item-header'>
                    <span>Machine Type</span>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            position: "absolute",
                            right: "20%",
                        }}
                        variant='text'
                        onClick={() => setMachineTypesFilter(undefined)}
                    >
                        Clear
                    </Button>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        {machineTypes?.map((item, index) => (
                            <Col key={index} xs={12} className='filters__checkbox'>
                                <Checkbox
                                    label={item.name}
                                    checked={!!machineTypesFilter?.includes(item.name)}
                                    onClick={() => handleMachineTypesFilter(item.name)}
                                />
                            </Col>
                        ))}
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='4'>
                <Accordion.Header className='filters__item-header'>
                    <span>Basket</span>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            position: "absolute",
                            right: "20%",
                        }}
                        variant='text'
                        onClick={() => setBasketsFilter(undefined)}
                    >
                        Clear
                    </Button>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        {machineBaskets?.map((item, index) => (
                            <Col key={index} xs={12} className='filters__checkbox'>
                                <Checkbox
                                    label={String(item)}
                                    checked={!!basketsFilter?.includes(String(item))}
                                    onClick={() => handleBasketsFilter(String(item))}
                                />
                            </Col>
                        ))}
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default MachineFilters;
