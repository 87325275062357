import { t } from "i18next";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FormWrapper from "@app/components/FormWrapper";
import LicenseeForm from "@app/components/forms/Licensee.form";
import { RoutePaths } from "@app/routes/index";
import {
    createLicensee,
    getLicenseeCountries,
    updateLicensee,
} from "@app/services/Endpoints/Licensee.API";
import { getRoles, getUserDetails } from "@app/services/Endpoints/User.API";
import { Errors, ModalType, ScreenType } from "@app/types/Common.types";
import {
    createLicenseeUser,
    Licensee,
    LicenseeAdminListInterface,
    LicenseeFormData,
} from "@app/types/Licensee";
import { UserDetails } from "@app/types/User.type";
import { FormContext } from "@app/utils/contexts/Form.context";

const initialFormData: LicenseeFormData = {
    name: "",
    id: "",
    countryId: "",
    admin: "",
    roleId: "",
    code: "",
    isActive: false,
};

const intialUserDetails: Partial<UserDetails> = {
    displayName: "",
    mail: "",
    mobilePhone: "",
};

interface LicenseeContainerProps {
    licensee?: Licensee;
}

const LicenseeContainer: FC<LicenseeContainerProps> = ({ licensee }) => {
    const navigate = useNavigate();

    const { triggerShowModal, setActions, isDirty, setIsDirty, setScreen } =
        useContext(FormContext);

    const [countries, setCountries] = useState<{ name: string; id: string }[]>([]);
    const [roles, setRoles] = useState<{ name: string; id: string }[]>([]);
    const [newLicenseeData, setNewLicenseeData] = useState<LicenseeFormData>(initialFormData);
    const [errors, setErrors] = useState<Partial<Errors>>();
    const [currentAdminDetails, setCurrentAdminDetails] =
        useState<Partial<UserDetails>>(intialUserDetails);
    const [licenseeAdminList, setLicenseeAdminList] = useState<LicenseeAdminListInterface>({});
    const [disableAddAdmin, setDisableAddAdmin] = useState<boolean>(true);

    const fetchCountries = useCallback(() => {
        getLicenseeCountries()
            .then((res) => setCountries([...res.data.data]))
            .catch(() => setCountries([]));
    }, []);
    const fetchUserRoles = useCallback(() => {
        getRoles(2)
            .then((res) => {
                const result = res?.data?.data;
                if (result) {
                    setRoles([...result]);
                }
            })
            .catch((err) => console.error(err));
    }, []);

    const fetchUserDetails = (value: string) => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const currentErrors: Partial<Errors> = { ...errors };
        currentErrors.admin = `${t("ERRORS.EMAIL_DONOT_EXIST")}`;
        setCurrentAdminDetails(intialUserDetails);
        if (value.match(emailRegex)) {
            getUserDetails(value)
                .then((res) => {
                    const result = res?.data?.data;
                    if (result[0]) {
                        setNewLicenseeData({ ...newLicenseeData, admin: result[0]?.mail });
                        setDisableAddAdmin(false);
                        setCurrentAdminDetails(result[0]);
                    } else {
                        setErrors({ ...errors, ...currentErrors });
                    }
                })
                .catch(() => {
                    setErrors({ ...errors, ...currentErrors });
                    setCurrentAdminDetails(intialUserDetails);
                });
        } else {
            currentErrors.admin = `${t("ERRORS.VALID_EMAIL")}`;

            setErrors({ ...errors, ...currentErrors });
            setCurrentAdminDetails(intialUserDetails);
        }
    };

    useEffect(() => {
        if (licensee) {
            const { name, id, countryId, isActive, users, code } = licensee;
            const tempLicenseeAdminList: LicenseeAdminListInterface = {};

            users.forEach(
                (user: { id: string; name: string; phoneNumber: string; roleId: string }) => {
                    tempLicenseeAdminList[user.id] = {
                        mail: user.id,
                        displayName: user.name,
                        mobilePhone: user.phoneNumber,
                        roleId: user.roleId,
                    };
                },
            );

            setNewLicenseeData({
                name,
                id,
                countryId,
                isActive,
                code,
            });

            setLicenseeAdminList({ ...tempLicenseeAdminList });
        }
    }, [licensee]);

    useEffect(() => {
        fetchCountries();
        fetchUserRoles();
    }, [fetchCountries, fetchUserRoles]);

    const handleChange = (name: string, value: string | string[] | boolean) => {
        const data: LicenseeFormData = { ...newLicenseeData };
        if (errors?.[name]) {
            setErrors({ ...errors, [name]: "" });
        }

        if (licenseeAdminList[name]) {
            licenseeAdminList[name].roleId = value + "";
            setLicenseeAdminList({ ...licenseeAdminList });
        }
        setNewLicenseeData({ ...data, [name]: value });
    };

    const handleBlur = (value: string) => {
        fetchUserDetails(value);
    };

    const addAdminHandler = () => {
        const { roleId, admin } = newLicenseeData;
        const currentErrors: Partial<Errors> = {};
        if (licenseeAdminList[String(currentAdminDetails.mail)]) {
            currentErrors.admin = `${t("ERRORS.ALREADY_EXIST")}`;
            setErrors({ ...errors, ...currentErrors });
        } else if (roleId && admin) {
            const obj = {
                [String(currentAdminDetails.mail)]: { ...currentAdminDetails, roleId },
            };
            setLicenseeAdminList({ ...licenseeAdminList, ...obj });
            const data: LicenseeFormData = { ...newLicenseeData };
            setNewLicenseeData({ ...data, roleId: "", admin: "" });
            setCurrentAdminDetails(intialUserDetails);
            setDisableAddAdmin(true);
        } else {
            if (!admin) {
                currentErrors.admin = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.LICENSEE.ADMIN.LABEL"),
                })}`;
                setErrors({ ...errors, ...currentErrors });
            } else if (!roleId) {
                currentErrors.roleId = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.LICENSEE.ROLE.LABEL"),
                })}`;
                setErrors({ ...errors, ...currentErrors });
            }
        }
    };

    const onDeleteAdmin = (item: Partial<UserDetails>) => {
        const { mail } = item;
        const obj = { ...licenseeAdminList };
        delete obj[String(mail)];
        setLicenseeAdminList({ ...obj });
    };

    const handleCancel = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    setNewLicenseeData(initialFormData);
                    setErrors(undefined);
                    navigate(-1);
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        } else navigate(RoutePaths.ViewLicensees);
    };

    const postObj = () => {
        const { name, code, countryId, admin, roleId } = newLicenseeData;
        const { displayName, mobilePhone } = currentAdminDetails;
        const adminUsersMap: createLicenseeUser[] = [];
        Object.keys(licenseeAdminList)?.map((key) => {
            const item = licenseeAdminList[key];
            const tempObj = {
                id: item.mail || "",
                name: item.displayName || "",
                phoneNumber: item.mobilePhone || "",
                roleId: item.roleId || "",
            };
            adminUsersMap.push(tempObj);
        });

        if (admin && roleId) {
            adminUsersMap.push({
                id: admin || "",
                name: displayName || "",
                phoneNumber: mobilePhone || "",
                roleId: roleId || "",
            });
        }

        const postObj = { name, countryId, code, users: adminUsersMap };
        return postObj;
    };

    const handleCreateLicensee = () => {
        createLicensee({ ...postObj() })
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                navigate(RoutePaths.ViewLicensees);
                setIsDirty(false);
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                setErrors({
                    ...errors,
                });
            });
    };

    const handleUpdateLicensee = () => {
        const tempObj = {
            ...postObj(),
            id: newLicenseeData.id,
            isActive: newLicenseeData.isActive,
            code: newLicenseeData.code,
        };

        updateLicensee({
            ...tempObj,
        })
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                navigate(RoutePaths.ViewLicensees);
                setIsDirty(false);
            })
            .catch((error) => {
                triggerShowModal(false, ModalType.SUBMIT);
                console.log(error);
            });
    };
    const handleSubmit = () => {
        const { name, code, countryId, admin, roleId } = newLicenseeData;
        const isLicenseeNameValid = !!name;
        const isLicenseeIDValid = !!code;
        const isCountryValid = !!countryId;
        let isLicenseeAdminValid = !!admin;
        let isLicenseeRoleValid = !!roleId;
        const isInValidEmail = admin && disableAddAdmin;
        if (Object.keys(licenseeAdminList).length) {
            isLicenseeAdminValid = true;
            isLicenseeRoleValid = true;
        }
        const isDataValid =
            isLicenseeNameValid &&
            isLicenseeIDValid &&
            isCountryValid &&
            isLicenseeAdminValid &&
            isLicenseeRoleValid;

        if (isDataValid && !isInValidEmail) {
            setErrors(undefined);
            if (licensee && licensee.isActive && !newLicenseeData.isActive) {
                setScreen(ScreenType.LICENSEE);
                triggerShowModal(true, ModalType.INACTIVE_SUBMISSION);
                setActions({
                    done: () => {
                        setScreen(undefined);
                        triggerShowModal(false, ModalType.INACTIVE_SUBMISSION);
                        handleUpdateLicensee();
                        setIsDirty(false);
                    },
                    cancel: () => {
                        triggerShowModal(false, ModalType.INACTIVE_SUBMISSION);
                        setScreen(undefined);
                        setNewLicenseeData({ ...newLicenseeData, isActive: true });
                    },
                });
            } else {
                triggerShowModal(true, ModalType.SUBMIT);
                setActions({
                    done: () => (licensee ? handleUpdateLicensee() : handleCreateLicensee()),
                    cancel: () => triggerShowModal(false, ModalType.SUBMIT),
                });
            }
        } else {
            const currentErrors: Partial<Errors> = {};
            if (!isLicenseeNameValid)
                currentErrors.name = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.LICENSEE.NAME.LABEL"),
                })}`;

            if (!isLicenseeIDValid)
                currentErrors.code = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.LICENSEE.ID.LABEL"),
                })}`;
            if (!isLicenseeAdminValid)
                currentErrors.admin = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.LICENSEE.ADMIN.LABEL"),
                })}`;
            if (!isLicenseeRoleValid)
                currentErrors.roleId = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.LICENSEE.ROLE.LABEL"),
                })}`;
            if (!isCountryValid)
                currentErrors.countryId = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.LICENSEE.COUNTRY.LABEL"),
                })}`;
            setErrors({ ...errors, ...currentErrors });
        }
    };

    return (
        <FormWrapper
            heading={
                window.location.href.includes("edit")
                    ? "Edit licensee details"
                    : t("ADD.LICENSEE.HEADING")
            }
            onCancelClick={handleCancel}
            onSubmitClick={handleSubmit}
            submitDisabled={!isDirty}
        >
            <LicenseeForm
                data={newLicenseeData}
                onChange={handleChange}
                onBlur={handleBlur}
                countryOptions={countries?.map(({ id, name }) => ({ value: id, label: name }))}
                rolesOptions={roles?.map(({ id, name }) => ({ value: id, label: name }))}
                errors={errors}
                isEdit={!!licensee}
                licenseeAdminList={licenseeAdminList}
                currentAdminDetails={currentAdminDetails}
                addAdmin={addAdminHandler}
                onDeleteAdmin={onDeleteAdmin}
                disableAddAdmin={disableAddAdmin}
            />
        </FormWrapper>
    );
};

export default LicenseeContainer;
