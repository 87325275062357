// time format = hh:mm:ss
export const getTimeInSeconds = (time: string) => {
    if (time) {
        const [hours, minutes, seconds] = time.split(":");
        return Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);
    }
    return 0;
};

export const getSecondsInTime = (seconds: number) => {
    if (seconds) {
        return new Date(seconds * 1000).toISOString().substring(11, 19);
    }
    return "00:00:00";
};
