import { t } from "i18next";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import Icons from "@app/assets/Icons";
import LoadingButton from "@app/components//Button";
import Loader from "@app/components/Loader";
import BootstrapModal from "@app/components/Modal";
import AutoComplete from "@app/components/inputs/Autocomplete";
import SelectInput from "@app/components/inputs/Select";
import TextInput from "@app/components/inputs/Text";
import Toggle from "@app/components/inputs/Toggle";
import { getPasskey } from "@app/services/Endpoints/Branches.API";
import { Errors, SelectOption } from "@app/types/Common.types";
import { Licensee } from "@app/types/Licensee";
import { Branches } from "@app/types/Service.type";
import { Role, User, UserBranch, UserLevel } from "@app/types/User.type";
import { AppContext } from "@app/utils/contexts/App.context";
import { FormContext } from "@app/utils/contexts/Form.context";

import "./index.scss";

interface UserFormProps {
    data: User;
    roles: Role[];
    licensees: Licensee[];
    branches: Branches[];
    loadingUser: boolean;
    errors?: Partial<Errors>;
    isEdit?: boolean;
    onChange: (
        _name: string,
        _value: string | boolean | UserBranch[],
        _fromRemove?: boolean,
    ) => void;
    onBlur: (_value: string) => void;
}

const UserForm: FC<UserFormProps> = ({
    data,
    roles,
    licensees,
    branches,
    loadingUser,
    errors,
    isEdit,
    onChange,
    onBlur,
}) => {
    const [viewPassKeyModal, setViewPassKeyModal] = useState(false);
    const [passKeyModalObj, setPasseKeyModalObj] = useState({ email: "", passKey: "" });
    const { setIsDirty } = useContext(FormContext);
    const { userPermissions } = useContext(AppContext);
    const [branchOptions, setBranchOptions] = useState<
        { value: string; label: string; isdisabled?: boolean }[]
    >([]);
    const selectedBranches = data?.branches?.map((uB) => uB.branchID);

    useEffect(() => {
        const branchOptions = [
            ...(branches?.map((branch) => {
                if (selectedBranches?.includes(branch.id)) {
                    return {
                        value: branch.id,
                        label: branch.name,
                        isdisabled: true,
                    };
                } else {
                    return {
                        value: branch.id,
                        label: branch.name,
                        isdisabled: false,
                    };
                }
            }) ?? []),
        ];
        setBranchOptions(branchOptions);
    }, [branches]);

    useEffect(() => {
        branchOptions.forEach((branch) => {
            if (selectedBranches?.includes(branch.value)) {
                branch.isdisabled = true;
            } else {
                branch.isdisabled = false;
            }
        });
        setBranchOptions([...branchOptions]);
    }, [data?.branches]);

    const userLevelOptions = Object.values(UserLevel)
        .map((key, index, array) => {
            if (typeof key === "string") {
                return {
                    value: String(array[array.length / 2 + index]),
                    label: t(`USER_ROLE.LEVEL.${key.toUpperCase()}`),
                };
            }
        })
        .filter(Boolean) as { value: string; label: string }[];

    const roleOptions = [
        { value: "", label: t("ADD.USER.ROLE.PLACEHOLDER") },
        ...(roles
            ?.filter((role) => role.isActive)
            ?.map((role) => ({
                value: role.id,
                label: role.name,
            })) ?? []),
    ];

    const licenseeOptions = [
        { value: "", label: t("ADD.USER.LICENSEE.PLACEHOLDER") },
        ...(licensees?.map((licensee) => ({
            value: licensee.id,
            label: licensee.name,
        })) ?? []),
    ];

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setIsDirty(true);
        const { name, value } = e.target;
        onChange(name, value);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target;
        onBlur(value);
    };

    const handleBranchChange = (e: ChangeEvent<HTMLSelectElement>, index: number) => {
        setIsDirty(true);
        const { name, value } = e.target;
        const branchData = data?.branches ?? [];
        const currentBranch = branchData[index];
        if (name === "branchID") currentBranch.branchID = value;
        if (name === "roleID") currentBranch.roleID = value;
        branchData[index] = currentBranch;
        onChange("branches", branchData);
    };

    const handleAutoComplete = (option: SelectOption, index: number) => {
        setIsDirty(true);
        const { value } = option;
        const branchData = data?.branches ?? [];
        const currentBranch = branchData[index];
        currentBranch.branchID = value;
        branchData[index] = currentBranch;
        onChange("branches", branchData);
        const selectedBranches = branchData.map((uB) => uB.branchID);
        branchOptions.forEach((branch) => {
            if (selectedBranches?.includes(branch.value)) {
                branch.isdisabled = true;
            } else {
                branch.isdisabled = false;
            }
        });
        setBranchOptions([...branchOptions]);
    };

    const handleAdd = () =>
        onChange("branches", [...(data?.branches ?? []), { branchID: "", roleID: "" }]);

    const handleRemove = (indexToRemove: number, fromRemove: boolean) => {
        const branchData = (data?.branches ?? []).filter((_, index) => index !== indexToRemove);
        setIsDirty(true);
        onChange("branches", branchData, fromRemove);
    };

    const manageAccessHandler = (email: string) => {
        if (email) {
            getPasskey(email)
                .then((res) => {
                    setViewPassKeyModal(true);
                    setPasseKeyModalObj({ email: email, passKey: res.data.data });
                })
                .catch(() => setPasseKeyModalObj({ email: "", passKey: "" }));
        }
    };

    return (
        <Container fluid className='py-4 px-5 users-form'>
            <Row className='mb-5'>
                <Col xs={8}>
                    <TextInput
                        label={`${t("ADD.USER.EMAIL.LABEL")}`}
                        placeholder={t("ADD.USER.EMAIL.PLACEHOLDER")}
                        name='id'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={data?.id ?? ""}
                        error={errors?.id}
                        disabled={isEdit}
                    />
                </Col>
                <Col xs={4} className='mt-4'>
                    {loadingUser ? (
                        <Loader size='sm' />
                    ) : (
                        <>
                            <div className='role-form__userInfo'>{data.name}</div>
                            <div className='role-form__userInfo'>{data.phoneNumber}</div>
                        </>
                    )}
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col xs={8}>
                    <SelectInput
                        label={t("ADD.USER.LEVEL.LABEL")}
                        value={String(data?.userLevel)}
                        onChange={handleChange}
                        name='userLevel'
                        options={userLevelOptions}
                        error={errors?.userLevel}
                    />
                </Col>
                {Number(data?.userLevel) == UserLevel.Branch && (
                    <Col xs={2} className='pt-4 mt-1'>
                        <LoadingButton
                            btnClass='add-branch__loading-button add-branch__manageAccessBtn'
                            variant='upload'
                            label='Manage Access'
                            fullWidth
                            onClick={() => manageAccessHandler(data?.id || "")}
                            // onClick={console.log}
                            disabled={!data?.id}
                            // disabled
                        />
                    </Col>
                )}
            </Row>
            <Row className='mb-5'>
                {Number(data?.userLevel) == UserLevel.Licensor && (
                    <Col xs={8}>
                        <SelectInput
                            label={t("ADD.USER.ROLE.LABEL")}
                            value={String(data?.roleID ?? "")}
                            onChange={handleChange}
                            name='roleID'
                            options={roleOptions}
                            error={errors?.roleID}
                        />
                    </Col>
                )}
                {Number(data?.userLevel) == UserLevel.Licensee && (
                    <>
                        <Col xs={6}>
                            <SelectInput
                                label={t("ADD.USER.LICENSEE.LABEL")}
                                value={String(data?.licenseeID ?? "")}
                                onChange={handleChange}
                                name='licenseeID'
                                options={licenseeOptions}
                                error={errors?.licenseeID}
                            />
                        </Col>
                        <Col xs={6}>
                            <SelectInput
                                label={t("ADD.USER.ROLE.LABEL")}
                                value={String(data?.roleID ?? "")}
                                onChange={handleChange}
                                name='roleID'
                                options={roleOptions}
                                error={errors?.roleID}
                            />
                        </Col>
                    </>
                )}
                <Container fluid>
                    {Number(data?.userLevel) == UserLevel.Branch && Array.isArray(data?.branches)
                        ? data?.branches?.map((branch, index, allBranches) => (
                              <Row
                                  key={`branch_${branch.branchID}_${branch.roleID}_${index}`}
                                  className='align-items-end mb-3'
                              >
                                  <Col xs={4}>
                                      <AutoComplete
                                          label={t("ADD.USER.BRANCH.LABEL")}
                                          value={branch.branchID}
                                          onChange={(e) => handleAutoComplete(e, index)}
                                          name='branchID'
                                          options={branchOptions}
                                          //   error={errors?.branches}
                                          placeholder={`${t("ADD.USER.BRANCH.PLACEHOLDER")}`}
                                      />
                                  </Col>
                                  <Col xs={4} className='mr-1'>
                                      <SelectInput
                                          label={t("ADD.USER.ROLE.LABEL")}
                                          value={branch.roleID}
                                          onChange={(e) => handleBranchChange(e, index)}
                                          name='roleID'
                                          options={roleOptions}
                                          //   error={errors?.branches}
                                      />
                                  </Col>
                                  <Col xs={4}>
                                      {index !== allBranches.length - 1 ? (
                                          <LoadingButton
                                              onClick={() => handleRemove(index, true)}
                                              icon={
                                                  <img
                                                      src={Icons.DustbinIcon}
                                                      className='add-product__dustbin-icon'
                                                  />
                                              }
                                              btnClass='add-branch__loading-button add-branch__manageAccessBtn'
                                              variant='upload'
                                              label={t("COMMON.DELETE")}
                                          />
                                      ) : (
                                          <LoadingButton
                                              onClick={() => {
                                                  handleAdd();
                                              }}
                                              icon={
                                                  <img
                                                      src={Icons.PlusIcon}
                                                      className='add-product__dustbin-icon'
                                                  />
                                              }
                                              btnClass='add-branch__loading-button add-branch__manageAccessBtn'
                                              variant='upload'
                                              label={t("COMMON.ADD", {
                                                  name: t("ADD.USER.BRANCH.LABEL"),
                                              })}
                                          />
                                      )}
                                  </Col>
                              </Row>
                          ))
                        : null}
                    <Row>
                        <Col xs={4}>
                            {errors?.bLBranchID && (
                                <Form.Text className='select-input__error'>
                                    <img src={Icons.ErrorGreyIcon} />{" "}
                                    <span>{errors?.bLBranchID}</span>
                                </Form.Text>
                            )}
                        </Col>
                        <Col xs={4}>
                            {errors?.bLRoleID && (
                                <Form.Text className='select-input__error'>
                                    <img src={Icons.ErrorGreyIcon} />{" "}
                                    <span>{errors?.bLRoleID}</span>
                                </Form.Text>
                            )}
                        </Col>
                    </Row>
                    {errors?.branchRequired && (
                        <Form.Text className='select-input__error'>
                            <img src={Icons.ErrorGreyIcon} /> <span>{errors?.branchRequired}</span>
                        </Form.Text>
                    )}
                </Container>
            </Row>
            {isEdit && userPermissions?.Deactivate_User && (
                <Row className='mt-4'>
                    <Col>
                        <Form.Group>
                            <Form.Label className='text-input__label'>
                                {t("MACHINE.CREATE.STATUS.LABEL")}
                            </Form.Label>
                            <Toggle
                                isActive={data?.isActive}
                                onToggle={() => {
                                    setIsDirty(true);
                                    onChange("isActive", !data?.isActive);
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            )}
            <BootstrapModal
                header='View PassKey'
                open={viewPassKeyModal}
                onClose={() => setViewPassKeyModal(false)}
            >
                <Container>
                    <Row className='mt-4'>
                        <TextInput
                            type='text'
                            label='User ID'
                            value={passKeyModalObj.email}
                            disabled
                            placeholder=''
                        />
                    </Row>
                    <Row className='add-branch__viewPassKeyField'>
                        <TextInput
                            type='text'
                            label='Passkey'
                            value={passKeyModalObj.passKey}
                            disabled
                            placeholder=''
                        />
                        <img
                            className='add-branch__copyIcon'
                            src={Icons.copyIcon}
                            onClick={() => navigator.clipboard.writeText(passKeyModalObj.passKey)}
                        />
                    </Row>
                    <Row className='add-branch__viewPasskeyBtns'>
                        <LoadingButton
                            variant='add'
                            label='Done'
                            onClick={() => setViewPassKeyModal(false)}
                        />
                    </Row>
                </Container>
            </BootstrapModal>
        </Container>
    );
};

export default UserForm;
