import { FC, ReactElement, useContext } from "react";
import { Accordion, Button } from "react-bootstrap";

import Icons from "@app/assets/Icons";
import "./index.scss";
import { FilterContext } from "@app/utils/contexts/Filters.context";

interface FilterWrapperProps {
    children: ReactElement;
}

const FilterWrapper: FC<FilterWrapperProps> = ({ children }) => {
    const { clearAll } = useContext(FilterContext);
    return (
        <div className='filters'>
            <div className='filters__seperator my-3' />
            <Accordion defaultActiveKey='0' flush className='filters__header'>
                <Accordion.Header>
                    <div>
                        <img src={Icons.FilterIcon} className='filters__header-icon' />
                        <span>Add Filters</span>
                    </div>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            marginLeft: "22%",
                        }}
                        variant='text'
                        onClick={(e) => {
                            e.stopPropagation();
                            clearAll();
                        }}
                    >
                        Clear All
                    </Button>
                </Accordion.Header>
                <Accordion.Body>{children}</Accordion.Body>
            </Accordion>
        </div>
    );
};

export default FilterWrapper;
