import { FC, ReactElement, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";

import LoadingButton from "@app/components/Button";
import Loader from "@app/components/Loader";
import BootstrapModal from "@app/components/Modal";
import { AppContext } from "@app/utils/contexts/App.context";

import "./index.scss";

interface FormWrapperProps {
    heading: string;
    children: ReactElement[] | ReactElement;
    onCancelClick?: () => void;
    onSubmitClick?: () => void;
    onClickBack?: () => void;
    submitDisabled?: boolean;
    backDisabled?: boolean;
    cancelDisabled?: boolean;
}

const FormWrapper: FC<FormWrapperProps> = ({
    heading,
    children,
    onCancelClick,
    onSubmitClick,
    onClickBack,
    submitDisabled,
    cancelDisabled,
    backDisabled,
}) => {
    const { isLoading, showLoader } = useContext(AppContext);

    return (
        <Container fluid className='form-wrapper'>
            <Row>
                <h2>{heading}</h2>
            </Row>
            <Row className='m-0 mt-2'>
                <Col xs={12} className='form-wrapper__border' />
                <Col xs={12} className='form-wrapper__box'>
                    {children}
                </Col>
            </Row>
            <Row className='m-0 mt-5 justify-content-between'>
                <Col>
                    {onClickBack && (
                        <LoadingButton
                            btnClass='activate-deactivate__modal-btn-wrapper--continue w-170 label-black'
                            label={"Back"}
                            variant='back'
                            onClick={onClickBack}
                            disabled={backDisabled || isLoading}
                        />
                    )}
                </Col>
                <Col xs={8} className='d-flex justify-content-end'>
                    {onCancelClick && (
                        <LoadingButton
                            label='Cancel'
                            onClick={onCancelClick}
                            variant='cancel'
                            disabled={cancelDisabled}
                        />
                    )}

                    <LoadingButton
                        label='Submit'
                        onClick={onSubmitClick}
                        variant='submit'
                        disabled={submitDisabled || isLoading}
                    />
                </Col>
            </Row>
            {isLoading && (
                <BootstrapModal
                    contentClassName='form-wrapper__modal-body'
                    backdropClassName='form-wrapper__modal'
                    open
                    size='sm'
                >
                    {showLoader && <Loader />}
                </BootstrapModal>
            )}
        </Container>
    );
};

export default FormWrapper;
