import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { t } from "i18next";
import { FC, useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Icons from "@app/assets/Icons";
import { RoutePaths } from "@app/routes/index";
import { getMe } from "@app/services/Endpoints/User.API";
import { UserLevel } from "@app/types/User.type";
import { AppContext } from "@app/utils/contexts/App.context";

import "./index.scss";

const SplashScreenPage: FC = () => {
    const navigate = useNavigate();
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { setAppUser, appUser } = useContext(AppContext);

    useEffect(() => {
        // let interval: ReturnType<typeof setInterval>;
        if (inProgress === InteractionStatus.None) {
            if (isAuthenticated) {
                const currentUser = accounts[0];
                Promise.all([
                    instance.acquireTokenSilent({
                        scopes: ["User.Read.All"],
                        account: currentUser,
                    }),
                    instance.acquireTokenSilent({
                        scopes: ["api://4f5cfbd7-7a59-4662-ab76-98808aad4a87/serverapi.all"],
                        account: currentUser,
                    }),
                ]).then(([user, server]) => {
                    localStorage.setItem("user-token", user.accessToken);
                    localStorage.setItem("server-token", server.accessToken);
                    getMe().then((res) => setAppUser(res?.data?.data));
                });
            } else {
                instance.loginRedirect({
                    scopes: ["api://4f5cfbd7-7a59-4662-ab76-98808aad4a87/serverapi.all"],
                });
            }
        }
    }, [instance, isAuthenticated, accounts, inProgress, setAppUser]);

    useEffect(() => {
        console.log(appUser, "Here");
        if (appUser?.userLevel === UserLevel.Licensor) {
            if (appUser?.role) {
                let perms = {
                    licensee: false,
                    branch: false,
                    machine: false,
                    item: false,
                    customization: false,
                    product: false,
                    user: false,
                };
                appUser?.role?.features?.forEach(
                    (feature) =>
                        (perms = {
                            ...perms,
                            ...feature?.permissions
                                ?.filter((perm) => perm.name === "R")
                                .reduce(
                                    (allPermissions, permission) => ({
                                        ...allPermissions,
                                        [`${feature.name?.toLowerCase()}`]: Boolean(
                                            permission.isAllowed,
                                        ),
                                    }),
                                    {} as { [key: string]: boolean },
                                ),
                        }),
                );
                if (perms.licensee) {
                    navigate(RoutePaths.ViewLicensees);
                } else if (perms.branch) {
                    navigate(RoutePaths.ViewBranches);
                } else if (perms.machine) {
                    navigate(RoutePaths.ViewMachines);
                } else if (perms.customization) {
                    navigate(RoutePaths.ViewCustomizations);
                } else if (perms.item) {
                    navigate(RoutePaths.ViewItems);
                } else if (perms.product) {
                    navigate(RoutePaths.ViewProducts);
                } else if (perms.user) {
                    navigate(RoutePaths.ViewUsers);
                }
            }
        } else if (appUser?.userLevel === UserLevel.Licensee) {
            navigate(RoutePaths.LicenseeBranches);
        }
    }, [appUser, navigate]);

    return (
        <Container fluid className='splash-screen'>
            <img src={Icons.AlbaikLogoLarge} />
            <h3>{t("LANDING.TITLE")}</h3>
        </Container>
    );
};

export default SplashScreenPage;
