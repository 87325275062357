import { FC, ReactElement } from "react";

import "./index.scss";
import Icons from "@app/assets/Icons";

interface HeaderProps {
    primaryText: string;
    secondaryText?: string;
    rightAction?: ReactElement;
}

const Header: FC<HeaderProps> = ({ primaryText, secondaryText, rightAction }) => (
    <div className='app-header'>
        <img src={Icons.AlbaikLogo} />
        <div className='app-header__title'>
            {primaryText} <span className='app-header__title--secondary'>{secondaryText}</span>
        </div>
        <div className='app-header__end'>{rightAction}</div>
    </div>
);

export default Header;
