import { FC } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

import Icons from "@app/assets/Icons";
import { SelectOption } from "@app/types/Common.types";

import "./index.scss";

interface AutoCompleteProps {
    label: string;
    value: string;
    onChange: (_e: SelectOption) => void;
    name: string;
    options: SelectOption[];
    error?: string;
    disabled?: boolean;
    placeholder?: string;
}

const AutoComplete: FC<AutoCompleteProps> = ({
    label,
    value,
    onChange,
    options,
    disabled,
    placeholder,
    error,
}) => (
    <Form.Group className='autocomplete-input'>
        <Form.Label className='autocomplete-input__label'>{label}</Form.Label>
        <Select
            value={options?.find((opt) => opt.value === value)}
            onChange={(e) => onChange(e as unknown as SelectOption)}
            options={options}
            isDisabled={disabled}
            isSearchable
            styles={{
                control: (baseStyles) => ({
                    ...baseStyles,
                    border: "1px solid #bababa",
                    borderRadius: "3px",
                    height: "50px",
                }),
            }}
            placeholder={placeholder}
            isOptionDisabled={(option) => option.isdisabled || false}
        />
        {error && (
            <Form.Text className='select-input__error'>
                <img src={Icons.ErrorGreyIcon} /> <span>{error}</span>
            </Form.Text>
        )}
    </Form.Group>
);

export default AutoComplete;
