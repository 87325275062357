import { FC } from "react";
import { Container } from "react-bootstrap";

const UnauthorizedPage: FC = () => (
    <Container fluid className='d-flex justify-content-center align-items-center h-100 w-100'>
        <h4>You're not authorized to perform this action</h4>
    </Container>
);

export default UnauthorizedPage;
