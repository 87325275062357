import { createContext, FC, ReactNode, useState } from "react";
import { toast } from "react-toastify";

import { DisplayData, ModalType, ScreenType } from "@app/types/Common.types";

interface FormContextProviderProps {
    children: ReactNode;
}

interface FormContextOutput {
    isDirty: boolean;
    setIsDirty: (_val: boolean) => void;
    shouldOpenModal: boolean;
    triggerShowModal: (_val: boolean, _type: ModalType) => void;
    actions: Actions;
    setActions: (_val: Actions) => void;
    modalType: ModalType;
    screen: ScreenType | undefined;
    setScreen: (_val: ScreenType | undefined) => void;
    dataToDisplay: DisplayData[];
    setDataToDisplay: (_val: DisplayData[]) => void;
}

interface Actions {
    done?: () => void;
    cancel?: () => void;
}

export const FormContext = createContext({} as FormContextOutput);

const FormContextProvider: FC<FormContextProviderProps> = ({ children }) => {
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalType, setModalType] = useState<ModalType>(ModalType.SUBMIT);
    const [screen, setScreen] = useState<ScreenType | undefined>(undefined);
    const [dataToDisplay, setDataToDisplay] = useState<DisplayData[]>([]);
    const [actions, setActions] = useState<Actions>({
        done: undefined,
        cancel: undefined,
    });

    const shouldOpenModal = isDirty && showModal;

    const triggerShowModal = (open: boolean, type: ModalType) => {
        if (open) {
            toast.dismiss();
        }
        setShowModal(open);
        setModalType(type);
    };

    return (
        <FormContext.Provider
            value={{
                isDirty,
                setIsDirty,
                shouldOpenModal,
                triggerShowModal,
                actions,
                setActions,
                modalType,
                screen,
                setScreen,
                dataToDisplay,
                setDataToDisplay,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

export default FormContextProvider;
