import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import BranchContainer from "@app/containers/branch";
import { RoutePaths } from "@app/routes/index";
import { getBranchDetails } from "@app/services/Endpoints/Branches.API";
import { Branch } from "@app/types/Branch.types";

const EditBranchPage: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [branchData, setBranchData] = useState<Branch>();

    useEffect(() => {
        if (location?.state?.branchEditData) {
            const branchID = location.state.branchEditData.id;
            getBranchDetails(branchID)
                .then((res) => setBranchData(res.data.data))
                .catch(() => setBranchData(undefined));
        } else navigate(RoutePaths.ViewBranches);
    }, [navigate, location]);

    return <BranchContainer branch={branchData} />;
};

export default EditBranchPage;
