import { t } from "i18next";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FormWrapper from "@app/components/FormWrapper";
import UserRoleForm from "@app/components/forms/UserRole.form";
import { RoutePaths } from "@app/routes/index";
import {
    createUserRole,
    getFeatures,
    getUsersOfRole,
    updateUserRole,
} from "@app/services/Endpoints/User.API";
import { DisplayData, Errors, ModalType, ScreenType } from "@app/types/Common.types";
import { Feature, PermisionNames, Role, RoleFormData, UserLevel } from "@app/types/User.type";
import { FormContext } from "@app/utils/contexts/Form.context";
import {
    generateRequestFeatures,
    generateStructuredFeatures,
    getFeatureIds,
    getFeaturePermissions,
} from "@app/utils/userRoleHelpers";

interface UserRoleContainerProps {
    role?: Role;
}

const initialFormData: RoleFormData = {
    name: "",
    userLevel: UserLevel.None,
    isActive: true,
    features: {},
};

const UserRoleContainer: FC<UserRoleContainerProps> = ({ role }) => {
    const navigate = useNavigate();

    const { triggerShowModal, setActions, isDirty, setIsDirty, setScreen, setDataToDisplay } =
        useContext(FormContext);

    const [features, setFeatures] = useState<Feature[]>([]);
    const [prevFeatures, setPrevFeatures] = useState<Feature[]>([]);
    const [roleData, setRoleData] = useState<RoleFormData>(initialFormData);
    const [userAssignedToRole, setUsersAssignedToRole] = useState<DisplayData[]>([]);
    const [errors, setErrors] = useState<Partial<Errors>>();

    const fetchFeatures = useCallback(() => {
        if (!features.length && roleData.userLevel !== UserLevel.None)
            getFeatures(roleData.userLevel)
                .then((res) => {
                    const features = res.data.data;
                    setFeatures([...features]);
                })
                .catch(() => setFeatures([]));
    }, [roleData, features]);

    const fetchUsersAssignedToRole = useCallback(() => {
        if (role?.id) {
            getUsersOfRole(role.id, 5)
                .then((res) => setUsersAssignedToRole([...res.data.data]))
                .catch(() => setUsersAssignedToRole([]));
        }
    }, [role]);

    useEffect(() => {
        fetchFeatures();
    }, [fetchFeatures]);

    useEffect(() => {
        fetchUsersAssignedToRole();
    }, [fetchUsersAssignedToRole]);

    if (features.length !== prevFeatures.length) {
        setPrevFeatures(features);
        if (Number(roleData.userLevel) !== Number(role?.userLevel)) {
            const structureFeatures = generateStructuredFeatures(
                features,
                Number(roleData.userLevel),
            );

            setRoleData({ ...roleData, features: structureFeatures });
        } else if (role?.features?.length) {
            const structureFeatures = generateStructuredFeatures(
                role?.features as Feature[],
                Number(role.userLevel),
            );

            setRoleData({ ...roleData, features: structureFeatures });
        }
    }

    useEffect(() => {
        if (role?.id) {
            const { name, userLevel, features, isActive } = { ...role };
            setRoleData({
                name,
                userLevel,
                isActive,
                features: generateStructuredFeatures(features as Feature[], Number(userLevel)),
            });
        }
    }, [role]);

    const handleChange = (name: string, value: string | boolean) => {
        const data: RoleFormData = { ...roleData };
        if (name === "userLevel") {
            setFeatures([]);
        }
        if (errors?.[name]) {
            setErrors({ ...errors, [name]: "" });
        }
        setRoleData({ ...data, [name]: value });
    };

    const handleFeatureCreatePermissions = (
        featureId: string,
        value: boolean,
        permissionName: PermisionNames,
    ) => {
        const isReadPermission = permissionName === PermisionNames.READ;
        const isCreatePermission = permissionName === PermisionNames.CREATE;

        const { linceseeFeatureID, branchFeatureID, userFeatureID } = getFeatureIds(features);

        const currentFeatures = roleData.features;

        if (value && isCreatePermission) {
            if (featureId === linceseeFeatureID) {
                if (branchFeatureID) {
                    const { readPermissionID, createPermissionID } = getFeaturePermissions(
                        branchFeatureID,
                        features,
                    );
                    if (createPermissionID)
                        currentFeatures[`${branchFeatureID}`][`${createPermissionID}`].isAllowed =
                            true;
                    if (readPermissionID)
                        currentFeatures[`${branchFeatureID}`][`${readPermissionID}`].isAllowed =
                            true;
                }
                if (userFeatureID) {
                    const { readPermissionID, createPermissionID } = getFeaturePermissions(
                        userFeatureID,
                        features,
                    );
                    if (createPermissionID)
                        currentFeatures[`${userFeatureID}`][`${createPermissionID}`].isAllowed =
                            true;
                    if (readPermissionID)
                        currentFeatures[`${userFeatureID}`][`${readPermissionID}`].isAllowed = true;
                }
            }
            if (featureId === branchFeatureID) {
                if (userFeatureID) {
                    const { readPermissionID, createPermissionID } = getFeaturePermissions(
                        userFeatureID,
                        features,
                    );
                    if (createPermissionID)
                        currentFeatures[`${userFeatureID}`][`${createPermissionID}`].isAllowed =
                            true;
                    if (readPermissionID)
                        currentFeatures[`${userFeatureID}`][`${readPermissionID}`].isAllowed = true;
                }
            }
        } else {
            if (featureId === userFeatureID) {
                if (branchFeatureID) {
                    const { readPermissionID, createPermissionID } = getFeaturePermissions(
                        branchFeatureID,
                        features,
                    );
                    if (createPermissionID && isReadPermission)
                        currentFeatures[`${branchFeatureID}`][`${createPermissionID}`].isAllowed =
                            false;
                    if (
                        readPermissionID &&
                        !currentFeatures[`${branchFeatureID}`][`${readPermissionID}`].isDisabled &&
                        isReadPermission
                    )
                        currentFeatures[`${branchFeatureID}`][`${readPermissionID}`].isAllowed =
                            false;
                }
                if (linceseeFeatureID) {
                    const { readPermissionID, createPermissionID } = getFeaturePermissions(
                        linceseeFeatureID,
                        features,
                    );
                    if (createPermissionID && isReadPermission)
                        currentFeatures[`${linceseeFeatureID}`][`${createPermissionID}`].isAllowed =
                            false;
                    if (readPermissionID && isReadPermission)
                        currentFeatures[`${linceseeFeatureID}`][`${readPermissionID}`].isAllowed =
                            false;
                }
            }
            if (featureId === branchFeatureID) {
                if (linceseeFeatureID) {
                    const { readPermissionID, createPermissionID } = getFeaturePermissions(
                        linceseeFeatureID,
                        features,
                    );
                    if (createPermissionID && isReadPermission)
                        currentFeatures[`${linceseeFeatureID}`][`${createPermissionID}`].isAllowed =
                            false;
                    if (readPermissionID && isReadPermission)
                        currentFeatures[`${linceseeFeatureID}`][`${readPermissionID}`].isAllowed =
                            false;
                }
            }
        }

        setRoleData({ ...roleData, features: currentFeatures });
    };

    const handleToggle = (
        featureId: string,
        permissionId: string,
        permissionName: PermisionNames,
        value: boolean,
    ) => {
        if (errors?.features) {
            setErrors({ ...errors, features: "" });
        }
        const { editPermissionID, readPermissionID, createPermissionID, deactivatePermissionID } =
            getFeaturePermissions(featureId, features);

        const selectedFeature = roleData.features[`${featureId}`];
        const selectedPermission = selectedFeature[`${permissionId}`];
        selectedPermission.isAllowed = value;

        // If Deactive = true, mark Edit and Read as true
        if (permissionName === PermisionNames.DEACTIVATE && value) {
            if (editPermissionID) selectedFeature[`${editPermissionID}`].isAllowed = true;
            if (readPermissionID) selectedFeature[`${readPermissionID}`].isAllowed = true;
        }

        // If Create or Edit = true, mark Read as true
        if (
            (permissionName === PermisionNames.CREATE || permissionName === PermisionNames.EDIT) &&
            value
        ) {
            if (readPermissionID) selectedFeature[`${readPermissionID}`].isAllowed = true;
        }

        // If Read = false, mark Create, Edit & Deactivate as false
        if (permissionName === PermisionNames.READ && !value) {
            handleFeatureCreatePermissions(featureId, value, permissionName);
            if (createPermissionID) selectedFeature[`${createPermissionID}`].isAllowed = false;
            if (deactivatePermissionID)
                selectedFeature[`${deactivatePermissionID}`].isAllowed = false;
            if (editPermissionID) selectedFeature[`${editPermissionID}`].isAllowed = false;
        }

        // If Edit = false, mark Deactivate as false
        if (permissionName === PermisionNames.EDIT && !value) {
            if (deactivatePermissionID)
                selectedFeature[`${deactivatePermissionID}`].isAllowed = false;
        }

        if (permissionName === PermisionNames.CREATE)
            handleFeatureCreatePermissions(featureId, value, permissionName);

        const updatedFeatures = {
            ...roleData.features,
            [`${featureId}`]: {
                ...selectedFeature,
                [`${permissionId}`]: { ...selectedPermission },
            },
        };

        setRoleData({ ...roleData, features: updatedFeatures });
    };

    const handleCancel = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    setRoleData(initialFormData);
                    setErrors(undefined);
                    navigate(RoutePaths.ViewRoles);
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        } else navigate(RoutePaths.ViewRoles);
    };

    const handleCreateRole = () => {
        const userRoleData: Partial<Role> = {
            name: roleData.name,
            userLevel: Number(roleData.userLevel),
            isActive: true,
            features: generateRequestFeatures(roleData.features),
        };

        createUserRole(userRoleData)
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                setIsDirty(false);
                navigate(RoutePaths.ViewRoles);
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleUpdateRole = async () => {
        const userRoleData: Partial<Role> = {
            name: roleData.name,
            userLevel: Number(roleData.userLevel),
            isActive: roleData.isActive,
            features: generateRequestFeatures(roleData.features),
        };

        return await updateUserRole(String(role?.id), userRoleData)
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                navigate(RoutePaths.ViewRoles);
                setIsDirty(false);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleSubmit = () => {
        const { name, userLevel, features, isActive } = roleData;

        const isLicenseeFeaturesValid =
            Object.values(features).reduce(
                (allFeatures, feature) =>
                    allFeatures +
                    Object.values(feature).reduce(
                        (allPermissions, permission) =>
                            allPermissions + (permission.isAllowed ? 1 : 0),
                        0,
                    ),
                0,
            ) >= 2;

        const isNameValid = !!name;
        const isLevelValid = Number(userLevel) !== UserLevel.None;
        const isFeaturesValid =
            Number(userLevel) === UserLevel.Licensee
                ? isLicenseeFeaturesValid
                : Object.values(features)?.some((feature) =>
                      Object.values(feature)?.some((permission) => permission.isAllowed),
                  );
        const isDataValid = isNameValid && isLevelValid && isFeaturesValid;
        const isInactiveSubmissionWithData =
            !isActive && !!userAssignedToRole.length && isDataValid;
        const isInactiveSubmissionWithoutData =
            !isActive && !userAssignedToRole.length && isDataValid;

        if (isInactiveSubmissionWithData) {
            setScreen(ScreenType.INACTIVE_ROLE_DENIED);
            setDataToDisplay(userAssignedToRole);
            triggerShowModal(true, ModalType.INACTIVE_ROLE_DENIED);
            setActions({
                done: () => {
                    triggerShowModal(false, ModalType.INACTIVE_ROLE_DENIED);
                    setScreen(undefined);
                    setDataToDisplay([]);
                    navigate(RoutePaths.ViewUsers, {
                        state: { roleId: role?.id, roleName: role?.name },
                    });
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.INACTIVE_ROLE_DENIED);
                    setScreen(undefined);
                    setDataToDisplay([]);
                    setRoleData({ ...roleData, isActive: true });
                },
            });
        } else if (isInactiveSubmissionWithoutData) {
            setScreen(ScreenType.USER_ROLE);
            triggerShowModal(true, ModalType.INACTIVE_SUBMISSION);
            setActions({
                done: async () => {
                    await handleUpdateRole();
                    triggerShowModal(false, ModalType.INACTIVE_SUBMISSION);
                    setScreen(undefined);
                    setDataToDisplay([]);
                    navigate(RoutePaths.ViewRoles);
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.INACTIVE_SUBMISSION);
                    setScreen(undefined);
                    setDataToDisplay([]);
                },
            });
        } else if (isDataValid) {
            triggerShowModal(true, ModalType.SUBMIT);
            setActions({
                done: () => (role ? handleUpdateRole() : handleCreateRole()),
                cancel: () => triggerShowModal(false, ModalType.SUBMIT),
            });
        } else {
            const currentErrors: Partial<Errors> = {};
            if (!isNameValid)
                currentErrors.name = `${t("ERRORS.REQUIRED", {
                    name: t("USER_ROLE.ROLE.LABEL"),
                })}`;
            if (!isLevelValid)
                currentErrors.userLevel = `${t("ERRORS.REQUIRED", {
                    name: t("USER_ROLE.LEVEL.LABEL"),
                })}`;
            if (!isFeaturesValid && isLevelValid)
                currentErrors.features = `${t("ERRORS.PERMISSION_REQUIRED")}`;
            setErrors({ ...errors, ...currentErrors });
        }
    };

    return (
        <FormWrapper
            heading={!!role ? t("USER_ROLE.EDITHEADING") : t("USER_ROLE.HEADING")}
            onCancelClick={handleCancel}
            onSubmitClick={handleSubmit}
            submitDisabled={!isDirty}
        >
            <UserRoleForm
                data={roleData}
                onChange={handleChange}
                features={features}
                errors={errors}
                onToggle={handleToggle}
                isEdit={!!role}
            />
        </FormWrapper>
    );
};

export default UserRoleContainer;
