import { FC, useContext, useEffect, useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";

import Checkbox from "@app/components/inputs/Checkbox";
import { getCities, getCountries } from "@app/services/Endpoints/Country.API";
import { getLicensee } from "@app/services/Endpoints/Licensee.API";
import { City, Country } from "@app/types/Countries.types";
import { Licensee } from "@app/types/Licensee";
import { FilterContext } from "@app/utils/contexts/Filters.context";

const BranchFilters: FC = () => {
    const {
        handleStatusesFilter,
        statusesFilter,
        setStatusesFilter,
        citiesFilter,
        handleCitiesFilter,
        setCitiesFilter,
        countriesFilter,
        handleCountriesFilter,
        setCountriesFilter,
        licenseesFilter,
        handleLicenseesFilter,
        setLicenseesFilter,
    } = useContext(FilterContext);

    const [cities, setCities] = useState<City[]>();
    const [countries, setCountries] = useState<Country[]>([]);
    const [licensees, setLicensees] = useState<Licensee[]>([]);
    const [key, setKey] = useState<string | undefined | null>();

    useEffect(() => {
        getCountries()
            .then((res) => setCountries([...res.data.data]))
            .catch(() => setCountries([]));

        getLicensee()
            .then((res) => {
                setLicensees([...res.data.data]);
            })
            .catch(() => {
                setLicensees([]);
            });
    }, []);

    useEffect(() => {
        const countryParam = countriesFilter?.map((country) => `countries=${country}`).join("&");
        getCities(countryParam)
            .then((res) => {
                setCities([...res.data.data]);
            })
            .catch(() => setCities([]));
    }, [countriesFilter]);

    return (
        <Accordion
            activeKey={key}
            onSelect={(k) => setKey(String(k))}
            flush
            className='filters__item'
        >
            <Accordion.Item eventKey='0' className=''>
                <Accordion.Header className='filters__item-header'>
                    <span>Status</span>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            position: "absolute",
                            right: "20%",
                        }}
                        variant='text'
                        onClick={(e) => {
                            e.stopPropagation();
                            setStatusesFilter(undefined);
                        }}
                    >
                        Clear
                    </Button>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        <Col xs={12} className='filters__checkbox'>
                            <Checkbox
                                label='Active'
                                checked={!!statusesFilter?.includes(true)}
                                onClick={() => handleStatusesFilter(true)}
                            />
                        </Col>
                        <Col xs={12} className='filters__checkbox'>
                            <Checkbox
                                label='Inactive'
                                checked={!!statusesFilter?.includes(false)}
                                onClick={() => handleStatusesFilter?.(false)}
                            />
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
                <Accordion.Header className='filters__item-header'>
                    <span>Country</span>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            position: "absolute",
                            right: "20%",
                        }}
                        variant='text'
                        onClick={() => setCountriesFilter(undefined)}
                    >
                        Clear
                    </Button>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        {countries?.map((item, index) => (
                            <Col key={index} xs={12} className='filters__checkbox'>
                                <Checkbox
                                    label={item.name}
                                    checked={!!countriesFilter?.includes(item.id)}
                                    onClick={() => handleCountriesFilter(item.id)}
                                />
                            </Col>
                        ))}
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='2'>
                <Accordion.Header className='filters__item-header'>
                    <span>City</span>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            position: "absolute",
                            right: "20%",
                        }}
                        variant='text'
                        onClick={() => setCitiesFilter(undefined)}
                    >
                        Clear
                    </Button>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        {cities?.map((item, index) => (
                            <Col key={index} xs={12} className='filters__checkbox'>
                                <Checkbox
                                    label={item.name}
                                    checked={!!citiesFilter?.includes(item.id)}
                                    onClick={() => handleCitiesFilter(item.id)}
                                />
                            </Col>
                        ))}
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='3'>
                <Accordion.Header className='filters__item-header'>
                    <span>Licensee</span>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            position: "absolute",
                            right: "20%",
                        }}
                        variant='text'
                        onClick={() => setLicenseesFilter(undefined)}
                    >
                        Clear
                    </Button>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        {licensees?.map((item, index) => (
                            <Col key={index} xs={12} className='filters__checkbox'>
                                <Checkbox
                                    label={item.name}
                                    checked={!!licenseesFilter?.includes(item.id)}
                                    onClick={() => handleLicenseesFilter(item.id)}
                                />
                            </Col>
                        ))}
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default BranchFilters;
