import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import UserRoleContainer from "@app/containers/userRole";
import { RoutePaths } from "@app/routes/index";
import { getUserRoleDetails } from "@app/services/Endpoints/User.API";
import { Role } from "@app/types/User.type";

const EditUserRolePage: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [editRoleData, setEditRoleData] = useState<Role | undefined>(undefined);

    useEffect(() => {
        if (!location.state?.rolesEditData) {
            navigate(RoutePaths.ViewRoles);
        } else {
            getUserRoleDetails(location.state.rolesEditData.id).then((res) =>
                setEditRoleData(res.data.data),
            );
        }
    }, [location.state, navigate]);

    return <UserRoleContainer role={editRoleData} />;
};

export default EditUserRolePage;
