import { t } from "i18next";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Icons from "@app/assets/Icons";
import Loader from "@app/components/Loader";
import Pagination from "@app/components/Pagination";
import TableHeader from "@app/components/TableHeader";
import ViewUtilityHeader from "@app/components/ViewUtilityHeader";
import { RouteNames } from "@app/routes/index";
import { getRoles } from "@app/services/Endpoints/User.API";
import { HeadersMapInterface } from "@app/types/Common.types";
import { Role, rolesEnum } from "@app/types/User.type";
import { AppContext } from "@app/utils/contexts/App.context";
import { FilterContext } from "@app/utils/contexts/Filters.context";

import "./index.scss";

let totalPages = 0;
const sortByEnum = {
    DESC: 1,
    ASC: 0,
};

const ViewUserRolePage: FC = () => {
    const navigate = useNavigate();

    const { statusesFilter, userLevelsFilter } = useContext(FilterContext);
    const { appUser } = useContext(AppContext);

    const [roles, setRoles] = useState<Role[]>([]);
    const [sortByObj, setSortByObj] = useState({ sortBy: "name", sortOrder: sortByEnum.DESC });
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loader, setLoader] = useState<boolean>(false);
    const [searchVal, setSearchVal] = useState<string>("");
    const [isScrollComing, setIsScrollComing] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useRef<any>();
    const level = "";
    const HeadersMap: HeadersMapInterface = {
        serialNo: {
            label: t("VIEW.USERROLES.SERIALNO"),
            layout: { lg: 2, sm: 1 },
            disableSort: true,
        },
        name: { label: t("VIEW.USERROLES.NAME"), layout: { lg: 3, sm: 2 } },
        userLevel: {
            label: t("VIEW.USERROLES.USERLEVEL"),
            layout: { lg: 3, sm: 3 },
        },
        isActive: { label: t("VIEW.USERROLES.ISACTIVE"), layout: { lg: 3, sm: 2 } },
    };

    useEffect(() => {
        setPageNumber(1);
    }, [statusesFilter, userLevelsFilter]);

    useEffect(() => {
        const filters = [
            statusesFilter?.map((status) => `&IsActiveFlags=${status}`).join(""),
            userLevelsFilter?.map((lvl) => `&UserLevels=${Number(lvl)}`).join(""),
        ].join("&");
        getUserRoles(
            level,
            sortByObj.sortBy,
            sortByObj.sortOrder,
            pageNumber,
            pageSize,
            searchVal,
            filters,
        );
    }, [pageNumber, pageSize, sortByObj, searchVal, statusesFilter, userLevelsFilter]);

    useEffect(() => {
        if (ref.current?.offsetWidth != ref.current?.clientWidth) {
            setIsScrollComing(true);
        } else {
            setIsScrollComing(false);
        }
    });

    const getUserRoles = (
        level: number | string,
        sortBy: string,
        sortOrder: number,
        pageNumber: number,
        pageSize: number,
        name?: string,
        filters?: string,
    ) => {
        setLoader(true);
        getRoles(level, sortBy, sortOrder, pageNumber, pageSize, name, filters)
            .then((res) => {
                totalPages = res.data.totalPages;
                const result = res?.data?.data;
                if (result) {
                    setRoles([...result]);
                } else {
                    setRoles([]);
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setLoader(false));
    };

    const sortByHandler = (headerKey: string) => {
        setSortByObj({ sortBy: headerKey, sortOrder: Number(!sortByObj.sortOrder) });
    };

    const onChangeRecordsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setPageSize(Number(value));
        setPageNumber(1);
    };

    const onPageChange = (pageNumber: number) => {
        setPageNumber(Number(pageNumber));
    };
    const onEdit = (rolesData: Role) => {
        navigate(RouteNames.Edit, {
            state: { rolesEditData: rolesData },
        });
    };
    const redirectToAddRoles = () => {
        navigate(RouteNames.Add);
    };

    const handleSearch = (val: string) => {
        setSearchVal(val);
    };
    return (
        <Container fluid className='userRole'>
            <ViewUtilityHeader
                onChange={onChangeRecordsPerPage}
                placeholder='Search for Role name'
                btnLabel={t("VIEW.USERROLES.ADD")}
                onClick={redirectToAddRoles}
                onSearch={handleSearch}
            />

            <TableHeader
                headersMap={HeadersMap}
                sortByHandler={sortByHandler}
                sortByObj={sortByObj}
                enableIconColumn
            />

            <div
                className={`userRole--page ${isScrollComing ? "userRole--isScroll" : ""}`}
                id='listing-Outercontainer'
                ref={ref}
            >
                {loader ? (
                    <Loader />
                ) : roles.length ? (
                    roles.map((item, index) => (
                        <Row
                            key={index}
                            className={`userRole__rowWrap ${
                                index % 2 == 0 && "customisation__rowGrey"
                            }`}
                        >
                            <Col className='userRole--pl20' sm='1' lg='2'>
                                {item.sequenceNo <= 9 ? "0" : ""}
                                {item.sequenceNo}
                            </Col>
                            <Col sm='2' lg='3'>
                                {item.name}
                            </Col>

                            <Col sm='3' lg='3'>
                                {rolesEnum[item.userLevel]}
                            </Col>
                            <Col sm='3' lg='3'>
                                {item.isActive
                                    ? t("VIEW.USERROLES.ACTIVE")
                                    : t("VIEW.USERROLES.INACTIVE")}
                            </Col>
                            <Col sm='1' lg='1'>
                                {appUser?.roleID !== item.id && !item.isSystemRole && (
                                    <img
                                        className='userRole__edit userRole__enablepointer'
                                        src={Icons.EditIcon}
                                        onClick={() => onEdit(item)}
                                        height='20px'
                                    />
                                )}
                            </Col>
                        </Row>
                    ))
                ) : (
                    <div className='userRole--noData'>{t("APIMSG.NODATA")}</div>
                )}
            </div>
            <Pagination
                sortByObj={sortByObj}
                onPageChange={onPageChange}
                totalPages={totalPages}
                pageSize={pageSize}
                pageNumber={pageNumber}
            />
        </Container>
    );
};

export default ViewUserRolePage;
