import { t } from "i18next";
import React, { ChangeEvent, FC, useCallback } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";

import Icons from "@app/assets/Icons";
import LoadingButton from "@app/components/Button";
import { Debounce } from "@app/utils/helpers";

import "./index.scss";

interface ViewUtilityHeaderProps {
    placeholder: string;
    btnLabel: string;
    onChange: (_event: React.ChangeEvent<HTMLSelectElement>) => void;
    onClick?: () => void;
    onSearch?: (_val: string) => void;
}

const ViewUtilityHeader: FC<ViewUtilityHeaderProps> = ({
    onChange,
    placeholder,
    btnLabel,
    onClick,
    onSearch,
}) => {
    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        const searchText = e.target.value;
        onSearch && onSearch(searchText);
    };

    const handleSearchChangeDebounce = useCallback(Debounce(handleSearchChange, 500), []);
    return (
        <Row className='viewHeader__utilityContainer' data-testid='utility-container'>
            <Col lg='5' sm='5' className='p-0'>
                {onClick && (
                    <LoadingButton
                        icon={<img src={Icons.PlusIcon} className='viewHeader__plusIcon' />}
                        label={btnLabel}
                        variant='add'
                        onClick={onClick}
                        btnClass='viewHeader__add-button'
                    />
                )}
            </Col>
            <Col lg='3' sm='4' className='viewHeader__rowSelection'>
                <div className='viewHeader__rowSelectionLabel'>{t("ROW_SELECTION.SHOW")}</div>
                <div>
                    <Form.Select size='sm' onChange={(event) => onChange(event)}>
                        <option value='10'>10 rows</option>
                        <option value='25'>25 rows</option>
                        <option value='50'>50 rows</option>
                        <option value='100'>100 rows</option>
                    </Form.Select>
                </div>
            </Col>

            <Col lg='4' sm='3' className='viewHeader__search'>
                <InputGroup>
                    <Form.Control
                        placeholder={placeholder}
                        aria-label={placeholder}
                        aria-describedby='search-addon'
                        onChange={handleSearchChangeDebounce}
                    />
                </InputGroup>
            </Col>
        </Row>
    );
};

export default ViewUtilityHeader;
