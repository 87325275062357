import { FC } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import LoadingButton from "@app/components/Button";

const NotFoundPage: FC = () => {
    const navigate = useNavigate();
    return (
        <Container
            fluid
            className='d-flex justify-content-center align-items-center vh-100 wh-100 flex-column'
        >
            <h3 className='pb-4'>Invalid Route</h3>
            <LoadingButton
                label='Navigate to Dashboard'
                variant='submit'
                onClick={() => navigate("/")}
                btnClass='w-25'
            />
        </Container>
    );
};

export default NotFoundPage;
