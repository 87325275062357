import { OperationalAPIService } from "@app/services/Methods/api";
import { BranchSettingsResponse, SharableItemResponse } from "@app/types/API.types";
import { BranchSettings } from "@app/types/BranchSettings";

export const saveBranchSettings = (data: Partial<BranchSettings>[]) =>
    OperationalAPIService.post("/branch-settings", data);

export const getBranchSettings = () =>
    OperationalAPIService.get<BranchSettingsResponse>("/branch-settings");

export const getSharableItems = () =>
    OperationalAPIService.get<SharableItemResponse>("/sharableItem");
