import { MasterAPIService } from "@app/services/Methods/api";
import { CreateProductResponse } from "@app/types/API.types";
import { CreateProduct } from "@app/types/Product.types";

export const getProducts = (
    sortBy?: string,
    sortOrder?: number,
    pageNumber?: number,
    pageSize?: number,
    name?: string,
    itemId?: string,
    filters?: string,
) =>
    MasterAPIService.get(
        `/product?${sortBy ? `sortBy=${sortBy}&` : ""}${
            sortOrder ? `sortOrder=${sortOrder}&` : ""
        }${pageNumber ? `pageNumber=${pageNumber}&` : ""}${
            pageSize ? `pageSize=${pageSize}&` : ""
        }${name ? `name=${name}&` : ""}${itemId ? `itemId=${itemId}&` : ""}${
            filters ? filters : ""
        }`,
    );

export const getProductCategory = () => MasterAPIService.get("/product/ProductCategory");

export const getProductById = (id: string) =>
    MasterAPIService.get(`/product/GetProductById?productId=${id}`);

export const addProduct = (product: CreateProduct) =>
    MasterAPIService.post<CreateProductResponse>("/product", product);

export const updateProduct = (data: Partial<CreateProduct>) =>
    MasterAPIService.patch<CreateProduct>("/product", data);
