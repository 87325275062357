import { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import NotFoundPage from "@app/pages/auth/NotFound/index.page";
import UnauthorizedPage from "@app/pages/auth/Unathorized/index.page";
import { AppContext } from "@app/utils/contexts/App.context";
import FilterContextProvider from "@app/utils/contexts/Filters.context";
import FormContextProvider from "@app/utils/contexts/Form.context";

import Layout from "./Layout";
import SidebarData from "./Layout/sidebarData";

const DashboardRoutes = () => {
    const { userPermissions } = useContext(AppContext);

    return (
        <Routes>
            {SidebarData(userPermissions).map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={
                        <FilterContextProvider>
                            <FormContextProvider>
                                <Layout
                                    titlePrimary={route.primaryText}
                                    titleSecondary={route.secondaryText}
                                >
                                    {route.authorized ? route.element : <UnauthorizedPage />}
                                </Layout>
                            </FormContextProvider>
                        </FilterContextProvider>
                    }
                />
            ))}
            <Route path={"*"} element={<NotFoundPage />} />
        </Routes>
    );
};

export default DashboardRoutes;
