import { t } from "i18next";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FormWrapper from "@app/components/FormWrapper";
import AddCustomization from "@app/components/forms/Customization.form";
import { RoutePaths } from "@app/routes/index";
import { addCustomization, editCustomization } from "@app/services/Endpoints/Customization";
import { CustomizationErrors, ModalType } from "@app/types/Common.types";
import { CustomizationFormData } from "@app/types/Customization.types";
import { FormContext } from "@app/utils/contexts/Form.context";

const initialFormData: CustomizationFormData = {
    id: "",
    name: "",
    displayName: "",
    code: "",
    imageId: "",
    status: true,
    imageName: "",
};

interface CustomizationContainerProps {
    customization?: CustomizationFormData;
}

const CustomizationContainer: FC<CustomizationContainerProps> = ({ customization }) => {
    const navigate = useNavigate();

    const { triggerShowModal, setActions, isDirty, setIsDirty } = useContext(FormContext);

    const [newCustomizationData, setNewCustomizationData] =
        useState<CustomizationFormData>(initialFormData);
    const [errors, setErrors] = useState<Partial<CustomizationErrors>>();

    useEffect(() => {
        if (customization) {
            const { name, displayName, code, imageId, status, id, imageName } = {
                ...customization,
            };

            setNewCustomizationData({
                id,
                name,
                displayName,
                code,
                imageId,
                status,
                imageName,
            });
        }
    }, [customization]);

    const handleChange = (name: string, value: string | boolean) => {
        const data: CustomizationFormData = { ...newCustomizationData };
        if (errors?.[name]) {
            setErrors({ ...errors, [name]: "" });
        }
        setNewCustomizationData({ ...data, [name]: value });
    };

    const handleCancel = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    setNewCustomizationData(initialFormData);
                    setErrors(undefined);
                    navigate(RoutePaths.ViewCustomizations);
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        } else navigate(RoutePaths.ViewCustomizations);
    };

    const handleCreateCustomization = () => {
        addCustomization({
            ...newCustomizationData,
        })
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                navigate(RoutePaths.ViewCustomizations);
                setIsDirty(false);
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleUpdateCustomization = () => {
        editCustomization({
            ...newCustomizationData,
        })
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                navigate(RoutePaths.ViewCustomizations);
                setIsDirty(false);
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleSubmit = () => {
        const { name, displayName, code, imageId } = newCustomizationData;
        const isNameValid = !!name;
        const isDisplayNameValid = !!displayName;
        const isCodeValid = !!code;
        const isImageIdValid = !!imageId;

        const isDataValid = isNameValid && isDisplayNameValid && isCodeValid && isImageIdValid;

        if (isDataValid) {
            setErrors(undefined);
            triggerShowModal(true, ModalType.SUBMIT);
            setActions({
                done: () =>
                    customization ? handleUpdateCustomization() : handleCreateCustomization(),
                cancel: () => triggerShowModal(false, ModalType.SUBMIT),
            });
        } else {
            const currentErrors: Partial<CustomizationErrors> = {};
            if (!isNameValid)
                currentErrors.name = `${t("ERRORS.REQUIRED", {
                    name: "Customization Name",
                })}`;
            if (!isDisplayNameValid)
                currentErrors.displayName = `${t("ERRORS.REQUIRED", {
                    name: "Customization Display Name",
                })}`;
            if (!isCodeValid)
                currentErrors.code = `${t("ERRORS.REQUIRED", {
                    name: "Customization ID",
                })}`;
            if (!isImageIdValid)
                currentErrors.imageId = `${t("ERRORS.REQUIRED", {
                    name: "Image ",
                })}`;
            setErrors({ ...errors, ...currentErrors });
        }
    };

    return (
        <FormWrapper
            heading={
                window.location.href.includes("edit")
                    ? "Edit Customization details"
                    : t("PRODUCT.HEADER")
            }
            onCancelClick={handleCancel}
            onSubmitClick={handleSubmit}
            submitDisabled={!isDirty}
        >
            <AddCustomization
                data={newCustomizationData}
                onChange={handleChange}
                errors={errors}
                isEdit={!!customization}
            />
        </FormWrapper>
    );
};

export default CustomizationContainer;
