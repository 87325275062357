import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ItemContainer from "@app/containers/item";
import { RoutePaths } from "@app/routes/index";

const EditItemPage: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location.state?.itemEditData) {
            navigate(RoutePaths.ViewItems);
        }
    }, [location.state, navigate]);

    return <ItemContainer items={location.state?.itemEditData} />;
};

export default EditItemPage;
