import AlbaikLogo from "./albaikLogo.png";
import AlbaikLogoComplete from "./albaikLogoComplete.png";
import AlbaikLogoLarge from "./albaikLogoLarge.png";
import BackArrowIcon from "./backArrowIcon.png";
import BinIcon from "./binIcon.png";
import CheckIcon from "./checkIcon.png";
import ChickenIcon from "./chickenIcon.png";
import copyIcon from "./copyIcon.png";
import DeleteIcon from "./deleteIcon.png";
import DoubleArrowIcon from "./doubleArrowIcon.svg";
import DoubleBackIcon from "./doubleBackIcon.png";
import DownArrowIcon from "./downArrowIcon.png";
import DustbinIcon from "./dustbinIcon.png";
import EditIcon from "./editIcon.png";
import ErrorGreyIcon from "./errorGreyIcon.png";
import ExclamationIcon from "./exclamation.png";
import FilterIcon from "./filter.png";
import ForwardArrowIcon from "./forwardArrowIcon.png";
import LogOffIcon from "./logOffIcon.png";
import PlusIcon from "./plusIcon.png";
import ProceedIcon from "./proceedIcon.png";
import ThreeDotsIcon from "./threeDotsIcon.png";
import ToggleCrossIcon from "./toggleCrossIcon.png";
import ToggleTickIcon from "./toggleTickIcon.png";
import UpArrowIcon from "./upArrowIcon.png";

const Icons = {
    AlbaikLogo,
    AlbaikLogoComplete,
    AlbaikLogoLarge,
    BackArrowIcon,
    DoubleBackIcon,
    LogOffIcon,
    ForwardArrowIcon,
    PlusIcon,
    EditIcon,
    DoubleArrowIcon,
    DownArrowIcon,
    UpArrowIcon,
    ThreeDotsIcon,
    ToggleTickIcon,
    ToggleCrossIcon,
    ErrorGreyIcon,
    ExclamationIcon,
    CheckIcon,
    DeleteIcon,
    DustbinIcon,
    BinIcon,
    ProceedIcon,
    ChickenIcon,
    FilterIcon,
    copyIcon,
};

export default Icons;
