import { t } from "i18next";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import Icons from "@app/assets/Icons";
import InactiveSubmission from "@app/components/InactiveSubmission";
import Loader from "@app/components/Loader";
import Pagination from "@app/components/Pagination";
import TableHeader from "@app/components/TableHeader";
import ViewUtilityHeader from "@app/components/ViewUtilityHeader";
import { RouteNames } from "@app/routes/index";
import { getUsersData } from "@app/services/Endpoints/User.API";
import { HeadersMapInterface } from "@app/types/Common.types";
import { Users } from "@app/types/Service.type";
import { AppContext } from "@app/utils/contexts/App.context";
import { FilterContext } from "@app/utils/contexts/Filters.context";

import "./index.scss";

let totalPages = 0;
const sortByEnum = {
    DESC: 1,
    ASC: 0,
};

const ViewUsersPage: FC = () => {
    const { userPermissions, appUser } = useContext(AppContext);
    const { statusesFilter, userLevelsFilter } = useContext(FilterContext);
    const navigate = useNavigate();
    const { state } = useLocation();
    const [users, setUsers] = useState<Users[]>([]);
    const [sortByObj, setSortByObj] = useState({ sortBy: "name", sortOrder: sortByEnum.DESC });
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loader, setLoader] = useState<boolean>(false);
    const [searchVal, setSearchVal] = useState<string>("");
    const [isScrollComing, setIsScrollComing] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useRef<any>();
    const HeadersMap: HeadersMapInterface = {
        serialNo: {
            label: t("VIEW.USER.SERIALNO"),
            layout: { lg: 1, sm: 1 },
            disableSort: true,
        },
        name: { label: t("VIEW.USER.NAME"), layout: { lg: 2, sm: 2 } },
        id: {
            label: t("VIEW.USER.ID"),
            layout: { lg: 4, sm: 4 },
        },
        branches: { label: t("VIEW.USER.BRANCHES"), layout: { lg: 2, sm: 2 } },
        isActive: { label: t("VIEW.USER.STATUS"), layout: { lg: 2, sm: 2 } },
    };

    const { roleId = undefined, roleName = undefined } = { ...state };

    useEffect(() => {
        setPageNumber(1);
    }, [statusesFilter, userLevelsFilter, roleId]);

    useEffect(() => {
        const filters = [
            statusesFilter?.map((status) => `&IsActiveFlags=${status}`).join(""),
            userLevelsFilter?.map((lvl) => `&UserLevels=${Number(lvl)}`).join(""),
            roleId && `RoleId=${roleId}`,
        ].join("&");
        getUsers(sortByObj.sortBy, sortByObj.sortOrder, pageNumber, pageSize, searchVal, filters);
    }, [pageNumber, pageSize, sortByObj, searchVal, statusesFilter, userLevelsFilter, roleId]);

    useEffect(() => {
        if (ref.current?.offsetWidth != ref.current?.clientWidth) {
            setIsScrollComing(true);
        } else {
            setIsScrollComing(false);
        }
    });

    const getUsers = (
        sortBy: string,
        sortOrder: number,
        pageNumber: number,
        pageSize: number,
        name?: string,
        filters?: string,
    ) => {
        setLoader(true);
        getUsersData(sortBy, sortOrder, pageNumber, pageSize, name, filters)
            .then((res) => {
                totalPages = res.data.totalPages;
                const result = res?.data?.data;
                if (result) {
                    setUsers([...result]);
                } else {
                    setUsers([]);
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setLoader(false));
    };

    const sortByHandler = (headerKey: string) => {
        setSortByObj({ sortBy: headerKey, sortOrder: Number(!sortByObj.sortOrder) });
    };

    const onChangeRecordsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setPageSize(Number(value));
        setPageNumber(1);
    };

    const onPageChange = (pageNumber: number) => {
        setPageNumber(Number(pageNumber));
    };
    const onEdit = (userData: Users) => {
        navigate(RouteNames.Edit, {
            state: { userEditData: userData },
        });
    };
    const redirectToAddCustomisation = () => {
        navigate(RouteNames.Add);
    };

    const handleSearch = (val: string) => {
        setSearchVal(val);
    };

    const canEdit = (user: Users) => {
        const isAppUserRoleSystem = appUser?.role?.isSystemRole;
        const isCurrentUserRoleSystem = user?.isSystemRole;

        if (
            !isAppUserRoleSystem &&
            isCurrentUserRoleSystem &&
            user.userLevel === appUser?.userLevel
        )
            return false;
        else return true;
    };

    return (
        <Container fluid className='users'>
            <ViewUtilityHeader
                onChange={onChangeRecordsPerPage}
                placeholder='Search for User name'
                btnLabel={t("VIEW.USER.ADDUSER")}
                onClick={userPermissions?.Create_User ? redirectToAddCustomisation : undefined}
                onSearch={handleSearch}
            />

            {roleName && roleId && (
                <InactiveSubmission title='Users for Role:' subTitle={roleName} />
            )}

            <TableHeader
                headersMap={HeadersMap}
                sortByHandler={sortByHandler}
                sortByObj={sortByObj}
                enableIconColumn
            />

            <div
                className={`users--page ${isScrollComing ? "users--isScroll" : ""}`}
                id='listing-Outercontainer'
                ref={ref}
            >
                {loader ? (
                    <Loader />
                ) : users.length ? (
                    users.map((item, index) => (
                        <Row
                            key={index}
                            className={`users__rowWrap ${index % 2 == 0 && "users__rowGrey"}`}
                        >
                            <Col className='users--pl20' sm='1' lg='1'>
                                {pageNumber == 1 && index < 9 ? "0" : ""}
                                {(pageNumber - 1) * 10 + (index + 1)}
                            </Col>
                            <Col sm='2' lg='2'>
                                {item.name}
                            </Col>

                            <Col sm='4' lg='4'>
                                {item.id}
                            </Col>
                            <Col sm='2' lg='2'>
                                {item.branches}
                            </Col>
                            <Col sm='2' lg='2'>
                                {item.isActive ? t("VIEW.USER.ACTIVE") : t("VIEW.USER.INACTIVE")}
                            </Col>
                            <Col sm='1' lg='1'>
                                {userPermissions?.Edit_User &&
                                    appUser?.id?.toLowerCase() !== item.id?.toLowerCase() &&
                                    canEdit(item) && (
                                        <img
                                            className='users__enablepointer'
                                            src={Icons.EditIcon}
                                            onClick={() => onEdit(item)}
                                            height='20px'
                                        />
                                    )}
                            </Col>
                        </Row>
                    ))
                ) : (
                    <div className='users--noData'>{t("APIMSG.NODATA")}</div>
                )}
            </div>
            <Pagination
                sortByObj={sortByObj}
                onPageChange={onPageChange}
                totalPages={totalPages}
                pageSize={pageSize}
                pageNumber={pageNumber}
            />
        </Container>
    );
};

export default ViewUsersPage;
