import { t } from "i18next";
import { FC, useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import LoadingButton from "@app/components/Button";
import Loader from "@app/components/Loader";
import Pagination from "@app/components/Pagination";
import TableHeader from "@app/components/TableHeader";
import ViewUtilityHeader from "@app/components/ViewUtilityHeader";
import { getBranchesData } from "@app/services/Endpoints/Branches.API";
import { HeadersMapInterface } from "@app/types/Common.types";
import { Branches } from "@app/types/Service.type";
import { LicenseeContext } from "@app/utils/contexts/Licensee.context";

import "./index.scss";

let totalPages = 0;
const sortByEnum = {
    DESC: 1,
    ASC: 0,
};

const MyBranchesPage: FC = () => {
    const { selectedBranch, setSelectedBranch } = useContext(LicenseeContext);

    const [branches, setBranches] = useState<Branches[]>([]);
    const [sortByObj, setSortByObj] = useState({ sortBy: "name", sortOrder: sortByEnum.DESC });
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loader, setLoader] = useState<boolean>(false);
    const [searchVal, setSearchVal] = useState<string>("");
    const HeadersMap: HeadersMapInterface = {
        serialNo: {
            label: t("VIEW.BRANCHES.SERIALNO"),
            layout: { lg: 1, sm: 1 },
            disableSort: true,
        },
        name: { label: t("VIEW.BRANCHES.NAME"), layout: { lg: 2, sm: 2 } },
        code: {
            label: t("VIEW.BRANCHES.ID"),
            layout: { lg: 2, sm: 2 },
        },
        cityName: {
            label: t("VIEW.BRANCHES.CITY"),
            layout: { lg: 1, sm: 1 },
        },
        countryName: {
            label: t("VIEW.BRANCHES.COUNTRY"),
            layout: { lg: 2, sm: 2 },
        },
        isActive: { label: t("VIEW.BRANCHES.STATUS"), layout: { lg: 2, sm: 2 } },
    };

    useEffect(() => {
        getCustomisations(sortByObj.sortBy, sortByObj.sortOrder, pageNumber, pageSize, searchVal);
    }, [pageNumber, pageSize, sortByObj, searchVal]);

    const getCustomisations = (
        sortBy: string,
        sortOrder: number,
        pageNumber: number,
        pageSize: number,
        name?: string,
    ) => {
        setLoader(true);
        getBranchesData(sortBy, sortOrder, pageNumber, pageSize, name)
            .then((res) => {
                totalPages = res.data.totalPages;
                const result = res?.data?.data;
                if (result) {
                    setBranches([...result]);
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setLoader(false));
    };

    const sortByHandler = (headerKey: string) => {
        setSortByObj({ sortBy: headerKey, sortOrder: Number(!sortByObj.sortOrder) });
    };

    const onChangeRecordsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setPageSize(Number(value));
        setPageNumber(1);
    };

    const onPageChange = (pageNumber: number) => {
        setPageNumber(Number(pageNumber));
    };

    const handleSearch = (val: string) => {
        setSearchVal(val);
    };

    const handleBranchClick = (branch: Branches) => {
        setSelectedBranch(branch);
        localStorage.setItem("SelectedBranch", JSON.stringify(branch));
    };

    return (
        <Container fluid className='branches'>
            <ViewUtilityHeader
                onChange={onChangeRecordsPerPage}
                placeholder='Search for Branch name'
                btnLabel={t("VIEW.BRANCHES.ADDBRANCHES")}
                onSearch={handleSearch}
            />

            <TableHeader
                headersMap={HeadersMap}
                sortByHandler={sortByHandler}
                sortByObj={sortByObj}
                enableIconColumn
            />

            <div className='my-branches--page'>
                {loader ? (
                    <Loader />
                ) : branches.length ? (
                    branches.map((item, index) => (
                        <Row
                            key={index}
                            className={`my-branches__rowWrap ${
                                index % 2 == 0 && "my-branches__rowGrey"
                            }`}
                        >
                            <Col key={item.sequenceNo} className='my-branches--pl20' sm='1' lg='1'>
                                {item.sequenceNo <= 9 ? "0" : ""}
                                {item.sequenceNo}
                            </Col>
                            <Col key={item.name} sm='2' lg='2'>
                                {item.name}
                            </Col>

                            <Col key={item.code} sm='2' lg='2'>
                                {item.code}
                            </Col>
                            <Col key={item.cityName} sm='1' lg='1'>
                                {item.cityName}
                            </Col>
                            <Col key={item.countryName} sm='2' lg='2'>
                                {item.countryName}
                            </Col>
                            <Col key={index + 1} sm='2' lg='2'>
                                {item.isActive
                                    ? t("VIEW.BRANCHES.ACTIVE")
                                    : t("VIEW.BRANCHES.INACTIVE")}
                            </Col>
                            <Col key={item.id} sm='2' lg='2'>
                                <LoadingButton
                                    label={`${
                                        selectedBranch?.id === item.id
                                            ? t("COMMON.SELECTED")
                                            : t("COMMON.SELECT")
                                    }`}
                                    disabled={!item.isActive || selectedBranch?.id === item.id}
                                    onClick={() => handleBranchClick(item)}
                                    btnClass={selectedBranch?.id === item.id ? "m-0" : ""}
                                    variant={selectedBranch?.id === item.id ? "upload" : "submit"}
                                />
                            </Col>
                        </Row>
                    ))
                ) : (
                    <div className='my-branches--noData'>{t("APIMSG.NODATA")}</div>
                )}
            </div>
            <Pagination
                sortByObj={sortByObj}
                onPageChange={onPageChange}
                totalPages={totalPages}
                pageSize={pageSize}
                pageNumber={pageNumber}
            />
        </Container>
    );
};

export default MyBranchesPage;
