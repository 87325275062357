import { t } from "i18next";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import Icons from "@app/assets/Icons";
import InactiveSubmission from "@app/components/InactiveSubmission";
import Loader from "@app/components/Loader";
import Pagination from "@app/components/Pagination";
import TableHeader from "@app/components/TableHeader";
import ViewUtilityHeader from "@app/components/ViewUtilityHeader";
import { RouteNames } from "@app/routes/index";
import { getBranchesData } from "@app/services/Endpoints/Branches.API";
import { HeadersMapInterface } from "@app/types/Common.types";
import { Branches } from "@app/types/Service.type";
import { AppContext } from "@app/utils/contexts/App.context";
import { FilterContext } from "@app/utils/contexts/Filters.context";

import "./index.scss";

let totalPages = 0;
const sortByEnum = {
    DESC: 1,
    ASC: 0,
};

const ViewBranchPage: FC = () => {
    const { userPermissions } = useContext(AppContext);
    const { statusesFilter, countriesFilter, citiesFilter, licenseesFilter } =
        useContext(FilterContext);
    const navigate = useNavigate();
    const { state } = useLocation();
    const [branches, setBranches] = useState<Branches[]>([]);
    const [sortByObj, setSortByObj] = useState({ sortBy: "name", sortOrder: sortByEnum.DESC });
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loader, setLoader] = useState<boolean>(false);
    const [searchVal, setSearchVal] = useState<string>("");
    const [isScrollComing, setIsScrollComing] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useRef<any>();
    const HeadersMap: HeadersMapInterface = {
        serialNo: {
            label: t("VIEW.BRANCHES.SERIALNO"),
            layout: { lg: 1, sm: 1 },
            disableSort: true,
        },
        name: { label: t("VIEW.BRANCHES.NAME"), layout: { lg: 2, sm: 2 } },
        code: {
            label: t("VIEW.BRANCHES.ID"),
            layout: { lg: 1, sm: 1 },
        },
        cityName: {
            label: t("VIEW.BRANCHES.CITY"),
            layout: { lg: 1, sm: 1 },
        },
        countryName: {
            label: t("VIEW.BRANCHES.COUNTRY"),
            layout: { lg: 2, sm: 2 },
        },
        licenseeName: { label: t("VIEW.BRANCHES.LICENSEE"), layout: { lg: 2, sm: 2 } },
        isActive: { label: t("VIEW.BRANCHES.STATUS"), layout: { lg: 2, sm: 2 } },
    };

    const { userId = undefined, userName = undefined } = { ...state };

    useEffect(() => {
        setPageNumber(1);
    }, [statusesFilter, countriesFilter, citiesFilter, licenseesFilter, userId]);

    useEffect(() => {
        const filters = [
            statusesFilter?.map((status) => `&IsActiveFlags=${status}`).join(""),
            countriesFilter?.map((countryId) => `&CountryIDs=${countryId}`).join(""),
            citiesFilter?.map((cityId) => `&CityIDs=${cityId}`).join(""),
            licenseesFilter?.map((licenseeId) => `&LicenseeIDs=${licenseeId}`).join(""),
            userId && `UserId=${userId}`,
        ].join("&");

        getCustomisations(
            sortByObj.sortBy,
            sortByObj.sortOrder,
            pageNumber,
            pageSize,
            searchVal,
            filters,
        );
    }, [
        pageNumber,
        pageSize,
        sortByObj,
        searchVal,
        statusesFilter,
        countriesFilter,
        citiesFilter,
        licenseesFilter,
        userId,
    ]);

    useEffect(() => {
        if (ref.current?.offsetWidth != ref.current?.clientWidth) {
            setIsScrollComing(true);
        } else {
            setIsScrollComing(false);
        }
    });

    const getCustomisations = (
        sortBy: string,
        sortOrder: number,
        pageNumber: number,
        pageSize: number,
        name?: string,
        filters?: string,
    ) => {
        setLoader(true);
        getBranchesData(sortBy, sortOrder, pageNumber, pageSize, name, filters)
            .then((res) => {
                totalPages = res.data.totalPages;
                const result = res?.data?.data;
                if (result) {
                    setBranches([...result]);
                } else {
                    setBranches([]);
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setLoader(false));
    };

    const sortByHandler = (headerKey: string) => {
        setSortByObj({ sortBy: headerKey, sortOrder: Number(!sortByObj.sortOrder) });
    };

    const onChangeRecordsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setPageSize(Number(value));
        setPageNumber(1);
    };

    const onPageChange = (pageNumber: number) => {
        setPageNumber(Number(pageNumber));
    };
    const onEdit = (branchData: Branches) => {
        navigate(RouteNames.Edit, {
            state: { branchEditData: branchData },
        });
    };
    const redirectToAddCustomisation = () => {
        navigate(RouteNames.Add);
    };

    const handleSearch = (val: string) => {
        setSearchVal(val);
    };
    return (
        <Container fluid className='branches'>
            <ViewUtilityHeader
                onChange={onChangeRecordsPerPage}
                placeholder='Search for Branch name'
                btnLabel={t("VIEW.BRANCHES.ADDBRANCHES")}
                onClick={userPermissions?.Create_Branch ? redirectToAddCustomisation : undefined}
                onSearch={handleSearch}
            />

            {userName && userId && (
                <InactiveSubmission title='Branches for User:' subTitle={userName} />
            )}

            <TableHeader
                headersMap={HeadersMap}
                sortByHandler={sortByHandler}
                sortByObj={sortByObj}
                enableIconColumn
            />

            <div
                className={`branches--page ${isScrollComing ? "branches--isScroll" : ""}`}
                id='listing-Outercontainer'
                ref={ref}
            >
                {loader ? (
                    <Loader />
                ) : branches.length ? (
                    <div id='listing-Innercontainer'>
                        {branches.map((item, index) => (
                            <Row
                                key={index}
                                className={`branches__rowWrap ${
                                    index % 2 == 0 && "branches__rowGrey"
                                }`}
                            >
                                <Col className='branches--pl20' sm='1' lg='1'>
                                    {item.sequenceNo <= 9 ? "0" : ""}
                                    {item.sequenceNo}
                                </Col>
                                <Col sm='2' lg='2'>
                                    {item.name}
                                </Col>

                                <Col sm='1' lg='1'>
                                    {item.code}
                                </Col>
                                <Col sm='1' lg='1'>
                                    {item.cityName}
                                </Col>
                                <Col sm='2' lg='2'>
                                    {item.countryName}
                                </Col>
                                <Col sm='2' lg='2'>
                                    {item.licenseeName}
                                </Col>
                                <Col sm='2' lg='2'>
                                    {item.isActive
                                        ? t("VIEW.BRANCHES.ACTIVE")
                                        : t("VIEW.BRANCHES.INACTIVE")}
                                </Col>
                                <Col sm='1' lg='1'>
                                    {userPermissions?.Edit_Branch && (
                                        <img
                                            className='customisation__enablepointer'
                                            src={Icons.EditIcon}
                                            onClick={() => onEdit(item)}
                                            height='20px'
                                        />
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </div>
                ) : (
                    <div className='branches--noData'>{t("APIMSG.NODATA")}</div>
                )}
            </div>
            <Pagination
                sortByObj={sortByObj}
                onPageChange={onPageChange}
                totalPages={totalPages}
                pageSize={pageSize}
                pageNumber={pageNumber}
            />
        </Container>
    );
};

export default ViewBranchPage;
