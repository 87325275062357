import { t } from "i18next";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FormWrapper from "@app/components/FormWrapper";
import AddProduct from "@app/components/forms/Product.form";
import { RoutePaths } from "@app/routes/index";
import { getItems } from "@app/services/Endpoints/Item.API";
import { addProduct, getProductCategory, updateProduct } from "@app/services/Endpoints/Product.API";
import { getStations } from "@app/services/Endpoints/Station";
import { ItemErrors, ModalType, ProductErrors } from "@app/types/Common.types";
import { CreateProduct, items, ProductCategory, ProductFormData } from "@app/types/Product.types";
import { Station, StationList } from "@app/types/Station.types";
import { Item } from "@app/types/items.types";
import { FormContext } from "@app/utils/contexts/Form.context";
import { getSecondsInTime, getTimeInSeconds } from "@app/utils/timeHelpers";

const initialFormData: ProductFormData = {
    name: "",
    displayName: "",
    code: "",
    holdingTime: "00:00:00",
    assemblyCapacity: "",
    productCategoryId: null,
    imageId: "",
    assemblyStationId: null,
    directDispensing: false,
    status: true,
    items: [
        {
            itemId: "",
            quantity: "",
            isPrimary: false,
        },
    ],
    imageName: "",
};

interface ProductContainerProps {
    product?: CreateProduct;
}

const ProductContainer: FC<ProductContainerProps> = ({ product }) => {
    const navigate = useNavigate();

    const { triggerShowModal, setActions, isDirty, setIsDirty } = useContext(FormContext);

    const [assemblyStations, setAssemblyStations] = useState<Station[]>([]);
    const [newProductData, setNewProductData] = useState<ProductFormData>(initialFormData);
    const [errors, setErrors] = useState<Partial<ProductErrors>>();
    const [productCategory, setProductCategory] = useState<ProductCategory[]>([]);
    const [items, setItems] = useState<Item[]>([]);

    const fetchProductCategory = useCallback(() => {
        getProductCategory()
            .then((res) => {
                setProductCategory([...res.data.data]);
            })
            .catch(() => setProductCategory([]));
    }, []);

    const fetchStations = useCallback(() => {
        getStations().then((res) => {
            const assemblyStations =
                res?.data?.data?.find((station: StationList) => station.stationType === "Assembly")
                    .stations ?? [];
            setAssemblyStations([...assemblyStations]);
        });
    }, []);

    const fetchItems = useCallback(() => {
        getItems()
            .then((res) => {
                setItems([...res.data.data]);
            })
            .catch(() => setProductCategory([]));
    }, []);

    useEffect(() => {
        fetchProductCategory();
        fetchStations();
        fetchItems();
    }, [fetchProductCategory, fetchStations, fetchItems]);

    useEffect(() => {
        if (product) {
            const {
                name,
                displayName,
                code,
                holdingTime,
                assemblyCapacity,
                productCategoryId,
                imageId,
                assemblyStationId,
                directDispensing,
                status,
                items,
                imageName,
            } = {
                ...product,
            };

            setNewProductData({
                name,
                displayName,
                code,
                holdingTime: getSecondsInTime(holdingTime),
                assemblyCapacity: String(assemblyCapacity),
                productCategoryId,
                imageId,
                assemblyStationId,
                directDispensing,
                status,
                items,
                imageName,
            });
        }
    }, [product]);

    const handleChange = (name: string, value: string | string[] | number | boolean | items[]) => {
        const data: ProductFormData = { ...newProductData };
        if (errors?.[name]) {
            setErrors({ ...errors, [name]: "" });
        }
        setNewProductData({ ...data, [name]: value });
    };

    const handleCancel = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    setNewProductData(initialFormData);
                    setErrors(undefined);
                    navigate(RoutePaths.ViewProducts);
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        } else navigate(RoutePaths.ViewProducts);
    };

    const handleCreateProduct = () => {
        addProduct({
            ...newProductData,
            holdingTime: getTimeInSeconds(newProductData.holdingTime),
            assemblyCapacity: Number(newProductData.assemblyCapacity),
            items: newProductData.directDispensing
                ? []
                : newProductData.items.map((item) => ({
                      ...item,
                      quantity: Number(item.quantity) as number,
                  })),
            assemblyStationId: newProductData.directDispensing
                ? null
                : newProductData.assemblyStationId,
            productCategoryId: newProductData.directDispensing
                ? null
                : newProductData.productCategoryId,
        })
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                navigate(RoutePaths.ViewProducts);
                setIsDirty(false);
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleUpdateProduct = () => {
        if (newProductData.directDispensing) {
            newProductData.items = [];
        }
        updateProduct({
            ...newProductData,
            id: String(product?.id),
            holdingTime: newProductData.directDispensing
                ? 0
                : getTimeInSeconds(newProductData.holdingTime),
            assemblyCapacity: newProductData.directDispensing
                ? 0
                : Number(newProductData.assemblyCapacity),
            items: newProductData.items,
            assemblyStationId: newProductData.directDispensing
                ? null
                : newProductData.assemblyStationId,
            productCategoryId: newProductData.directDispensing
                ? null
                : newProductData.productCategoryId,
        })
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                navigate(RoutePaths.ViewProducts);
                setIsDirty(false);
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleSubmit = () => {
        const {
            name,
            displayName,
            code,
            assemblyCapacity,
            productCategoryId,
            holdingTime,
            imageId,
            assemblyStationId,
            items,
        } = newProductData;
        const isNameValid = !!name;
        const isDisplayNameValid = !!displayName;
        const isCodeValid = !!code;
        const isHoldingTimeValid = getTimeInSeconds(holdingTime) > 0;
        const isAssemblyCapacityValid = Number(assemblyCapacity) >= 1;
        const isProductCategoryIdValid = !!productCategoryId;
        const isImageIdValid = !!imageId;
        const isAssemblyStationIdValid = !!assemblyStationId;

        const validItems = items.filter((item) => item.itemId && item.quantity);
        const nonEmptyItems = items.filter((item) => item.itemId || item.quantity);

        const allItemsValid = validItems.length >= 1 && validItems.length === nonEmptyItems.length;

        // const isDataValid =
        //     (isNameValid && isDisplayNameValid && isCodeValid && isImageIdValid) ||
        //     (!newProductData.directDispensing &&
        //         isHoldingTimeValid &&
        //         isAssemblyCapacityValid &&
        //         isProductCategoryIdValid &&
        //         isAssemblyStationIdValid &&
        //         allItemsValid);

        const isDataValid = newProductData.directDispensing
            ? isNameValid && isDisplayNameValid && isCodeValid && isImageIdValid
            : isNameValid &&
              isDisplayNameValid &&
              isCodeValid &&
              isImageIdValid &&
              isHoldingTimeValid &&
              isAssemblyCapacityValid &&
              isProductCategoryIdValid &&
              isAssemblyStationIdValid &&
              allItemsValid;

        if (isDataValid) {
            setErrors(undefined);
            triggerShowModal(true, ModalType.SUBMIT);
            setActions({
                done: () => (product ? handleUpdateProduct() : handleCreateProduct()),
                cancel: () => triggerShowModal(false, ModalType.SUBMIT),
            });
        } else {
            const currentErrors: Partial<ProductErrors> = {};
            if (!isNameValid)
                currentErrors.name = `${t("ERRORS.REQUIRED", {
                    name: "Product Name",
                })}`;
            if (!isDisplayNameValid)
                currentErrors.displayName = `${t("ERRORS.REQUIRED", {
                    name: "Display Name",
                })}`;
            if (!isCodeValid)
                currentErrors.code = `${t("ERRORS.REQUIRED", {
                    name: "Code",
                })}`;
            if (!isHoldingTimeValid)
                currentErrors.holdingTime = `${t("ERRORS.LESS_THAN_ONE", {
                    name: "Holding Time",
                    number: 0,
                })}`;
            if (!isAssemblyCapacityValid)
                currentErrors.assemblyCapacity = `${t("ERRORS.REQUIRED", {
                    name: "Assembly Capacity",
                })}`;
            if (!isProductCategoryIdValid)
                currentErrors.productCategoryId = `${t("ERRORS.REQUIRED", {
                    name: "Product Category",
                })}`;
            if (!isImageIdValid)
                currentErrors.imageId = `${t("ERRORS.REQUIRED", {
                    name: "Image ",
                })}`;
            if (!isAssemblyStationIdValid)
                currentErrors.assemblyStationId = `${t("ERRORS.REQUIRED", {
                    name: "Assembly Station",
                })}`;
            if (!validItems.length) {
                const itemErrors: ItemErrors[] = [
                    {
                        itemId: "",
                        quantity: "",
                    },
                ];
                itemErrors[0].itemId = `${t("ERRORS.REQUIRED", {
                    name: "Primary Item",
                })}`;
                currentErrors.items = itemErrors;
            }
            if (!allItemsValid) {
                const itemErrors: ItemErrors[] = [];
                items.forEach((item) => {
                    const itemError = {
                        itemId: "",
                        quantity: "",
                    };
                    if (!item.itemId) {
                        itemError.itemId = `${t("ERRORS.REQUIRED", {
                            name: "Item",
                        })}`;
                    }
                    if (!item.quantity) {
                        itemError.quantity = `${t("ERRORS.REQUIRED", {
                            name: "Quantity",
                        })}`;
                    }
                    itemErrors.push(itemError);
                });
                currentErrors.items = itemErrors;
            }
            setErrors({ ...errors, ...currentErrors });
        }
    };

    return (
        <FormWrapper
            heading={
                window.location.href.includes("edit") ? "Edit Product details" : t("PRODUCT.HEADER")
            }
            onCancelClick={handleCancel}
            onSubmitClick={handleSubmit}
            submitDisabled={!isDirty}
        >
            <AddProduct
                data={newProductData}
                onChange={handleChange}
                items={items?.map(({ id, name }) => ({ value: id, label: name }))}
                productCatgoryOptions={productCategory?.map(({ id, value }) => ({
                    value: id,
                    label: value,
                }))}
                assemblyStations={assemblyStations?.map(({ stationId, displayName }) => ({
                    value: stationId,
                    label: displayName,
                }))}
                errors={errors}
                isEdit={!!product}
            />
        </FormWrapper>
    );
};

export default ProductContainer;
