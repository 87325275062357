import { MasterAPIService } from "@app/services/Methods/api";
import { ItemFormData } from "@app/types/Item";

export const getItems = () => MasterAPIService.get("/item");

export const getItemsData = (
    sortBy?: string,
    sortOrder?: number,
    pageNumber?: number,
    pageSize?: number,
    name?: string,
    filters?: string,
) =>
    MasterAPIService.get(
        `/item/getItemsForView?${sortBy ? `sortBy=${sortBy}&` : ""}${
            sortOrder ? `sortOrder=${sortOrder}&` : ""
        }${pageNumber ? `pageNumber=${pageNumber}&` : ""}${
            pageSize ? `pageSize=${pageSize}&` : ""
        }${name ? `name=${name}&` : ""}${filters ? filters : ""}`,
    );

export const getItemUnits = () => MasterAPIService.get("/item/getItemUnits");

export const getCookingStations = () => MasterAPIService.get("/station");

export const getMachines = () => MasterAPIService.get("/machine");

export const getItemCategories = () => MasterAPIService.get("/item/getItemCategories");
export const createItem = (data: ItemFormData) => MasterAPIService.post("/item", data);
export const updateItem = (data: ItemFormData) => MasterAPIService.patch("/item", data);
