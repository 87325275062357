import { ResponseTypes } from "@app/types/Common.types";

export const generateErrorMessage = (messages: string[]) => {
    if (messages.length > 1) {
        return (
            <div>
                {messages?.map((message, index) => (
                    <p key={message}>
                        {index + 1}: {message}
                    </p>
                ))}
            </div>
        );
    }
    return messages[0];
};

export const shouldAutoClose = (code: ResponseTypes) => {
    switch (code) {
        case ResponseTypes.INVALID_INPUT:
        case ResponseTypes.DUPLICATE_ENTITY:
            return false;
        default:
            return 5000;
    }
};
