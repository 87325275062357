import { MasterAPIService, OperationalAPIService } from "@app/services/Methods/api";
import { postMachineInterface, postTanjaraInterface } from "@app/types/SelectMachine.types";

export const getSelectedMachines = () => OperationalAPIService.get("/machine/configured-stations");
export const getMachineMake = () => MasterAPIService.get("/machine/manufacturers");
export const getMachineMakeByStationId = (stationId: string) =>
    MasterAPIService.get(`/machine/manufacturers-by-stationid?stationId=${stationId}`);
export const getMachineName = (manufactureId: string, stationId: string) =>
    MasterAPIService.get(`/machine/machine-names?makeId=${manufactureId}&stationId=${stationId}`);
export const postTanjara = (data: postTanjaraInterface) =>
    OperationalAPIService.post("/machine/save-tanjara", data);
export const postMachine = (data: postMachineInterface) =>
    OperationalAPIService.post("machine/create", data);
export const deleteMachine = (machineID: string) =>
    OperationalAPIService.delete(`/machine/delete?Id=${machineID}`);
