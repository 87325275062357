import { FC, Key, useContext, useEffect, useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";

import Checkbox from "@app/components/inputs/Checkbox";
import { getStations } from "@app/services/Endpoints/Station";
import { Station, StationList } from "@app/types/Station.types";
import { FilterContext } from "@app/utils/contexts/Filters.context";

const ItemFilters: FC = () => {
    const {
        handleStatusesFilter,
        statusesFilter,
        setStatusesFilter,
        stationsFilter,
        setStationsFilter,
        handleStationsFilter,
    } = useContext(FilterContext);

    const [cookingStations, setCookingStations] = useState<Station[]>([]);
    const [key, setKey] = useState<string | undefined | null>();

    useEffect(() => {
        getStations().then((res) => {
            const cookingStations =
                res?.data?.data?.find((station: StationList) => station.stationType === "Cooking")
                    .stations ?? [];
            setCookingStations([...cookingStations]);
        });
    }, []);

    return (
        <Accordion
            activeKey={key}
            onSelect={(k) => setKey(String(k))}
            flush
            className='filters__item'
        >
            <Accordion.Item eventKey='0' className=''>
                <Accordion.Header className='filters__item-header'>
                    <span>Status</span>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            position: "absolute",
                            right: "20%",
                        }}
                        variant='text'
                        onClick={(e) => {
                            e.stopPropagation();
                            setStatusesFilter(undefined);
                        }}
                    >
                        Clear
                    </Button>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        <Col xs={12} className='filters__checkbox'>
                            <Checkbox
                                label='Active'
                                checked={!!statusesFilter?.includes(true)}
                                onClick={() => handleStatusesFilter(true)}
                            />
                        </Col>
                        <Col xs={12} className='filters__checkbox'>
                            <Checkbox
                                label='Inactive'
                                checked={!!statusesFilter?.includes(false)}
                                onClick={() => handleStatusesFilter?.(false)}
                            />
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
                <Accordion.Header className='filters__item-header'>
                    <span>Station</span>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            position: "absolute",
                            right: "20%",
                        }}
                        variant='text'
                        onClick={() => setStationsFilter(undefined)}
                    >
                        Clear
                    </Button>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        {cookingStations?.map((item, index: Key | null | undefined) => (
                            <Col key={index} xs={12} className='filters__checkbox'>
                                <Checkbox
                                    label={item.displayName}
                                    checked={!!stationsFilter?.includes(item.stationId)}
                                    onClick={() => handleStationsFilter?.(item.stationId)}
                                />
                            </Col>
                        ))}
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default ItemFilters;
