import { MasterAPIService } from "@app/services/Methods/api";
import {
    FeaturesResponse,
    RolesResponse,
    RoleUsersResponse,
    userDetailsResponse,
    UserResponse,
} from "@app/types/API.types";
import { Role, User } from "@app/types/User.type";

export const getFeatures = (level: number) =>
    MasterAPIService.get<FeaturesResponse>(`/role/features/${level}`);

export const getRoles = (
    level?: number | string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber?: number,
    pageSize?: number,
    name?: string,
    filters?: string,
) =>
    MasterAPIService.get<RolesResponse>(
        `/role/search?${level ? `UserLevels=${level}&` : ""}${sortBy ? `sortBy=${sortBy}&` : ""}${
            sortOrder ? `sortOrder=${sortOrder}&` : ""
        }${pageNumber ? `pageNumber=${pageNumber}&` : ""}${
            pageSize ? `pageSize=${pageSize}&` : ""
        }${name ? `name=${name}&` : ""}${filters ? filters : ""}`,
    );

export const createUserRole = (data: Partial<Role>) =>
    MasterAPIService.post<Partial<Role>>("/role", data);

export const getUserDetails = (user: string) =>
    MasterAPIService.get<userDetailsResponse>(`/users/find?userId=${user}`);

export const getUserRoleDetails = (id: string) => MasterAPIService.get(`/role/${id}`);

export const updateUserRole = (id: string, data: Partial<Role>) =>
    MasterAPIService.patch<Partial<Role>>(`/role/${id}`, data);

export const getUsersOfRole = (id: string, max: number) =>
    MasterAPIService.get<RoleUsersResponse>(`/role/users/${id}/${max}`);

export const getUsersData = (
    sortBy?: string,
    sortOrder?: number,
    pageNumber?: number,
    pageSize?: number,
    name?: string,
    filters?: string,
) =>
    MasterAPIService.get(
        `/users/search?${sortBy ? `sortBy=${sortBy}&` : ""}${
            sortOrder ? `sortOrder=${sortOrder}&` : ""
        }${pageNumber ? `pageNumber=${pageNumber}&` : ""}${
            pageSize ? `pageSize=${pageSize}&` : ""
        }${name ? `name=${name}&` : ""}${filters ? filters : ""}`,
    );

export const createAlbaikUser = (data: Partial<User>) =>
    MasterAPIService.post<UserResponse>("/users/albaik", data);

export const createLincenseeUser = (data: Partial<User>) =>
    MasterAPIService.post<UserResponse>("/users/licensee", data);

export const createBranchUser = (data: Partial<User>) =>
    MasterAPIService.post<UserResponse>("/users/branch", data);

export const updateAlbaikUser = (data: Partial<User>) =>
    MasterAPIService.patch<UserResponse>("/users/albaik", data);

export const updateLincenseeUser = (data: Partial<User>) =>
    MasterAPIService.patch<UserResponse>("/users/licensee", data);

export const updateBranchUser = (data: Partial<User>) =>
    MasterAPIService.patch<UserResponse>("/users/branch", data);

export const getAlbaikUser = (id: string) =>
    MasterAPIService.get<UserResponse>(`/users/albaik/${id}`);

export const getLincenseeUser = (id: string) =>
    MasterAPIService.get<UserResponse>(`/users/licensee/${id}`);

export const getBranchUser = (id: string) =>
    MasterAPIService.get<UserResponse>(`/users/branch/${id}`);

export const getMe = () => MasterAPIService.get<UserResponse>("/users/signin");
