import { MasterAPIService } from "@app/services/Methods/api";
import { createLicenseeData } from "@app/types/Licensee";

export const getLicensee = (
    sortBy?: string,
    sortOrder?: number,
    pageNumber?: number,
    pageSize?: number,
    name?: string,
    countryIds?: string[],
    filters?: string,
) =>
    MasterAPIService.get(
        `/licensee/search?${sortBy ? `sortBy=${sortBy}&` : ""}${
            sortOrder ? `sortOrder=${sortOrder}&` : ""
        }${pageNumber ? `pageNumber=${pageNumber}&` : ""}${
            pageSize ? `pageSize=${pageSize}&` : ""
        }${name ? `name=${name}&` : ""}${countryIds?.length ? `CountryIDs=${countryIds}&` : ""}${
            filters ? filters : ""
        }`,
    );

export const getLicenseeByID = (id: string) => MasterAPIService.get(`/licensee/${id}`);

export const getLicenseeCountries = () => MasterAPIService.get("/common/countries");
export const createLicensee = (data: createLicenseeData) =>
    MasterAPIService.post<createLicenseeData>("/licensee", data);

export const updateLicensee = (data: createLicenseeData) =>
    MasterAPIService.patch<createLicenseeData>("/licensee", data);

export const getUserDetails = (user: string) => MasterAPIService.get(`/users/find?userId=${user}`);

export const getSingleUserLicensee = (id: string) =>
    MasterAPIService.get(`licensee/GetSingleUserLicensees?userId=${id}`);
