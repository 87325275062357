import { t } from "i18next";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Icons from "@app/assets/Icons";
import Loader from "@app/components/Loader";
import Pagination from "@app/components/Pagination";
import TableHeader from "@app/components/TableHeader";
import ViewUtilityHeader from "@app/components/ViewUtilityHeader";
import { RouteNames } from "@app/routes/index";
import { getLicensee } from "@app/services/Endpoints/Licensee.API";
import { HeadersMapInterface } from "@app/types/Common.types";
import { Licensee } from "@app/types/Licensee";
import { AppContext } from "@app/utils/contexts/App.context";
import { FilterContext } from "@app/utils/contexts/Filters.context";

import "./index.scss";

let totalPages = 0;
const sortByEnum = {
    DESC: 1,
    ASC: 0,
};

const ViewLinceseePage: FC = () => {
    const { userPermissions } = useContext(AppContext);
    const { statusesFilter, countriesFilter } = useContext(FilterContext);
    const HeadersMap: HeadersMapInterface = {
        serialNo: {
            label: t("VIEW.LICENSEE.SERIALNO"),
            layout: { lg: 1, sm: 1 },
            disableSort: true,
        },
        code: { label: t("VIEW.LICENSEE.ID"), layout: { lg: 1, sm: 1 } },
        name: { label: t("VIEW.LICENSEE.NAME"), layout: { lg: 2, sm: 2 } },
        adminUsers: {
            label: t("VIEW.LICENSEE.ADMIN"),
            layout: { lg: 3, sm: 3 },
            disableSort: true,
        },
        countryName: { label: t("VIEW.LICENSEE.COUNTRY"), layout: { lg: 2, sm: 2 } },
        IsActive: { label: t("VIEW.LICENSEE.STATUS"), layout: { lg: 2, sm: 2 } },
    };
    const [licensee, setLicensee] = useState<Licensee[]>([]);
    const [sortByObj, setSortByObj] = useState({
        sortBy: "IsActive",
        sortOrder: sortByEnum.DESC,
    });
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loader, setLoader] = useState<boolean>(false);
    const [searchVal, setSearchVal] = useState<string>("");
    const [isScrollComing, setIsScrollComing] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useRef<any>();

    const navigate = useNavigate();

    useEffect(() => {
        setPageNumber(1);
    }, [statusesFilter, countriesFilter]);

    useEffect(() => {
        const filters = [
            statusesFilter?.map((status) => `&IsActiveFlags=${status}`).join(""),
            countriesFilter?.map((countryId) => `&CountryIDs=${countryId}`).join(""),
        ].join("&");
        getLicenseeHandler(
            sortByObj.sortBy,
            sortByObj.sortOrder,
            pageNumber,
            pageSize,
            searchVal,
            filters,
        );
    }, [pageNumber, pageSize, sortByObj, searchVal, statusesFilter, countriesFilter]);

    useEffect(() => {
        if (ref.current?.offsetWidth != ref.current?.clientWidth) {
            setIsScrollComing(true);
        } else {
            setIsScrollComing(false);
        }
    });

    const getLicenseeHandler = (
        sortBy: string,
        sortOrder: number,
        pageNumber: number,
        pageSize: number,
        name?: string,
        filters?: string,
    ) => {
        setLoader(true);
        getLicensee(sortBy, sortOrder, pageNumber, pageSize, name, undefined, filters)
            .then((res) => {
                totalPages = res?.data.totalPages;
                const result = res?.data?.data;
                if (result) {
                    setLicensee([...result]);
                } else {
                    setLicensee([]);
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setLoader(false));
    };

    const sortByHandler = (headerKey: string) => {
        setSortByObj({ sortBy: headerKey, sortOrder: Number(!sortByObj.sortOrder) });
    };

    const onChangeRecordsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setPageSize(Number(value));
        setPageNumber(1);
    };

    const onPageChange = (pageNumber: number) => {
        setPageNumber(Number(pageNumber));
    };
    const onEdit = (LicenseeId: string) => {
        navigate(RouteNames.Edit, {
            state: { LicenseeId: LicenseeId },
        });
    };
    const redirectToAddLicensee = () => {
        navigate(RouteNames.Add);
    };
    const handleSearch = (val: string) => {
        setSearchVal(val);
    };

    return (
        <Container fluid className='licensee'>
            <ViewUtilityHeader
                onChange={onChangeRecordsPerPage}
                placeholder='Search for Licensee name'
                btnLabel={t("VIEW.LICENSEE.ADDLICENSEE")}
                onClick={userPermissions?.Create_Licensee ? redirectToAddLicensee : undefined}
                onSearch={handleSearch}
            />

            <TableHeader
                headersMap={HeadersMap}
                sortByHandler={sortByHandler}
                sortByObj={sortByObj}
                enableIconColumn
            />

            <div
                className={`licensee--page ${isScrollComing ? "licensee--isScroll" : ""}`}
                id='listing-Outercontainer'
                ref={ref}
            >
                {loader ? (
                    <Loader />
                ) : licensee.length ? (
                    <div id='listing-Innercontainer'>
                        {licensee.map((item, index) => (
                            <Row
                                key={index}
                                className={`licensee__rowWrap ${
                                    index % 2 == 0 && "licensee__rowGrey"
                                }`}
                            >
                                <Col className='licensee--pl20' sm='1' lg='1'>
                                    {item.sequenceNo <= 9 ? "0" : ""}
                                    {item.sequenceNo}
                                </Col>
                                <Col sm='1' lg='1'>
                                    {item.code}
                                </Col>

                                <Col sm='2' lg='2'>
                                    {item.name}
                                </Col>
                                <Col sm='3' lg='3'>
                                    {item.adminUsers}
                                </Col>
                                <Col sm='2' lg='2'>
                                    {item.countryName}
                                </Col>

                                <Col sm='2' lg='2'>
                                    {item.isActive
                                        ? t("VIEW.LICENSEE.ACTIVE")
                                        : t("VIEW.LICENSEE.INACTIVE")}
                                </Col>
                                <Col sm='1' lg='1'>
                                    {userPermissions?.Edit_Licensee && (
                                        <img
                                            className='licensee__enablepointer'
                                            src={Icons.EditIcon}
                                            onClick={() => onEdit(item.id)}
                                            height='20px'
                                        />
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </div>
                ) : (
                    <div className='licensee--noData'>{t("APIMSG.NODATA")}</div>
                )}
            </div>
            <Pagination
                sortByObj={sortByObj}
                onPageChange={onPageChange}
                totalPages={totalPages}
                pageSize={pageSize}
                pageNumber={pageNumber}
            />
        </Container>
    );
};

export default ViewLinceseePage;
