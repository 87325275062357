import { t } from "i18next";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";

import Icons from "@app/assets/Icons";
import LoadingButton from "@app/components/Button";
import ImageUpload from "@app/components/ImageUpload";
import SelectInput from "@app/components/inputs/Select";
import TextInput from "@app/components/inputs/Text";
import Toggle from "@app/components/inputs/Toggle";
import { ProductErrors } from "@app/types/Common.types";
import { items, ProductFormData } from "@app/types/Product.types";
import { AppContext } from "@app/utils/contexts/App.context";
import { FormContext } from "@app/utils/contexts/Form.context";

import "./index.scss";

type CustomChangeEvent = ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>;

interface AddProductProps {
    data: ProductFormData;
    items: { value: string; label: string }[];
    productCatgoryOptions: { value: string; label: string }[];
    assemblyStations: { value: string; label: string }[];
    errors?: Partial<ProductErrors>;
    isEdit?: boolean;
    onChange: (_name: string, _value: string | string[] | number | boolean | items[]) => void;
}

const AddProduct: FC<AddProductProps> = ({
    isEdit,
    items,
    productCatgoryOptions,
    errors,
    data,
    onChange,
    assemblyStations,
}) => {
    const { setIsDirty } = useContext(FormContext);
    const { userPermissions } = useContext(AppContext);

    const [selectedItem, setSelectedItem] = useState<string[]>([]);

    const addedItems = [
        ...data.items.filter((item) => item.isPrimary),
        ...data.items.filter((item) => !item.isPrimary),
    ];

    const handleImageUpload = (id: string) => {
        setIsDirty(true);
        onChange("imageId", id);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setIsDirty(true);
        const { name, value } = e.target;
        if (name === "assemblyCapacity") {
            if (value != "0") onChange(name, value);
        } else {
            onChange(name, value);
        }
    };

    useEffect(() => {
        onChange("items", [{ itemId: "", quantity: "", isPrimary: false }]);
    }, []);

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        index: number,
    ) => {
        setIsDirty(true);
        const { name, value } = e.target;
        const list = [...addedItems];
        if (name === "itemId") {
            list[index].itemId = value;
            const updatedSelectedItem = [...selectedItem];
            updatedSelectedItem[index] = value;
            setSelectedItem(updatedSelectedItem);
        } else {
            if (value != "0") list[index].quantity = value;
        }
        list[index].isPrimary = index === 0;
        onChange("items", list);
    };

    const handleRemoveClick = (index: number) => {
        setIsDirty(true);
        const list = [...addedItems];
        const filteredItems = list.filter((_, indexFilter) => !(indexFilter === index));
        onChange("items", filteredItems);
        const filterSelectedItem = selectedItem.filter(
            (_, indexFilter) => !(indexFilter === index),
        );
        setSelectedItem(filterSelectedItem);
    };

    const handleAddClick = () => {
        onChange("items", [...addedItems, { itemId: "", quantity: "", isPrimary: false }]);
    };

    return (
        <Container fluid className='py-4 px-5'>
            <Row className='mb-3'>
                <br />
                <Col xs={6}>
                    <TextInput
                        label={`${t("PRODUCT.PRODUCTNAME")}`}
                        placeholder={"Enter Product Name"}
                        value={data?.name}
                        onChange={handleChange}
                        name='name'
                        error={errors?.name}
                        disabled={isEdit}
                    />
                </Col>
                <Col sm={6}>
                    <TextInput
                        label={`${t("PRODUCT.PRODUCTID")}`}
                        placeholder={"Enter Product ID"}
                        value={data?.code}
                        onChange={handleChange}
                        name='code'
                        error={errors?.code}
                        disabled={isEdit}
                    />
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col sm={6}>
                    <TextInput
                        label={`${t("PRODUCT.DISPLAYNAME")}`}
                        placeholder={"Enter Display Name"}
                        value={data?.displayName}
                        onChange={handleChange}
                        name='displayName'
                        error={errors?.displayName}
                    />
                </Col>
                <Col sm={6}>
                    <div>
                        <h6 className='customization-form__label'>{t("PRODUCT.UPLOADIMAGE")}</h6>
                        <ImageUpload
                            onUpload={handleImageUpload}
                            fetchImage={data?.imageId}
                            error={errors?.imageId}
                            fetchImageName={data?.imageName}
                        />
                    </div>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col sm={6}>
                    <div className='mb-3'>
                        <div className='customization-form__label mb-3'>
                            {t("PRODUCT.DIRECTDISPENSING")}
                        </div>
                        <Toggle
                            isActive={data.directDispensing}
                            onToggle={() => {
                                setIsDirty(true);
                                onChange("directDispensing", !data.directDispensing);
                            }}
                            variant={"on"}
                        />
                    </div>
                </Col>
                <Col sm={3}>
                    <TextInput
                        type='duration'
                        label={`${t("PRODUCT.HOLDINGTIME")}`}
                        placeholder=''
                        value={data.directDispensing ? "" : data?.holdingTime}
                        onChange={handleChange}
                        name='holdingTime'
                        error={data.directDispensing ? "" : errors?.holdingTime}
                        disabled={data.directDispensing}
                    />
                </Col>
                <Col sm={3}>
                    <TextInput
                        type='number'
                        label={`${t("PRODUCT.ASSEMBLYCAPACITY")}`}
                        placeholder='00'
                        value={data.directDispensing ? "" : data?.assemblyCapacity}
                        onChange={handleChange}
                        name='assemblyCapacity'
                        error={data.directDispensing ? "" : errors?.assemblyCapacity}
                        disabled={data.directDispensing}
                    />
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col xs={6}>
                    <SelectInput
                        label={`${t("PRODUCT.ASSEMBLYSTATION")}`}
                        value={!data.directDispensing ? data?.assemblyStationId || "" : ""}
                        onChange={handleChange}
                        name='assemblyStationId'
                        options={[
                            { value: "", label: "Select Assembly Station" },
                            ...assemblyStations,
                        ]}
                        error={data.directDispensing ? "" : errors?.assemblyStationId}
                        disabled={data.directDispensing}
                    />
                </Col>
                <Col xs={6}>
                    <SelectInput
                        label={`${t("PRODUCT.PRODUCTCATAGORY")}`}
                        value={!data.directDispensing ? data?.productCategoryId || "" : ""}
                        onChange={handleChange}
                        name='productCategoryId'
                        options={[
                            { value: "", label: "Select Product Category" },
                            ...productCatgoryOptions,
                        ]}
                        error={data.directDispensing ? "" : errors?.productCategoryId}
                        disabled={data.directDispensing}
                    />
                </Col>
            </Row>
            <Row className='mb-4 mt-4 '>
                <Col className='add-product__item-label'>{t("PRODUCT.ITEMDETAILS")}</Col>
            </Row>

            {addedItems.map((item, i) => (
                <Row className='mb-3' key={`item_${i}_${item?.itemId}`}>
                    <Col xs={5}>
                        <Form.Group className='select-input'>
                            <Form.Label className='select-input__label'>
                                {i === 0
                                    ? `${t("PRODUCT.PRIMARYITEM")}`
                                    : `${t("PRODUCT.ADDITIONALITEM")}`}
                            </Form.Label>
                            <Select
                                value={
                                    item?.itemId
                                        ? {
                                              value: item?.itemId,
                                              label:
                                                  items.find((it) => it?.value === item?.itemId)
                                                      ?.label || "",
                                          }
                                        : null
                                }
                                onChange={(selectedOption) =>
                                    handleInputChange(
                                        {
                                            target: {
                                                name: "itemId",
                                                value: selectedOption?.value || "",
                                            },
                                        } as CustomChangeEvent,
                                        i,
                                    )
                                }
                                options={[
                                    ...items
                                        .map((item) => ({
                                            value: item?.value,
                                            label: item?.label,
                                        }))
                                        .filter((option) => !selectedItem.includes(option?.value)),
                                ]}
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        border: "1px solid #bababa",
                                        borderRadius: "3px",
                                        height: "50px",
                                    }),
                                }}
                                isDisabled={data?.directDispensing}
                                isClearable
                                isSearchable
                            />
                            {!data.directDispensing && errors?.items?.[i]?.itemId && (
                                <Form.Text className='select-input__error'>
                                    <img src={Icons.ErrorGreyIcon} />{" "}
                                    <span>{errors?.items?.[i]?.itemId}</span>
                                </Form.Text>
                            )}
                        </Form.Group>
                    </Col>
                    <Col xs={4}>
                        <TextInput
                            type='number'
                            label={`${t("PRODUCT.QUANTITY")}`}
                            placeholder='00'
                            value={data.directDispensing ? "" : String(item.quantity)}
                            onChange={(e) => handleInputChange(e, i)}
                            name='items'
                            disabled={data.directDispensing}
                            decimalAllowed
                            error={data.directDispensing ? "" : errors?.items?.[i]?.quantity}
                        />
                    </Col>
                    <Col xs={3} className='mt-4 pt-1'>
                        {i === 0 && (
                            <LoadingButton
                                onClick={() => {
                                    handleAddClick();
                                }}
                                disabled={data.directDispensing || !item.itemId || !item.quantity}
                                icon={
                                    <img
                                        src={Icons.PlusIcon}
                                        className='add-product__dustbin-icon'
                                    />
                                }
                                variant='upload'
                                label='Add Item'
                            />
                        )}
                        {i !== 0 && (
                            <LoadingButton
                                onClick={() => handleRemoveClick(i)}
                                disabled={data.directDispensing}
                                icon={
                                    <img
                                        src={Icons.DustbinIcon}
                                        className='add-product__dustbin-icon'
                                    />
                                }
                                variant='upload'
                                label='Delete'
                            />
                        )}
                    </Col>
                </Row>
            ))}

            <Row>
                {isEdit && userPermissions?.Deactivate_Product && (
                    <Row className='mt-4'>
                        <Col>
                            <Form.Group>
                                <Form.Label className='text-input__label'>
                                    {t("MACHINE.CREATE.STATUS.LABEL")}
                                </Form.Label>
                                <Toggle
                                    isActive={data?.status === true}
                                    onToggle={() => {
                                        setIsDirty(true);
                                        onChange("status", data.status === true ? false : true);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                )}
            </Row>
        </Container>
    );
};

export default AddProduct;
