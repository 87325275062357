import {
    Feature,
    FeatureName,
    PermisionNames,
    RoleFormFeatures,
    UserLevel,
} from "@app/types/User.type";

export const getFeaturePermissions = (featureId: string, features: Feature[]) => {
    const allPermissions = features.find((feature) => feature.id === featureId)?.permissions;

    const createPermissionID = allPermissions?.find(
        (permission) => permission.name === PermisionNames.CREATE,
    )?.id;
    const readPermissionID = allPermissions?.find(
        (permission) => permission.name === PermisionNames.READ,
    )?.id;
    const editPermissionID = allPermissions?.find(
        (permission) => permission.name === PermisionNames.EDIT,
    )?.id;
    const deactivatePermissionID = allPermissions?.find(
        (permission) => permission.name === PermisionNames.DEACTIVATE,
    )?.id;

    return { createPermissionID, readPermissionID, editPermissionID, deactivatePermissionID };
};

export const getFeatureIds = (features: Feature[]) => {
    const linceseeFeatureID = features.find((feature) => feature.name === FeatureName.LICENSEE)?.id;
    const branchFeatureID = features.find((feature) => feature.name === FeatureName.BRANCH)?.id;
    const userFeatureID = features.find((feature) => feature.name === FeatureName.USER)?.id;

    return { linceseeFeatureID, branchFeatureID, userFeatureID };
};

const isBranchReadLincensee = (
    featureName: FeatureName,
    permissionName: PermisionNames,
    userLevel: number,
) =>
    featureName === FeatureName.BRANCH &&
    userLevel === UserLevel.Licensee &&
    permissionName === PermisionNames.READ;

export const generateStructuredFeatures = (features: Feature[], userLevel: number) =>
    features.reduce(
        (result, feature) => ({
            ...result,
            [feature.id]: feature.permissions.reduce(
                (res, permission) => ({
                    ...res,
                    [`${permission.id}`]: {
                        id: permission.id,
                        isAllowed: isBranchReadLincensee(
                            feature.name,
                            permission.name as PermisionNames,
                            userLevel,
                        )
                            ? true
                            : permission.isAllowed,
                        isDisabled: isBranchReadLincensee(
                            feature.name,
                            permission.name as PermisionNames,
                            userLevel,
                        )
                            ? true
                            : false,
                    },
                }),
                {},
            ),
        }),
        {},
    );

export const generateRequestFeatures = (features: RoleFormFeatures) =>
    Object.keys(features).map((featureId) => ({
        id: featureId,
        permissions: Object.keys(features[`${featureId}`]).map((permissionId) => ({
            id: permissionId,
            isAllowed: features[`${featureId}`][`${permissionId}`].isAllowed,
        })),
    }));
