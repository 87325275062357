import { createContext, FC, ReactNode, useEffect, useState } from "react";

import { getMe } from "@app/services/Endpoints/User.API";
import { MasterAPIService, OperationalAPIService } from "@app/services/Methods/api";
import { UserPermissions } from "@app/types/Common.types";
import { User, UserLevel } from "@app/types/User.type";

interface AppContextProviderProps {
    children: ReactNode;
}

interface AppContextOutput {
    isLoading: boolean;
    isInitiliazing: boolean;
    appUser?: User;
    showLoader: boolean;
    userPermissions?: UserPermissions;
    setShowLoader: (_val: boolean) => void;
    setAppUser: (_user: User) => void;
}

export const AppContext = createContext({} as AppContextOutput);

const AppContextProvider: FC<AppContextProviderProps> = ({ children }) => {
    const [appUser, setAppUser] = useState<User>();
    // For global loading
    const [isInitiliazing, setIsInitiliazing] = useState<boolean>(true);
    // For local loading
    const [count, setCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [userPermissions, setUserPermissions] = useState<UserPermissions>();

    const isLoading = !!count || loading;

    useEffect(() => {
        const token = localStorage.getItem("server-token");

        MasterAPIService.interceptors.request.use((config) => {
            if (config.method === "post" || config.method === "patch") {
                setShowLoader(false);
            }
            setCount((prevCount) => prevCount + 1);
            setLoading(true);
            return config;
        });

        MasterAPIService.interceptors.response.use(
            (response) => {
                setCount((prevCount) => prevCount - 1);
                setLoading(false);
                setShowLoader(true);
                return response;
            },
            (error) => {
                setCount((prevCount) => prevCount - 1);
                setLoading(false);
                setShowLoader(true);
                return Promise.reject(error.response);
            },
        );

        OperationalAPIService.interceptors.request.use((config) => {
            if (config.method === "post" || config.method === "patch") {
                setShowLoader(false);
            }
            setCount((prevCount) => prevCount + 1);
            setLoading(true);
            return config;
        });

        OperationalAPIService.interceptors.response.use(
            (response) => {
                setCount((prevCount) => prevCount - 1);
                setLoading(false);
                setShowLoader(true);
                return response;
            },
            (error) => {
                setCount((prevCount) => prevCount - 1);
                setLoading(false);
                setShowLoader(true);
                return Promise.reject(error.response);
            },
        );

        if (token) {
            getMe()
                .then((res) => {
                    const user = res?.data?.data;
                    setAppUser(user);
                })
                .finally(() => setIsInitiliazing(false));
        }
    }, []);

    useEffect(() => {
        if (appUser?.role) {
            const isAlbaikSystemRole = Boolean(
                appUser?.role?.userLevel === UserLevel.Licensor && appUser?.role?.isSystemRole,
            );
            let perms: Partial<UserPermissions> = {
                ["Create_User_Role"]: isAlbaikSystemRole,
                ["Edit_User_Role"]: isAlbaikSystemRole,
                ["Read_User_Role"]: isAlbaikSystemRole,
                ["Deactivate_User_Role"]: isAlbaikSystemRole,
                ["Create_System_Config"]: isAlbaikSystemRole,
                ["Edit_System_Config"]: isAlbaikSystemRole,
            };
            appUser?.role?.features?.forEach(
                (feature) =>
                    (perms = {
                        ...perms,
                        ...feature?.permissions?.reduce(
                            (allPermissions, permission) => ({
                                ...allPermissions,
                                [`${permission.displayName}_${feature.displayName}`]: Boolean(
                                    permission.isAllowed,
                                ),
                            }),
                            {} as { [key: string]: boolean },
                        ),
                    }),
            );
            setUserPermissions(perms as UserPermissions);
        }
    }, [appUser]);

    return (
        <AppContext.Provider
            value={{
                isInitiliazing,
                isLoading,
                appUser,
                setAppUser,
                showLoader,
                setShowLoader,
                userPermissions,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
