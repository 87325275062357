import { t } from "i18next";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Icons from "@app/assets/Icons";
import LoadingButton from "@app/components/Button";
import TextInput from "@app/components/inputs/Text";
import { postTanjara } from "@app/services/Endpoints/SelectMachines.API";
import { Errors } from "@app/types/Common.types";
import { tanjaraInfo, tanjaraItem } from "@app/types/SelectMachine.types";
import { AppContext } from "@app/utils/contexts/App.context";
import { FormContext } from "@app/utils/contexts/Form.context";

import "./index.scss";

interface TanjaraSectionProps {
    item: tanjaraItem;
    id: string;
    handleChange: (_e: ChangeEvent<HTMLInputElement>) => void;
    updateTanjaraDetails: (_id: string, _obj: tanjaraItem) => void;
}
const TanjaraSection: FC<TanjaraSectionProps> = ({ item, id, updateTanjaraDetails }) => {
    const [tanjaraFieldsInfo, setTanjaraFieldsInfo] = useState<tanjaraInfo>({});
    const [errors, setErrors] = useState<Partial<Errors>>();
    const { setIsDirty } = useContext(FormContext);
    const [disabledTanjaraSave, setDisabledTanjaraSave] = useState(false);
    const { userPermissions } = useContext(AppContext);

    useEffect(() => {
        setTanjaraFieldsInfo({
            [id]: { from: item?.from, to: item?.to, selectedTrays: item?.selectedTrays },
        });
    }, [item]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
        setErrors({});
        setIsDirty(true);
        setDisabledTanjaraSave(true);
        const { name, value } = e.target;
        const tempObj = JSON.parse(JSON.stringify(tanjaraFieldsInfo));
        if (parseInt(value) > 0) {
            tempObj[id][name] = parseInt(value);
        } else {
            tempObj[id][name] = "";
        }
        setTanjaraFieldsInfo({ ...tempObj });
    };

    const validateTanjaraDetails = (id: string) => {
        const { from, to, selectedTrays } = tanjaraFieldsInfo[id];
        const currentErrors: Partial<Errors> = {};

        if (!from) {
            currentErrors.tanjaraMin = `${t("ERRORS.REQUIRED", {
                name: "Tanjara Number",
            })}`;
        }
        if (!to) {
            currentErrors.tanjaraMax = `${t("ERRORS.REQUIRED", {
                name: "Tanjara Number",
            })}`;
        } else if (to <= from) {
            currentErrors.tanjaraMax = `${t("ERRORS.LESS_THAN_ONE", {
                name: "Value",
                number: from,
            })}`;
        }

        if (!selectedTrays.length) {
            currentErrors.selectedTrays = `${t("ERRORS.LESS_THAN_ONE")}`;
        }
        setErrors({ ...errors, ...currentErrors });
        return !Object.keys(currentErrors).length;
    };

    const onGo = (id: string) => {
        const { from, to } = tanjaraFieldsInfo[id];
        setDisabledTanjaraSave(false);
        if (from && to && to > parseInt(String(from))) {
            const tempTrays = [];
            let startIndex = from;
            while (startIndex <= to) {
                tempTrays.push(startIndex);
                startIndex = startIndex + 1;
            }
            tanjaraFieldsInfo[id]["selectedTrays"] = tempTrays;
            updateTanjaraDetails(id, tanjaraFieldsInfo[id]);
        } else {
            validateTanjaraDetails(id);
        }
    };

    const tanjaraNumberHandler = (element: number, item: tanjaraItem, id: string) => {
        const { selectedTrays } = item;
        if (selectedTrays.includes(element) && selectedTrays.length > 1) {
            const index = selectedTrays.indexOf(element);
            selectedTrays.splice(index, 1);
        } else if (!selectedTrays.includes(element)) {
            selectedTrays.push(element);
        }
        updateTanjaraDetails(id, item);
    };

    const saveTanjara = () => {
        if (validateTanjaraDetails(id)) {
            postTanjara({ ...item, stationId: id }).then(() => {
                setIsDirty(false);
            });
        }
    };

    const renderTanjaraStationsSection = (id: string) => {
        const { from, to, selectedTrays } = item || {};
        const result = [];
        const rowSize = 10;
        const rowsArray = [];
        let startTray = Number(from);
        while (startTray <= Number(to)) {
            result.push(startTray);
            startTray = Number(startTray) + 1;
        }

        while (result.length > 0) {
            rowsArray.push(result.splice(0, rowSize));
        }

        return rowsArray.map((_arr, index) => (
            <Row className='mt-4' key={index}>
                {from && to
                    ? _arr.map((element) => (
                          <Col
                              lg={1}
                              key={element}
                              className={`tanjara__selection ${
                                  selectedTrays.includes(element) ? "tanjara__selectedSlot" : ""
                              }`}
                              onClick={() => tanjaraNumberHandler(element, item, id)}
                          >
                              {element}
                          </Col>
                      ))
                    : ""}
            </Row>
        ));
    };

    return (
        <Container className='px-0 mt-4'>
            <Row className='table mb-0' xs={12}>
                <Col className='table__content'>
                    <span className='table__first-label'>{t("SELECTMACHINES.TANJARA_NUMBER")}</span>
                </Col>
            </Row>
            <Row className='mb-4 tanjara__container'>
                <Col xs={4}>
                    <TextInput
                        type='number'
                        label={`${t("SELECTMACHINES.TANJARA_LABEL")}`}
                        placeholder={`${t("SELECTMACHINES.PLACEHOLDER")}`}
                        value={tanjaraFieldsInfo[id]?.from}
                        onChange={(event) => handleChange(event, id)}
                        name='from'
                        error={errors?.tanjaraMin}
                    />
                </Col>
                <Col xs={1} className='d-flex justify-content-center align-items-center mt-4'>
                    To
                </Col>
                <Col xs={4} className='tanjara__maxNumber'>
                    <TextInput
                        type='number'
                        label={`${t("SELECTMACHINES.TANJARA_LABEL")}`}
                        placeholder={`${t("SELECTMACHINES.PLACEHOLDER")}`}
                        value={tanjaraFieldsInfo[id]?.to}
                        onChange={(event) => handleChange(event, id)}
                        name='to'
                        error={errors?.tanjaraMax}
                    />
                </Col>
                <Col xs={3} className='d-flex align-items-end justify-content-end'>
                    <LoadingButton
                        icon={<img className='tanjara__go' src={Icons.ProceedIcon} />}
                        label='Go'
                        variant='add'
                        onClick={() => onGo(id)}
                    />
                </Col>

                {renderTanjaraStationsSection(id)}
                <Col xs={12} className='d-flex align-items-end justify-content-end py-4'>
                    {userPermissions && userPermissions["Create_Kitchen Setup"] && (
                        <LoadingButton
                            label='Save'
                            variant='upload'
                            onClick={() => saveTanjara()}
                            disabled={disabledTanjaraSave}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default TanjaraSection;
