import { MasterAPIService } from "@app/services/Methods/api";

export const getBranchLicenseeData = (branchId: string) =>
    MasterAPIService.get(`/BranchLicense/GetBranchLicense?branchId=${branchId}`);

export const createBranchLicense = (branchId: string, expiryDate: string, expiryTime: string) =>
    MasterAPIService.post("/BranchLicense", { branchId, expiryDate, expiryTime });

export const changeBranchLicenseExpiryDate = (
    branchId: string,
    expiryDate: string,
    expiryTime: string,
) =>
    MasterAPIService.patch("/BranchLicense/ChangeExpiryDate", { branchId, expiryDate, expiryTime });

export const revokeBranchLicense = (branchId: string) =>
    MasterAPIService.patch(`/BranchLicense/revokeBranchLicence?branchId=${branchId}`);
