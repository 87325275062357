import { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import UnauthorizedPage from "@app/pages/auth/Unathorized/index.page";
import { AppContext } from "@app/utils/contexts/App.context";
import FormContextProvider from "@app/utils/contexts/Form.context";
import LicenseeContextProvider from "@app/utils/contexts/Licensee.context";

import Layout from "./Layout";
import SidebarData from "./Layout/sidebarData";

const LicenseeRoutes = () => {
    const { userPermissions } = useContext(AppContext);
    return (
        <Routes>
            {SidebarData(undefined, userPermissions).map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={
                        <FormContextProvider>
                            <LicenseeContextProvider>
                                <Layout
                                    titlePrimary={route.primaryText}
                                    titleSecondary={route.secondaryText}
                                >
                                    {route.authorized ? route.element : <UnauthorizedPage />}
                                </Layout>
                            </LicenseeContextProvider>
                        </FormContextProvider>
                    }
                />
            ))}
        </Routes>
    );
};

export default LicenseeRoutes;
