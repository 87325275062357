import { User } from "./User.type";

export enum BranchType {
    // eslint-disable-next-line
    None = 0,
    // eslint-disable-next-line
    Full_Menu = 1,
    // eslint-disable-next-line
    Express = 2,
    // eslint-disable-next-line
    Mini = 4,
    // eslint-disable-next-line
    Limited = 8,
}

export const branchTypeEnum = {
    0: "",
    1: "Full Menu",
    2: "Express",
    4: "Mini",
    8: "Limited",
};

export interface Branch {
    id: string;
    name: string;
    code: string;
    branchType: BranchType;
    countryID: string;
    regionID: string;
    cityID: string;
    address: string;
    pinCode: string;
    licenseeID: string;
    is24HourOpen: boolean;
    startTime: number | string;
    endTime: number | string;
    isActive: boolean;
    users: Partial<User>[];
}

export interface BranchLicense {
    active: number;
    branchId: string;
    expiryDate: string | null;
    expiryTime: string | null;
    id: string;
    licenseKey: number;
    updatedDate: string | null;
}
