import { t } from "i18next";
import { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SelectProductForm from "@app/components/SelectProductsForm";
import { RoutePaths } from "@app/routes/index";
import {
    addSelectedProducts,
    getSelectedProducts,
} from "@app/services/Endpoints/SelectProduct.API";
import { getStations } from "@app/services/Endpoints/Station";
import { ModalType, SelectProductErrors } from "@app/types/Common.types";
import { Station, StationList } from "@app/types/Station.types";
import { SelectedProducts, SelectProduct, SelectProductData } from "@app/types/selectProduct.types";
import { FormContext } from "@app/utils/contexts/Form.context";

const SelectProductsContainer: FC = () => {
    const { triggerShowModal, setActions, isDirty, setIsDirty } = useContext(FormContext);
    const [assemblyStations, setAssemblyStations] = useState<Station[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<SelectProductData[]>([]);
    const [assemblyCapacity, setAssemblyCapacity] = useState<Record<string, string>>({});
    const [checked, setChecked] = useState<string[]>([]);
    const [errors, setErrors] = useState<SelectProductErrors>();
    const location = useLocation();
    const navigate = useNavigate();

    const fetchStations = useCallback(() => {
        getStations().then((res) => {
            const assemblyStations =
                res?.data?.data?.find((station: StationList) => station.stationType === "Assembly")
                    .stations ?? [];
            setAssemblyStations([...assemblyStations]);
        });
    }, []);

    const fetchSelectedProduct = useCallback(() => {
        getSelectedProducts()
            .then((res) => {
                setSelectedProducts([...res.data.data]);

                const selectedProductIds = res.data.data
                    .filter((product) => product.isSelected)
                    .map((product) => product.productId);

                setChecked(selectedProductIds);

                const productIds = res.data.data.reduce(
                    (acc, data) => ({ ...acc, [data.productId]: data.assemblyCapacity }),
                    {},
                );
                setAssemblyCapacity(productIds);
            })
            .catch();
    }, []);

    useEffect(() => {
        fetchStations();
        fetchSelectedProduct();
    }, [fetchStations, fetchSelectedProduct]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, productId: string) => {
        setIsDirty(true);
        const { name, value } = e.target;
        if (errors?.assemblyCapacity?.includes(name)) {
            setErrors({
                ...errors,
                assemblyCapacity: errors.assemblyCapacity.filter((id) => id !== name),
            });
        }

        setAssemblyCapacity((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        const updatedProducts = selectedProducts.map((product) =>
            product.productId === productId
                ? { ...product, assemblyCapacity: Number(value) }
                : product,
        );
        setSelectedProducts(updatedProducts);
    };

    const handleAssemblyStationChange = (
        event: ChangeEvent<HTMLSelectElement>,
        productId: string,
    ) => {
        setIsDirty(true);
        const { value } = event.target;
        const updatedProducts = selectedProducts.map((product) =>
            product.productId === productId ? { ...product, assemblyStationId: value } : product,
        );
        setSelectedProducts(updatedProducts);
    };

    const handleCheckAllChange = () => {
        setIsDirty(true);
        setErrors(undefined);
        if (!(checked.length === selectedProducts.length)) {
            const allProducts = selectedProducts.map((c) => c.productId);
            setChecked(allProducts);
        } else {
            setChecked([]);
        }
    };

    const handleProductCheck = (c: SelectProductData) => {
        setIsDirty(true);

        if (errors?.atLeastOneSelected) {
            setErrors({
                ...errors,
                atLeastOneSelected: "",
            });
        }
        if (errors?.assemblyCapacity?.includes(c.productId)) {
            setErrors({
                ...errors,
                assemblyCapacity: errors.assemblyCapacity.filter((id) => id !== c.productId),
            });
        }

        if (checked.includes(c.productId)) {
            setChecked(checked.filter((item) => item !== c.productId));
        } else {
            setChecked([...checked, c.productId]);
        }
    };

    const handleCancel = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                    setErrors(undefined);
                    fetchSelectedProduct();
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        }
    };

    const handleBack = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                    setErrors(undefined);
                    navigate(`/${RoutePaths.SelectMachines}`);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        } else navigate(`/${RoutePaths.SelectMachines}`);
    };

    const handleAddProducts = (products: SelectedProducts) => {
        addSelectedProducts(products)
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                setIsDirty(false);
                if (location.state?.NavigateViaMachine) {
                    navigate(`/${RoutePaths.SelectSaleChannels}`, {
                        state: { NavigateViaSelectProduct: "NavigateViaSelectProduct" },
                    });
                }
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleSubmit = () => {
        const selectedProductsArray = checked.map((productId) => {
            const selectedProduct = selectedProducts.find(
                (product) => product.productId === productId,
            );
            if (selectedProduct) {
                return {
                    productId: selectedProduct.productId,
                    assemblyStationId: selectedProduct.assemblyStationId,
                    assemblyCapacity: selectedProduct.assemblyCapacity,
                };
            }
            return null;
        });

        const filteredSelectedProductsArray = selectedProductsArray.filter(
            (product) => product !== null,
        );
        const dataToSend: SelectedProducts = {
            products: filteredSelectedProductsArray as SelectProduct[],
        };

        const isAtLeastOneSelected = checked.length > 0;
        const isCapacityValid = checked.every((channelId) => !(assemblyCapacity[channelId] === ""));
        const isDataValid = isAtLeastOneSelected && isCapacityValid;

        if (isDataValid) {
            setErrors(undefined);
            triggerShowModal(true, ModalType.SUBMIT);
            setActions({
                done: () => handleAddProducts(dataToSend),
                cancel: () => triggerShowModal(false, ModalType.SUBMIT),
            });
        } else {
            const currentErrors: SelectProductErrors = {
                assemblyCapacity: [],
                atLeastOneSelected: "",
            };
            if (!isAtLeastOneSelected)
                currentErrors.atLeastOneSelected = `${t("SELECTPRODUCTS.ERRORATLEASTONESELECTED")}`;

            if (!isCapacityValid) {
                checked.forEach((channelId) => {
                    if (assemblyCapacity[channelId] === "") {
                        currentErrors.assemblyCapacity.push(channelId);
                    }
                });
            }

            setErrors({ ...errors, ...currentErrors });
        }
    };

    return (
        <SelectProductForm
            headerName={[
                `${t("SELECTPRODUCTS.PRODUCT")}`,
                `${t("SELECTPRODUCTS.ASSEMBLYSTATION")}`,
                `${t("SELECTPRODUCTS.ASSEMBLYCAPACITY")}`,
            ]}
            checked={checked}
            onChangeAssemblyStation={handleAssemblyStationChange}
            selectedProducts={selectedProducts}
            onClickHeaderCheckBox={handleCheckAllChange}
            onClickRowCheckBox={handleProductCheck}
            OnChangeInputFields={handleInputChange}
            OnClickCancel={handleCancel}
            OnClickSubmit={handleSubmit}
            OnClickBack={handleBack}
            submitDisabled={!isDirty}
            error={errors}
            assemblyStations={assemblyStations?.map(({ stationId, displayName }) => ({
                value: stationId,
                label: displayName,
            }))}
        />
    );
};
export default SelectProductsContainer;
