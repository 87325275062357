import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import FormWrapper from "@app/components/FormWrapper";
import BranchSettingsForm from "@app/components/forms/BranchSettings.form";
import { RoutePaths } from "@app/routes/index";
import { getBranchSettings, saveBranchSettings } from "@app/services/Endpoints/BranchSettings";
import { BranchSettings, BranchSettingsFormData } from "@app/types/BranchSettings";
import { ModalType } from "@app/types/Common.types";
import { FormContext } from "@app/utils/contexts/Form.context";

import "./index.scss";

const initialFormData: BranchSettingsFormData = {
    BULK_LICENSEE: false,
};

const BranchSettingsContainer: FC = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { triggerShowModal, setActions, isDirty, setIsDirty } = useContext(FormContext);

    const [savedData, setSavedData] = useState<BranchSettings[]>();
    const [branchSettings, setBranchSettings] = useState<BranchSettingsFormData>(initialFormData);

    const fetchSystemConfiguration = useCallback(() => {
        getBranchSettings()
            .then((res) => {
                if (res) {
                    const BULK_LICENSEE =
                        res.data.data
                            .find((d) => d.name === "BULK_LICENSEE")
                            ?.value?.toLowerCase() === "true";
                    setBranchSettings({ BULK_LICENSEE });
                    setSavedData([...res.data.data]);
                } else {
                    setBranchSettings({ ...initialFormData });
                }
            })
            .catch(() => setBranchSettings({ ...initialFormData }));
    }, []);

    useEffect(() => {
        fetchSystemConfiguration();
    }, [fetchSystemConfiguration]);

    const handleChange = (name: string, value: string | boolean) => {
        setIsDirty(true);
        const data: BranchSettingsFormData = { ...branchSettings };
        setBranchSettings({ ...data, [name]: value });
    };

    const submitBranchSettings = () => {
        const data = [
            {
                name: "BULK_LICENSEE",
                value: String(branchSettings.BULK_LICENSEE),
                id: savedData?.find((sD) => sD.name === "BULK_LICENSEE")?.id,
            },
        ];
        saveBranchSettings(data)
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                setIsDirty(false);
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleSubmit = () => {
        triggerShowModal(true, ModalType.SUBMIT);
        setActions({
            done: () => submitBranchSettings(),
            cancel: () => triggerShowModal(false, ModalType.SUBMIT),
        });
    };

    const handleCancel = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        }
    };

    const handleBack = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                    navigate(`/${RoutePaths.SelectSaleChannels}`, {
                        state: {
                            NavigateBackFromBranchSettings: "NavigateBackFromBranchSettings",
                        },
                    });
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        } else
            navigate(`/${RoutePaths.SelectSaleChannels}`, {
                state: {
                    NavigateBackFromBranchSettings: "NavigateBackFromBranchSettings",
                },
            });
    };

    return (
        <FormWrapper
            heading='Choose your preferred settings for branch'
            onSubmitClick={handleSubmit}
            submitDisabled={!isDirty}
            onCancelClick={handleCancel}
            cancelDisabled={!isDirty}
            onClickBack={state?.NavigateViaSelectSalesChannels ? handleBack : undefined}
        >
            <BranchSettingsForm data={branchSettings} onChange={handleChange} />
        </FormWrapper>
    );
};

export default BranchSettingsContainer;
