import { FC } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import Checkbox from "@app/components/inputs/Checkbox";
import { BranchSettingsFormData } from "@app/types/BranchSettings";

interface BranchSettingsFormProps {
    data: BranchSettingsFormData;
    onChange: (_name: string, _value: string | boolean) => void;
}

const BranchSettingsForm: FC<BranchSettingsFormProps> = ({ data, onChange }) => (
    <Container fluid className='py-4 px-5'>
        <Row>
            <Col>
                <Form.Group>
                    <Form.Label className='text-input__label mb-4'>Select Features</Form.Label>
                    <div className='d-flex justify-content-between'>
                        <Checkbox
                            checked={data.BULK_LICENSEE}
                            label='Bulk'
                            onClick={() => onChange("BULK_LICENSEE", !data.BULK_LICENSEE)}
                            testId='bulk-checkbox'
                        />
                    </div>
                </Form.Group>
            </Col>
        </Row>
    </Container>
);

export default BranchSettingsForm;
