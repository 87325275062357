import { t } from "i18next";
import { ChangeEvent, FC, useContext } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import ImageUpload from "@app/components/ImageUpload";
import TextInput from "@app/components/inputs/Text";
import Toggle from "@app/components/inputs/Toggle";
import { ProductErrors } from "@app/types/Common.types";
import { CustomizationFormData } from "@app/types/Customization.types";
import { AppContext } from "@app/utils/contexts/App.context";
import { FormContext } from "@app/utils/contexts/Form.context";
import "./index.scss";

interface AddCustomizationProps {
    data: CustomizationFormData;
    errors?: Partial<ProductErrors>;
    isEdit?: boolean;
    onChange: (_name: string, _value: string | boolean) => void;
}

const AddCustomization: FC<AddCustomizationProps> = ({ isEdit, errors, data, onChange }) => {
    const { setIsDirty } = useContext(FormContext);
    const { userPermissions } = useContext(AppContext);

    const handleImageUpload = (id: string) => {
        setIsDirty(true);
        onChange("imageId", id);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setIsDirty(true);
        const { name, value } = e.target;
        console.log(name, "name");
        onChange(name, value);
    };

    return (
        <Container fluid className='py-4 px-5'>
            <Row className='mb-3'>
                <br />
                <Col xs={6}>
                    <TextInput
                        label={`${t("ADD.CUSTOMISATION.NAME")}`}
                        placeholder={"Enter Customization Name"}
                        value={data?.name}
                        onChange={handleChange}
                        name='name'
                        error={errors?.name}
                        disabled={isEdit}
                    />
                </Col>
                <Col sm={6}>
                    <TextInput
                        label={`${t("ADD.CUSTOMISATION.ID")}`}
                        placeholder={"Enter Customization ID"}
                        value={data?.code}
                        onChange={handleChange}
                        name='code'
                        error={errors?.code}
                        disabled={isEdit}
                    />
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col sm={6}>
                    <TextInput
                        label={`${t("ADD.CUSTOMISATION.DISPLAYNAME")}`}
                        placeholder={"Enter Display Name"}
                        value={data?.displayName}
                        onChange={handleChange}
                        name='displayName'
                        error={errors?.displayName}
                    />
                </Col>
                <Col sm={6}>
                    <div>
                        <h6 className='customization-form__label'>
                            {t("ADD.CUSTOMISATION.UPLOADPHOTO")}
                        </h6>
                        <ImageUpload
                            onUpload={handleImageUpload}
                            error={errors?.imageId}
                            fetchImage={data?.imageId}
                            fetchImageName={data?.imageName}
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                {isEdit && userPermissions?.Deactivate_Customization && (
                    <Row className='mt-4'>
                        <Col>
                            <Form.Group>
                                <Form.Label className='text-input__label'>
                                    {t("MACHINE.CREATE.STATUS.LABEL")}
                                </Form.Label>
                                <Toggle
                                    isActive={data?.status === true}
                                    onToggle={() => {
                                        setIsDirty(true);
                                        onChange("status", data.status === true ? false : true);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                )}
            </Row>
        </Container>
    );
};

export default AddCustomization;
