import { FC, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Icons from "@app/assets/Icons";
import LoadingButton from "@app/components/Button";
import TableHeader from "@app/components/TableHeader";
import { HeadersMapInterface } from "@app/types/Common.types";
import { machinesInterface } from "@app/types/SelectMachine.types";
import { AppContext } from "@app/utils/contexts/App.context";

interface MachineTablerProps {
    item: Array<machinesInterface>;
    onClick: () => void;
    onDelete: (_id: string) => void;
}
const MachineTable: FC<MachineTablerProps> = ({ item, onClick, onDelete }) => {
    const HeadersMap: HeadersMapInterface = {
        displayName: {
            label: "DISPLAY NAME",
            layout: { sm: 2 },
            disableSort: true,
        },
        make: { label: "MACHINE MAKE", layout: { sm: 3 }, disableSort: true },
        name: { label: "MACHINE NAME", layout: { sm: 3 }, disableSort: true },
        number: {
            label: "SERIAL NUMBER",
            layout: { sm: 3 },
            disableSort: true,
        },
    };
    const { userPermissions } = useContext(AppContext);

    return (
        <Container className='px-0'>
            <TableHeader
                headersMap={HeadersMap}
                sortByHandler={() => console.log("log")}
                sortByObj={{ sortBy: "", sortOrder: 0 }}
                enableIconColumn
            />
            {item ? (
                item.map((machine, index) => (
                    <Row
                        className={`px-0 selectMachine__rowWrap ${
                            index % 2 == 0 && " selectMachine__rowGrey"
                        }`}
                        key={index}
                    >
                        <Col sm='2' className='px-4'>
                            {machine.displayName}
                        </Col>
                        <Col sm='3'> {machine.machineMake}</Col>
                        <Col sm='3'> {machine.machineName}</Col>
                        <Col sm='3'> {machine.serialNumber}</Col>
                        <Col sm='1' className='selectMachine__enablepointer'>
                            <img src={Icons.DeleteIcon} onClick={() => onDelete(machine.id)} />
                        </Col>
                    </Row>
                ))
            ) : (
                <div className='selectMachine__noDataFound'>No Data Found</div>
            )}
            <div className='d-flex align-items-end justify-content-end mt-4 selectMachine__addMachineCTA'>
                {userPermissions && userPermissions["Create_Kitchen Setup"] && (
                    <LoadingButton
                        icon={<img src={Icons.PlusIcon} className='viewHeader__plusIcon' />}
                        label='Add Machine'
                        variant='add'
                        onClick={onClick}
                        btnClass='viewHeader__add-button'
                    />
                )}
            </div>
        </Container>
    );
};

export default MachineTable;
