import { MasterAPIService } from "@app/services/Methods/api";
import { CreateCustomizationResponse, CustomizationUpdateResponse } from "@app/types/API.types";
import { CustomizationFormData } from "@app/types/Customization.types";

export const getCustomisationsData = (
    sortBy?: string,
    sortOrder?: number,
    pageNumber?: number,
    pageSize?: number,
    name?: string,
    filters?: string,
) =>
    MasterAPIService.get(
        `/Customization?${sortBy ? `&sortBy=${sortBy}` : ""}${
            sortOrder ? `&sortOrder=${sortOrder}` : ""
        }${pageNumber ? `&pageNumber=${pageNumber}` : ""}${
            pageSize ? `&pageSize=${pageSize}` : ""
        }${name ? `&name=${name}` : ""}${filters ? filters : ""}`,
    );

export const addCustomization = (customization: CustomizationFormData) =>
    MasterAPIService.post<CreateCustomizationResponse>("/customization", customization);

export const editCustomization = (customization: CustomizationFormData) =>
    MasterAPIService.patch<CustomizationUpdateResponse>("/customization", customization);
