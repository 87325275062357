import { FC } from "react";

import Icons from "@app/assets/Icons";

import "./index.scss";

interface CheckboxProps {
    label: string;
    checked: boolean;
    onClick: () => void;
    className?: string;
    testId?: string;
    disabled?: boolean;
}

const Checkbox: FC<CheckboxProps> = ({ label, onClick, checked, className, testId, disabled }) => {
    const handleClick = () => {
        if (!disabled) onClick();
    };
    return (
        <div
            className={
                disabled ? `checkbox--disabled ${className ?? ""}` : `checkbox ${className ?? ""}`
            }
            onClick={handleClick}
            data-testid={testId ?? "checkbox"}
        >
            <button className='checkbox__icon'>
                {checked && (
                    <span className='checkbox__checked'>
                        <img className='checkbox__tick' src={Icons.CheckIcon} />
                    </span>
                )}
            </button>
            <p className='checkbox__label'>{label}</p>
        </div>
    );
};

export default Checkbox;
