import { FC, useContext, useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";

import Checkbox from "@app/components/inputs/Checkbox";
import { FilterContext } from "@app/utils/contexts/Filters.context";

const CustomizationFilters: FC = () => {
    const { handleStatusesFilter, statusesFilter, setStatusesFilter } = useContext(FilterContext);

    const [key, setKey] = useState<string | undefined | null>();

    return (
        <Accordion
            activeKey={key}
            onSelect={(k) => setKey(String(k))}
            flush
            className='filters__item'
        >
            <Accordion.Item eventKey='0' className=''>
                <Accordion.Header className='filters__item-header'>
                    <span>Status</span>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            position: "absolute",
                            right: "20%",
                        }}
                        variant='text'
                        onClick={(e) => {
                            e.stopPropagation();
                            setStatusesFilter(undefined);
                        }}
                    >
                        Clear
                    </Button>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        <Col xs={12} className='filters__checkbox'>
                            <Checkbox
                                label='Active'
                                checked={!!statusesFilter?.includes(true)}
                                onClick={() => handleStatusesFilter(true)}
                            />
                        </Col>
                        <Col xs={12} className='filters__checkbox'>
                            <Checkbox
                                label='Inactive'
                                checked={!!statusesFilter?.includes(false)}
                                onClick={() => handleStatusesFilter?.(false)}
                            />
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default CustomizationFilters;
