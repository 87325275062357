import { t } from "i18next";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Icons from "@app/assets/Icons";
import Loader from "@app/components/Loader";
import Pagination from "@app/components/Pagination";
import TableHeader from "@app/components/TableHeader";
import ViewUtilityHeader from "@app/components/ViewUtilityHeader";
import { RouteNames } from "@app/routes/index";
import { getMachines } from "@app/services/Endpoints/Machine.API";
import { HeadersMapInterface } from "@app/types/Common.types";
import { Machines } from "@app/types/Service.type";
import { AppContext } from "@app/utils/contexts/App.context";
import { FilterContext } from "@app/utils/contexts/Filters.context";

import "./index.scss";

let totalPages = 0;
const sortByEnum = {
    DESC: 1,
    ASC: 0,
};

const ViewMachinePage: FC = () => {
    const { userPermissions } = useContext(AppContext);
    const {
        statusesFilter,
        machineMakesFilter,
        machineTypesFilter,
        basketsFilter,
        stationsFilter,
    } = useContext(FilterContext);

    const HeadersMap: HeadersMapInterface = {
        serialNo: {
            label: t("VIEW.MACHINE.SERIALNO"),
            layout: { lg: 1, sm: 1 },
            disableSort: true,
        },
        machineName: { label: t("VIEW.MACHINE.MACHINENAME"), layout: { lg: 2, sm: 1 } },
        machineMake: { label: t("VIEW.MACHINE.MAKE"), layout: { lg: 2, sm: 2 } },
        applicableStations: {
            label: t("VIEW.MACHINE.STATION"),
            layout: { lg: 2, sm: 2 },
            disableSort: true,
        },
        machineType: { label: t("VIEW.MACHINE.TYPE"), layout: { lg: 2, sm: 2 } },
        noOfBaskets: { label: t("VIEW.MACHINE.BASKETS"), layout: { lg: 1, sm: 2 } },
        status: { label: t("VIEW.MACHINE.STATUS"), layout: { lg: 1, sm: 1 } },
    };
    const [machines, setMachines] = useState<Machines[]>([]);
    const [sortByObj, setSortByObj] = useState({
        sortBy: "machineName",
        sortOrder: sortByEnum.DESC,
    });
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loader, setLoader] = useState<boolean>(false);
    const [searchVal, setSearchVal] = useState<string>("");
    const navigate = useNavigate();
    const [isScrollComing, setIsScrollComing] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useRef<any>();

    useEffect(() => {
        setPageNumber(1);
    }, [statusesFilter, machineMakesFilter, machineTypesFilter, basketsFilter, stationsFilter]);

    useEffect(() => {
        const filters = [
            statusesFilter?.map((status) => `&Status=${Number(!status) + 1}`).join(""),
            machineMakesFilter?.map((makeId) => `&MachineMake=${makeId}`).join(""),
            machineTypesFilter?.map((typeId) => `&MachineType=${typeId}`).join(""),
            basketsFilter?.map((basket) => `&NoOfBaskets=${Number(basket)}`).join(""),
            stationsFilter?.map((stationId) => `&StationIds=${stationId}`).join(""),
        ].join("&");
        getMachinesHandler(
            sortByObj.sortBy,
            sortByObj.sortOrder,
            pageNumber,
            pageSize,
            searchVal,
            filters,
        );
    }, [
        pageNumber,
        pageSize,
        sortByObj,
        searchVal,
        statusesFilter,
        machineMakesFilter,
        machineTypesFilter,
        basketsFilter,
        stationsFilter,
    ]);

    useEffect(() => {
        if (ref.current?.offsetWidth != ref.current?.clientWidth) {
            setIsScrollComing(true);
        } else {
            setIsScrollComing(false);
        }
    });

    const getMachinesHandler = (
        sortBy: string,
        sortOrder: number,
        pageNumber: number,
        pageSize: number,
        name?: string,
        filters?: string,
    ) => {
        setLoader(true);
        getMachines(sortBy, sortOrder, pageNumber, pageSize, name, filters)
            .then((res) => {
                totalPages = res.data.totalPages;
                const result = res?.data?.data;
                if (result) {
                    setMachines([...result]);
                } else {
                    setMachines([]);
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setLoader(false));
    };

    const sortByHandler = (headerKey: string) => {
        setSortByObj({ sortBy: headerKey, sortOrder: Number(!sortByObj.sortOrder) });
    };

    const onChangeRecordsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setPageSize(Number(value));
        setPageNumber(1);
    };

    const onPageChange = (pageNumber: number) => {
        setPageNumber(Number(pageNumber));
    };
    const onEdit = (machineData: Machines) => {
        navigate(RouteNames.Edit, {
            state: { machineEditData: machineData },
        });
    };
    const redirectToAddMachine = () => {
        navigate(RouteNames.Add);
    };
    const handleSearch = (val: string) => {
        setSearchVal(val);
    };

    return (
        <Container fluid className='machine'>
            <ViewUtilityHeader
                onChange={onChangeRecordsPerPage}
                placeholder='Search for Machine name'
                btnLabel={t("VIEW.MACHINE.ADDMACHINE")}
                onClick={userPermissions?.Create_Machine ? redirectToAddMachine : undefined}
                onSearch={handleSearch}
            />

            <TableHeader
                headersMap={HeadersMap}
                sortByHandler={sortByHandler}
                sortByObj={sortByObj}
                enableIconColumn
            />

            <div
                className={`machine--page ${isScrollComing ? "machine--isScroll" : ""}`}
                id='listing-Outercontainer'
                ref={ref}
            >
                {loader ? (
                    <Loader />
                ) : machines.length ? (
                    machines.map((item, index) => (
                        <Row
                            key={index}
                            className={`machine__rowWrap ${index % 2 == 0 && "machine__rowGrey"}`}
                        >
                            <Col className='machine--pl20' sm='1' lg='1'>
                                {pageNumber == 1 && index < 9 ? "0" : ""}
                                {(pageNumber - 1) * 10 + (index + 1)}
                            </Col>
                            <Col sm='1' lg='2'>
                                {item.machineName}
                            </Col>

                            <Col sm='2' lg='2'>
                                {item.machineMake}
                            </Col>
                            <Col sm='2' lg='2'>
                                {item.applicableStations?.length > 1 ? (
                                    <>
                                        <span>{item.applicableStations[0].name}</span>&nbsp;
                                        <img
                                            className='machine--dots machine__enablepointer'
                                            src={Icons.ThreeDotsIcon}
                                        />
                                    </>
                                ) : (
                                    item.applicableStations[0].name
                                )}
                            </Col>
                            <Col sm='2' lg='2'>
                                {item.machineType}
                            </Col>
                            <Col sm='2' lg='1'>
                                {Number(item.noOfBaskets) < 10
                                    ? "0" + item.noOfBaskets
                                    : item.noOfBaskets}
                            </Col>
                            <Col sm='1' lg='1'>
                                {item.statusForDisplay}
                            </Col>
                            <Col sm='1' lg='1'>
                                {userPermissions?.Edit_Machine && (
                                    <img
                                        className='machine__edit machine__enablepointer'
                                        src={Icons.EditIcon}
                                        onClick={() => onEdit(item)}
                                        height='20px'
                                    />
                                )}
                            </Col>
                        </Row>
                    ))
                ) : (
                    <div className='customisation--noData'>{t("APIMSG.NODATA")}</div>
                )}
            </div>
            <Pagination
                sortByObj={sortByObj}
                onPageChange={onPageChange}
                totalPages={totalPages}
                pageSize={pageSize}
                pageNumber={pageNumber}
            />
        </Container>
    );
};

export default ViewMachinePage;
