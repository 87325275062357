import { t } from "i18next";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FormWrapper from "@app/components/FormWrapper";
import ItemForm from "@app/components/forms/Item.form";
import { RoutePaths } from "@app/routes/index";
import {
    createItem,
    getCookingStations,
    getItemCategories,
    getItemUnits,
    getMachines,
    updateItem,
} from "@app/services/Endpoints/Item.API";
import { getProducts } from "@app/services/Endpoints/Product.API";
import { DisplayData, Errors, ModalType, ScreenType } from "@app/types/Common.types";
import {
    Item,
    ItemFormData,
    ItemMachineDetails,
    ItemMachineList,
    ItemMachineRange,
    MachineFormData,
} from "@app/types/Item";
import { FormContext } from "@app/utils/contexts/Form.context";
import { getSecondsInTime, getTimeInSeconds } from "@app/utils/timeHelpers";

const initialFormData: ItemFormData = {
    code: "",
    cookingStationId: "",
    cookingStationName: "",
    crispyTime: "00:00:00",
    displayName: "",
    drainageTime: "00:00:00",
    holdingTime: "00:00:00",
    id: "",
    imageId: "",
    itemCategory: "",
    name: "",
    preparationTime: "00:00:00",
    status: false,
    units: "",
    itemMachines: [],
    machineConfigId: "",
    machineCapacity: 0,
    recoveryTime: "00:00:00",
    resetTime: "00:00:00",
    minQuantity: "",
    maxQuantity: "",
    cookingTime: "00:00:00",
    "minQuantity:1": "",
    "maxQuantity:1": "",
    "cookingTime:1": "00:00:00",
    cyclicCapacity: false,
    lastModifiedBy: "",
};

interface itemContainerProps {
    items?: Item;
}

const ItemContainer: FC<itemContainerProps> = ({ items }) => {
    const navigate = useNavigate();
    const { triggerShowModal, setActions, isDirty, setIsDirty, setDataToDisplay, setScreen } =
        useContext(FormContext);
    const [itemCategory, setItemCategory] = useState<{ key: number; value: string }[]>([]);
    const [itemUnits, setItemUnits] = useState<{ key: string; value: string }[]>([]);
    const [cookingStations, setCookingStations] = useState([]);
    const [itemData, setItemData] = useState<ItemFormData>(initialFormData);
    const [machines, setMachines] = useState<MachineFormData[]>();
    const [applicableMachines, setApplicableMachines] = useState<MachineFormData[]>([]);
    const [errors, setErrors] = useState<Partial<Errors>>();
    const [machinesList, setMachinesList] = useState<ItemMachineList>({});
    const [addRangeList, setAddRangeList] = useState<ItemMachineRange[]>([]);
    const [products, setProducts] = useState<DisplayData[]>([]);

    useEffect(() => {
        getItemCategories()
            .then((res) => {
                setItemCategory(res.data.data);
            })
            .catch(() => setItemCategory([]));

        getItemUnits().then((res) => {
            setItemUnits(res.data.data);
        });
        getCookingStations().then((res) => {
            const result = res.data.data;
            const cookingStation = result.filter(
                (item: { stationType: string; stations: [] }) => item.stationType === "Cooking",
            );
            setCookingStations(cookingStation[0]?.stations);
        });

        getMachines().then((res) => {
            setMachines(res.data.data);
        });
    }, []);

    useEffect(() => {
        if (items) {
            const tempObj = { ...items };
            const { itemMachines } = tempObj;
            tempObj.preparationTime = getSecondsInTime(Number(items.preparationTime));
            tempObj.crispyTime = getSecondsInTime(Number(items.crispyTime));
            tempObj.drainageTime = getSecondsInTime(Number(items.drainageTime));
            tempObj.holdingTime = getSecondsInTime(Number(items.holdingTime));
            tempObj.units = String(items.units);

            if (itemMachines.length) {
                // Transform Machine Object
                const tempMachineMap: { [key: string]: ItemMachineDetails } = {};
                Object.values(itemMachines)?.forEach((obj) => {
                    tempMachineMap[`${obj.machineConfigId}`] = {
                        ...obj,
                        recoveryTime: getSecondsInTime(Number(obj.recoveryTime)),
                        resetTime: getSecondsInTime(Number(obj.resetTime)),
                        itemMachineTimeRange: obj.itemMachineTimeRange.map((timeRange) => ({
                            ...timeRange,
                            cookingTime: getSecondsInTime(Number(timeRange.cookingTime)),
                        })),
                    };
                });
                setMachinesList({ ...tempMachineMap });
            }
            setItemData({ ...(tempObj as ItemFormData) });

            // verify item attached with product
            getProducts(undefined, undefined, undefined, undefined, undefined, items.id)
                .then((res) => {
                    const result = res?.data?.data;
                    if (result) {
                        setProducts([...result]);
                    }
                })
                .catch((err) => console.error(err));
        }
    }, [items]);

    useEffect(() => {
        if (items) {
            const { cookingStationId } = items;
            filterApplicableMachines(cookingStationId);
        }
    }, [items, machines]);

    const filterApplicableMachines = (selectedStation: string | number | boolean) => {
        const result: MachineFormData[] = [];
        machines?.forEach((machine: MachineFormData) => {
            machine.applicableStations.forEach((station) => {
                if (station.id === selectedStation) {
                    result.push(machine);
                }
            });
        });

        setApplicableMachines(result);
    };

    const handleChange = (name: string, value: string | boolean | number) => {
        setErrors({});
        const itemDataClone = JSON.parse(JSON.stringify(itemData));
        if (Object.keys(machinesList).length && name.includes("-")) {
            // means now changes happening on added machines properties minqunatity-id:0
            const splitNameByColon = name.split(":");
            const rangeIndex = splitNameByColon[1] ? Number(splitNameByColon[1]) : -1;
            const property: string = name.split("-")[0];
            if (
                property === "minQuantity" ||
                property === "maxQuantity" ||
                property === "cookingTime" ||
                property === "cyclicCapacity"
            ) {
                name = splitNameByColon[0];
            }

            let index = 0;
            let machineConfigId = "";
            for (; index < name.length; index++) {
                if (name.charAt(index) == "-") {
                    machineConfigId = name.slice(index + 1);
                    break;
                }
            }

            if (machinesList[machineConfigId]) {
                const tempObj = JSON.parse(JSON.stringify(machinesList[machineConfigId]));
                if (
                    property === "minQuantity" ||
                    property === "maxQuantity" ||
                    property === "cookingTime"
                ) {
                    if (tempObj?.itemMachineTimeRange) {
                        if (rangeIndex === -1) {
                            tempObj[property] = value;
                        } else {
                            if (property === "cookingTime") {
                                tempObj.itemMachineTimeRange[rangeIndex][property] = value;
                            } else tempObj.itemMachineTimeRange[rangeIndex][property] = value;
                        }
                    }
                } else {
                    if (
                        property === "recoveryTime" ||
                        property === "resetTime" ||
                        property === "cookingTime"
                    ) {
                        tempObj[property] = value;
                    } else {
                        if (property === "cyclicCapacity") {
                            triggerShowModal(true, ModalType.COOKING_STATION_SELECTION);
                            setActions({
                                done: () => {
                                    triggerShowModal(false, ModalType.COOKING_STATION_SELECTION);
                                    tempObj.machineCapacity = "";
                                    tempObj.recoveryTime = "00:00:00";
                                    tempObj.resetTime = "00:00:00";
                                    tempObj.itemMachineTimeRange = [];
                                    if (value) {
                                        tempObj.itemMachineTimeRange = [
                                            {
                                                cookingTime: "00:00:00",
                                                minQuantity: "",
                                                maxQuantity: "",
                                            },
                                            {
                                                cookingTime: "00:00:00",
                                                minQuantity: "",
                                                maxQuantity: "",
                                            },
                                        ];
                                    }
                                    tempObj[property] = value;
                                    machinesList[machineConfigId] = { ...tempObj };
                                    setMachinesList({ ...machinesList });
                                },
                                cancel: () => {
                                    triggerShowModal(false, ModalType.COOKING_STATION_SELECTION);
                                },
                            });
                        } else tempObj[property] = value;
                    }
                }
                if (property !== "cyclicCapacity") {
                    machinesList[machineConfigId] = { ...tempObj };
                    setMachinesList({ ...machinesList });
                }
            }
        } else if (addRangeList.length && name.includes(":")) {
            const splitNameByColon = name.split(":");
            const rangeIndex = Number(splitNameByColon[1]);
            const currentRange = addRangeList[rangeIndex];

            currentRange[`${splitNameByColon[0]}`] = String(value);

            setAddRangeList([...addRangeList]);
        } else {
            if (errors?.[name]) {
                setErrors({ ...errors, [name]: "" });
            }
            if (name === "cookingStationId") {
                // logic to vanish all form data
                if (itemDataClone.cookingStationId) {
                    triggerShowModal(true, ModalType.COOKING_STATION_SELECTION);
                    setActions({
                        done: () => {
                            setMachinesList({});
                            setErrors(undefined);
                            triggerShowModal(false, ModalType.COOKING_STATION_SELECTION);
                            setIsDirty(false);
                            filterApplicableMachines(value);
                            setItemData({
                                ...itemDataClone,
                                machineConfigId: "",
                                machineCapacity: 0,
                                recoveryTime: "00:00:00",
                                resetTime: "00:00:00",
                                minQuantity: "",
                                maxQuantity: "",
                                cookingTime: "00:00:00",
                                [name]: value,
                            });
                        },
                        cancel: () => {
                            triggerShowModal(false, ModalType.COOKING_STATION_SELECTION);
                        },
                    });
                } else {
                    filterApplicableMachines(value);
                    setItemData({ ...itemDataClone, [name]: value });
                }
            }
            if (name === "cyclicCapacity") {
                triggerShowModal(true, ModalType.COOKING_STATION_SELECTION);
                setActions({
                    done: () => {
                        triggerShowModal(false, ModalType.COOKING_STATION_SELECTION);
                        setIsDirty(false);
                        setAddRangeList([]);
                        itemDataClone.machineCapacity = "";
                        itemDataClone.recoveryTime = "00:00:00";
                        itemDataClone.resetTime = "00:00:00";
                        itemDataClone.cookingTime = "00:00:00";
                        itemDataClone.minQuantity = "";
                        itemDataClone.maxQuantity = "";
                        itemDataClone["cookingTime:1"] = "00:00:00";
                        itemDataClone["minQuantity:1"] = "";
                        itemDataClone["maxQuantity:1"] = "";
                        setItemData({ ...itemDataClone, [name]: value });
                    },
                    cancel: () => {
                        triggerShowModal(false, ModalType.COOKING_STATION_SELECTION);
                    },
                });
            }
            if (name !== "cookingStationId" && name !== "cyclicCapacity")
                setItemData({ ...itemDataClone, [name]: value });
        }
    };

    const addMachineHandler = () => {
        const {
            machineConfigId,
            machineCapacity,
            recoveryTime,
            cyclicCapacity,
            resetTime,
            cookingTime,
            minQuantity,
            maxQuantity,
        } = itemData;
        const currentErrors: Partial<Errors> = {};
        const isMinQuantity = !!minQuantity;
        const isMaxQuantity = !!maxQuantity;
        const isCookingTime = getTimeInSeconds(cookingTime) > 0;
        const isMinQuantity1 = !!itemData["minQuantity:1"];
        const isMaxQuantity1 = !!itemData["maxQuantity:1"];
        const isCookingTime1 = getTimeInSeconds(String(itemData["cookingTime:1"])) > 0;
        const isMachineClassId = !!machineConfigId;
        const isMachineCapacity = !!machineCapacity;
        const isRecoveryTime = getTimeInSeconds(recoveryTime) > 0;
        const isResetTime = getTimeInSeconds(resetTime) > 0;
        const isLastRangeValid =
            isMinQuantity &&
            isMaxQuantity &&
            isCookingTime &&
            minMaxValid(Number(minQuantity), Number(maxQuantity)) &&
            isEnteredRangeValid(addRangeList, Number(minQuantity), Number(maxQuantity));
        const isCyclicCapacityFormValid =
            cyclicCapacity &&
            isLastRangeValid &&
            isMinQuantity1 &&
            isMaxQuantity1 &&
            isCookingTime1 &&
            minMaxValid(Number(itemData["minQuantity:1"]), Number(itemData["maxQuantity:1"]));
        const isQuantityValid = cyclicCapacity
            ? isCyclicCapacityFormValid
            : isLastRangeValid || addRangeList.length;
        const isMachineFormValid = isMachineClassId
            ? isMachineCapacity && (isRecoveryTime || isResetTime) && isQuantityValid
            : true;

        const machinesListClone = JSON.parse(JSON.stringify(machinesList));
        if (machinesListClone[String(machineConfigId)]) {
            currentErrors.machineConfigId = "Machine details already entered.";
        } else if (isMachineFormValid) {
            let ranges = [...addRangeList];

            if (isCyclicCapacityFormValid) {
                ranges = [
                    ...addRangeList,
                    {
                        minQuantity,
                        maxQuantity,
                        cookingTime: cookingTime,
                    },
                    {
                        minQuantity: itemData["minQuantity:1"] || "",
                        maxQuantity: itemData["maxQuantity:1"] || "",
                        cookingTime: String(itemData["cookingTime:1"]),
                    },
                ];
            } else if (isLastRangeValid) {
                ranges = [
                    ...addRangeList,
                    {
                        minQuantity,
                        maxQuantity,
                        cookingTime: cookingTime,
                    },
                ];
            }

            const obj = {
                [String(machineConfigId)]: {
                    machineConfigId,
                    machineCapacity: Number(machineCapacity),
                    recoveryTime: recoveryTime,
                    cyclicCapacity: cyclicCapacity,
                    resetTime: resetTime,
                    itemMachineTimeRange: [...ranges],
                },
            };

            setItemData({
                ...itemData,
                machineConfigId: "",
                machineCapacity: 0,
                recoveryTime: "00:00:00",
                resetTime: "00:00:00",
                minQuantity: "",
                maxQuantity: "",
                cookingTime: "00:00:00",
                "minQuantity:1": "",
                "maxQuantity:1": "",
                "cookingTime:1": "00:00:00",
            });
            setMachinesList({ ...machinesListClone, ...obj });
            setAddRangeList([]);
        } else {
            if (!isMachineClassId) {
                currentErrors.machineConfigId = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.APPLICABLEMACHINE.LABEL"),
                })}`;
            }
            if (!isMachineCapacity) {
                currentErrors.machineCapacity = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.CAPACITY.LABEL"),
                })}`;
            }
            if (!isRecoveryTime) {
                currentErrors.recoveryTime = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.RECOVERYTIME.LABEL"),
                })}`;
            }
            if (!isResetTime) {
                currentErrors.resetTime = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.RESETTIME.LABEL"),
                })}`;
            }
            if (!addRangeList.length) {
                if (!minQuantity) {
                    currentErrors.minQuantity = `${t("ERRORS.REQUIRED", {
                        name: t("ADD.ITEM.QUANTITY.LABEL"),
                    })}`;
                }
                if (!maxQuantity) {
                    currentErrors.maxQuantity = `${t("ERRORS.REQUIRED", {
                        name: t("ADD.ITEM.QUANTITYMAX.LABEL"),
                    })}`;
                }
                if (!isCookingTime) {
                    currentErrors.cookingTime = `${t("ERRORS.REQUIRED", {
                        name: t("ADD.ITEM.COOKINGTIME.LABEL"),
                    })}`;
                }
                if (!itemData["minQuantity:1"]) {
                    currentErrors["minQuantity:1"] = `${t("ERRORS.REQUIRED", {
                        name: t("ADD.ITEM.QUANTITY.LABEL"),
                    })}`;
                }
                if (!itemData["maxQuantity:1"]) {
                    currentErrors["maxQuantity:1"] = `${t("ERRORS.REQUIRED", {
                        name: t("ADD.ITEM.QUANTITYMAX.LABEL"),
                    })}`;
                }
                if (!isCookingTime1) {
                    currentErrors["cookingTime:1"] = `${t("ERRORS.REQUIRED", {
                        name: t("ADD.ITEM.COOKINGTIME.LABEL"),
                    })}`;
                }
            }
        }
        setErrors({ ...errors, ...currentErrors });
    };

    const deleteMachine = (machineConfigId: string) => {
        triggerShowModal(true, ModalType.SUBMIT);
        setIsDirty(true);
        setActions({
            done: () => {
                delete machinesList[String(machineConfigId)];
                setMachinesList({ ...machinesList });
                triggerShowModal(false, ModalType.SUBMIT);
            },
            cancel: () => {
                triggerShowModal(false, ModalType.COOKING_STATION_SELECTION);
            },
        });
    };

    // const isEnteredRangeValid = (
    //     data: ItemMachineRange[],
    //     minQuantityVal: number,
    //     maxQuantityVal: number,
    // ) => {
    //     let isValidEntry = true;
    //     if (Array.isArray(data) && minQuantityVal && maxQuantityVal) {
    //         for (let i = 0; i < data.length; i++) {
    //             if (
    //                 !(
    //                     minQuantityVal < Number(data[i].minQuantity) ||
    //                     (minQuantityVal > Number(data[i].maxQuantity) &&
    //                         (maxQuantityVal < Number(data[i].minQuantity) ||
    //                             maxQuantityVal > Number(data[i].maxQuantity)))
    //                 )
    //             ) {
    //                 isValidEntry = false;
    //                 break;
    //             }
    //         }
    //     }
    //     return isValidEntry;
    // };

    const isEnteredRangeValid = (
        data: ItemMachineRange[],
        minQuantityVal: number,
        maxQuantityVal: number,
    ) => {
        let isValidEntry = true;
        if (Array.isArray(data) && minQuantityVal && maxQuantityVal) {
            for (let i = 0; i < data.length; i++) {
                if (
                    !(
                        (minQuantityVal < Number(data[i].minQuantity) &&
                            maxQuantityVal < Number(data[i].minQuantity)) ||
                        (minQuantityVal > Number(data[i].maxQuantity) &&
                            maxQuantityVal > Number(data[i].maxQuantity))
                    )
                ) {
                    isValidEntry = false;
                    break;
                }
            }
        }
        return isValidEntry;
    };

    const minMaxValid = (min: number, max: number) => {
        if (min > max) {
            const currentErrors: Partial<Errors> = {};
            currentErrors.maxQuantity = `${t("ERRORS.MAX_VALUE")}`;
            setErrors({ ...errors, ...currentErrors });
            return false;
        }
        return true;
    };

    const addRange = (addedMachineId?: string) => {
        const { cookingTime = "00:00:00", minQuantity = "", maxQuantity = "" } = itemData;
        const isRangeFormFilled = cookingTime && minQuantity && maxQuantity;
        const machinesListClone = JSON.parse(JSON.stringify(machinesList));

        if (addedMachineId && machinesListClone[addedMachineId]) {
            const { cookingTime, minQuantity, maxQuantity, itemMachineTimeRange } =
                machinesListClone[addedMachineId];
            const currentErrors: Partial<Errors> = {};

            if (!machinesListClone[addedMachineId].minQuantity) {
                currentErrors[`minQuantity-${addedMachineId}`] = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.QUANTITY.LABEL"),
                })}`;

                setErrors({ ...errors, ...currentErrors });
            } else if (!machinesListClone[addedMachineId].maxQuantity) {
                currentErrors[`maxQuantity-${addedMachineId}`] = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.QUANTITYMAX.LABEL"),
                })}`;

                setErrors({ ...errors, ...currentErrors });
            } else if (
                !minMaxValid(
                    Number(machinesListClone[addedMachineId].minQuantity),
                    Number(machinesListClone[addedMachineId].maxQuantity),
                )
            ) {
                currentErrors[`maxQuantity-${addedMachineId}`] = `${t("ERRORS.MAX_VALUE")}`;
                setErrors({ ...errors, ...currentErrors });
            } else if (
                !isEnteredRangeValid(
                    machinesListClone[addedMachineId].itemMachineTimeRange,
                    machinesListClone[addedMachineId].minQuantity,
                    machinesListClone[addedMachineId].maxQuantity,
                )
            ) {
                currentErrors[`minQuantity-${addedMachineId}`] = `${t("ERRORS.DUPLICATE_VALUE")}`;
                setErrors({ ...errors, ...currentErrors });
            } else if (!machinesListClone[addedMachineId].cookingTime) {
                currentErrors[`cookingTime-${addedMachineId}`] = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.COOKINGTIME.LABEL"),
                })}`;

                setErrors({ ...errors, ...currentErrors });
            } else {
                itemMachineTimeRange?.push({
                    cookingTime: cookingTime,
                    minQuantity: Number(minQuantity),
                    maxQuantity: Number(maxQuantity),
                });
                machinesListClone[String(addedMachineId)].minQuantity = "";
                machinesListClone[String(addedMachineId)].maxQuantity = "";
                machinesListClone[String(addedMachineId)].cookingTime = "00:00:00";
                setMachinesList({ ...machinesListClone });
                setErrors({});
            }
        } else if (isRangeFormFilled && minMaxValid(Number(minQuantity), Number(maxQuantity))) {
            const tempObj = { ...itemData };
            tempObj.cookingTime = "00:00:00";
            tempObj.minQuantity = "";
            tempObj.maxQuantity = "";
            // Check for if Entered Range lies in added Ranges
            if (
                addRangeList.length &&
                isEnteredRangeValid(addRangeList, Number(minQuantity), Number(maxQuantity))
            ) {
                setAddRangeList([
                    ...addRangeList,
                    {
                        cookingTime: cookingTime,
                        minQuantity: Number(minQuantity),
                        maxQuantity: Number(maxQuantity),
                    },
                ]);
                setItemData(tempObj);
            } else if (!addRangeList.length) {
                setAddRangeList([
                    ...addRangeList,
                    {
                        cookingTime: cookingTime,
                        minQuantity: Number(minQuantity),
                        maxQuantity: Number(maxQuantity),
                    },
                ]);
                setItemData(tempObj);
            } else {
                const currentErrors: Partial<Errors> = {};

                currentErrors.minQuantity = `${t("ERRORS.DUPLICATE_VALUE")}`;

                setErrors({ ...errors, ...currentErrors });
            }
        } else if (!isRangeFormFilled) {
            const currentErrors: Partial<Errors> = {};
            if (!minQuantity)
                currentErrors.minQuantity = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.QUANTITY.LABEL"),
                })}`;
            else if (!maxQuantity)
                currentErrors.maxQuantity = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.QUANTITYMAX.LABEL"),
                })}`;
            else if (!cookingTime)
                currentErrors.cookingTime = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.COOKINGTIME.LABEL"),
                })}`;

            setErrors({ ...errors, ...currentErrors });
        }
    };

    const deleteRange = (name: string, machineConfigId: string) => {
        setIsDirty(true);
        const splitNameByColon = name.split(":");
        const removeIndex = splitNameByColon[1] ? Number(splitNameByColon[1]) : -1;
        if (machineConfigId) {
            if (machinesList[machineConfigId]) {
                const tempArray = machinesList[machineConfigId]?.itemMachineTimeRange;
                tempArray?.splice(removeIndex, 1);
                setMachinesList({ ...machinesList });
            }
        } else {
            addRangeList.splice(removeIndex, 1);
            setAddRangeList([...addRangeList]);
        }
    };

    const handleCancel = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    setItemData(initialFormData);
                    setErrors(undefined);
                    navigate(-1);
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        } else navigate(RoutePaths.ViewItems);
    };

    const postObj = () => {
        const itemDataClone = JSON.parse(JSON.stringify(itemData));
        const obj = {
            ...itemDataClone,
            drainageTime: getTimeInSeconds(itemDataClone.drainageTime),
            holdingTime: getTimeInSeconds(itemDataClone.holdingTime),
            preparationTime: getTimeInSeconds(itemDataClone.preparationTime),
            crispyTime: getTimeInSeconds(itemDataClone.crispyTime),
            itemCategory: Number(itemDataClone.itemCategory),
            units: Number(itemDataClone.units),
            createdBy: "",
        };
        const itemMachines = Object.values(machinesList);
        // logic to add machine entered in form
        const {
            machineConfigId,
            machineCapacity,
            recoveryTime,
            resetTime,
            cyclicCapacity,
            maxQuantity,
            minQuantity,
            cookingTime,
        } = itemDataClone;

        // logic to add machines entered minQuantity manQuantity on added section
        if (itemMachines.length) {
            itemMachines.map((item: ItemMachineDetails) => {
                if (item.maxQuantity && item.minQuantity && item.cookingTime) {
                    item.itemMachineTimeRange.push({
                        minQuantity: Number(item.minQuantity),
                        maxQuantity: Number(item.maxQuantity),
                        cookingTime: item.cookingTime,
                    });
                }
            });
        }

        const isMachineEnteredForm =
            machineConfigId && machineCapacity && (recoveryTime || resetTime);

        const isRangeFormEntered = maxQuantity && minQuantity && cookingTime;
        const isCyclicRangeFormEntered =
            cyclicCapacity &&
            isRangeFormEntered &&
            itemDataClone["minQuantity:1"] &&
            itemDataClone["maxQuantity:1"] &&
            itemDataClone["cookingTime:1"];

        let temparray: ItemMachineRange[] = [];
        temparray = [...addRangeList];
        if (isRangeFormEntered) {
            temparray = [
                ...addRangeList,
                {
                    minQuantity: Number(minQuantity),
                    maxQuantity: Number(maxQuantity),
                    cookingTime: cookingTime,
                },
            ];
        }

        if (isCyclicRangeFormEntered) {
            temparray.push({
                minQuantity: Number(itemDataClone["minQuantity:1"]),
                maxQuantity: Number(itemDataClone["maxQuantity:1"]),
                cookingTime: itemDataClone["cookingTime:1"],
            });
        }

        if (isMachineEnteredForm) {
            itemMachines.push({
                machineConfigId,
                machineCapacity: Number(machineCapacity),
                recoveryTime: recoveryTime,
                resetTime: resetTime,
                cyclicCapacity: cyclicCapacity || false,
                itemMachineTimeRange: [...temparray],
            } as ItemMachineDetails);
        }
        delete obj.itemMachineTimeRange;
        delete obj.cyclicCapacity;
        delete obj.machineCapacity;
        delete obj.machineConfigId;
        delete obj.recoveryTime;
        delete obj.resetTime;
        delete obj.maxQuantity;
        delete obj.minQuantity;
        delete obj.cookingTime;
        delete obj["minQuantity:1"];
        delete obj["maxQuantity:1"];
        delete obj["cookingTime:1"];
        obj.itemMachines = [
            ...(itemMachines?.map((machine) => ({
                ...machine,
                recoveryTime: getTimeInSeconds(String(machine?.recoveryTime)),
                resetTime: getTimeInSeconds(String(machine?.resetTime)),
                itemMachineTimeRange: machine?.itemMachineTimeRange?.map((timeRange) => ({
                    ...timeRange,
                    cookingTime: getTimeInSeconds(String(timeRange?.cookingTime)),
                })),
            })) ?? []),
        ];
        return obj;
    };

    const handleCreateItem = () => {
        createItem({ ...postObj() })
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                navigate(RoutePaths.ViewItems);
                setIsDirty(false);
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                setErrors({
                    ...errors,
                });
            });
    };

    const handleUpdateItems = () => {
        const tempObj = {
            ...postObj(),
        };
        updateItem({
            ...tempObj,
            lastModifiedBy: "",
        })
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                setIsDirty(false);
                navigate(RoutePaths.ViewItems);
            })
            .catch((error) => {
                triggerShowModal(false, ModalType.SUBMIT);
                console.log(error);
            });
    };
    const checkForAddedMachineInputsValidity = (
        machineObj: ItemMachineList,
        currentErrors: Partial<Errors> = {},
    ) => {
        const addeMachineValidity = { isValid: true, currentErrors: currentErrors };
        let validParam = true;
        const keysArray = Object.keys(machineObj);
        for (let i = 0; i < keysArray.length; i++) {
            const {
                itemMachineTimeRange,
                cyclicCapacity,
                machineCapacity,
                resetTime,
                recoveryTime,
                minQuantity,
                maxQuantity,
                cookingTime,
            } = machineObj[keysArray[i]];

            if (!machineCapacity) {
                currentErrors[`machineCapacity-${keysArray[i]}`] = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.CAPACITY.LABEL"),
                })}`;
                validParam = false;
            }

            if (cyclicCapacity && !resetTime) {
                currentErrors[`resetTime-${keysArray[i]}`] = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.RESETTIME.LABEL"),
                })}`;
                validParam = false;
            }

            if (!cyclicCapacity && !recoveryTime) {
                currentErrors[`recoveryTime-${keysArray[i]}`] = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.RECOVERYTIME.LABEL"),
                })}`;
                validParam = false;
            }
            // Case when no list added for non cyclic capacity
            if (
                !cyclicCapacity &&
                (!itemMachineTimeRange?.length || minQuantity || maxQuantity || cookingTime)
            ) {
                if (!minQuantity) {
                    currentErrors[`minQuantity-${keysArray[i]}`] = `${t("ERRORS.REQUIRED", {
                        name: t("ADD.ITEM.QUANTITY.LABEL"),
                    })}`;
                    validParam = false;
                }
                if (!maxQuantity) {
                    currentErrors[`maxQuantity-${keysArray[i]}`] = `${t("ERRORS.REQUIRED", {
                        name: t("ADD.ITEM.QUANTITYMAX.LABEL"),
                    })}`;
                    validParam = false;
                }
                if (!cookingTime) {
                    currentErrors[`cookingTime-${keysArray[i]}`] = `${t("ERRORS.REQUIRED", {
                        name: t("ADD.ITEM.COOKINGTIME.LABEL"),
                    })}`;
                    validParam = false;
                }
            }

            itemMachineTimeRange?.forEach((rangeObj, index) => {
                if (rangeObj) {
                    // case for range and cyclic capacity true
                    const { minQuantity, maxQuantity, cookingTime } = rangeObj;
                    if (!minQuantity) {
                        currentErrors[`minQuantity-${keysArray[i]}:${index}`] = `${t(
                            "ERRORS.REQUIRED",
                            {
                                name: t("ADD.ITEM.QUANTITY.LABEL"),
                            },
                        )}`;
                        validParam = false;
                    }
                    if (!maxQuantity) {
                        currentErrors[`maxQuantity-${keysArray[i]}:${index}`] = `${t(
                            "ERRORS.REQUIRED",
                            {
                                name: t("ADD.ITEM.QUANTITYMAX.LABEL"),
                            },
                        )}`;
                        validParam = false;
                    }
                    if (!minMaxValid(Number(minQuantity), Number(maxQuantity))) {
                        currentErrors[`maxQuantity-${keysArray[i]}:${index}`] = `${t(
                            "ERRORS.MAX_VALUE",
                        )}`;
                        validParam = false;
                    }
                    if (
                        !cyclicCapacity &&
                        !isEnteredRangeValid(
                            itemMachineTimeRange,
                            machineObj[keysArray[i]]?.minQuantity,
                            machineObj[keysArray[i]]?.maxQuantity,
                        )
                    ) {
                        currentErrors[`minQuantity-${keysArray[i]}:${index}`] = `${t(
                            "ERRORS.DUPLICATE_VALUE",
                        )}`;
                        validParam = false;
                    }
                    if (!cookingTime) {
                        currentErrors[`cookingTime-${keysArray[i]}:${index}`] = `${t(
                            "ERRORS.REQUIRED",
                            {
                                name: t("ADD.ITEM.COOKINGTIME.LABEL"),
                            },
                        )}`;
                        validParam = false;
                    }
                }
            });
            addeMachineValidity.currentErrors = { ...currentErrors };
            addeMachineValidity.isValid = validParam;
        }
        return addeMachineValidity;
    };
    const handleSubmit = () => {
        const {
            name,
            code,
            displayName,
            cookingStationId,
            cyclicCapacity,
            drainageTime,
            holdingTime,
            itemCategory,
            imageId,
            units,
            preparationTime,
            crispyTime,
            machineConfigId,
            machineCapacity,
            recoveryTime,
            minQuantity,
            maxQuantity,
            cookingTime,
            resetTime,
        } = itemData;
        const isItemNameValid = !!name;
        const isIdValid = !!code;
        const isDisplayNameValid = !!displayName;
        const isCookingStationId = !!cookingStationId;
        const isDrainageTimeValid = getTimeInSeconds(drainageTime) > 0;
        const isHoldingTimeValid = getTimeInSeconds(holdingTime) > 0;
        const isItemCategoryValid = !!(itemCategory + "");
        const isUnitsValid = !!(units + "");
        const isPreparationTimeValid = getTimeInSeconds(preparationTime) > 0;
        const isCrispyTimeValid = getTimeInSeconds(crispyTime) > 0;
        const isMachineClassId = !!machineConfigId;
        const isMachineCapacity = !!machineCapacity;
        const isRecoveryTime = getTimeInSeconds(recoveryTime) > 0;
        const isResetTime = getTimeInSeconds(resetTime) > 0;
        const isMinQuantity = !!minQuantity;
        const isMaxQuantity = !!maxQuantity;
        const isCookingTime = getTimeInSeconds(cookingTime) > 0;
        const isImageIdValid = !!imageId;
        const isMinQuantity1 = !!itemData["minQuantity:1"];
        const isMaxQuantity1 = !!itemData["maxQuantity:1"];
        const isCookingTime1 = !!itemData["cookingTime:1"];
        // validations for form inputs
        const isAddQuantityValid =
            isMinQuantity &&
            isMaxQuantity &&
            isCookingTime &&
            minMaxValid(Number(minQuantity), Number(maxQuantity)) &&
            isEnteredRangeValid(addRangeList, Number(minQuantity), Number(maxQuantity));

        const isCyclicQuantityValid =
            isMinQuantity1 &&
            isMaxQuantity1 &&
            isCookingTime1 &&
            minMaxValid(Number(itemData["minQuantity:1"]), Number(itemData["maxQuantity:1"]));
        const isQuantityValid = cyclicCapacity
            ? isCyclicQuantityValid && isAddQuantityValid
            : isAddQuantityValid;

        const isMachineAdded = Object.keys(machinesList).length;

        const isMachineFormValid = isMachineClassId
            ? isMachineCapacity && (isRecoveryTime || isResetTime) && isQuantityValid
            : isMachineAdded;
        // case for when delete Machines and also not select new Machines, so Make form invalid

        const isDuplicateMachine =
            isMachineAdded && isMachineClassId && machinesList[machineConfigId];

        // <!--Check needs to be add for add Ranges code-->
        const isDataValid =
            isItemNameValid &&
            isIdValid &&
            isDisplayNameValid &&
            isCookingStationId &&
            isDrainageTimeValid &&
            isHoldingTimeValid &&
            isItemCategoryValid &&
            isUnitsValid &&
            isPreparationTimeValid &&
            isCrispyTimeValid &&
            isImageIdValid &&
            (isMachineFormValid ||
                (addRangeList.length &&
                    minQuantity === "" &&
                    maxQuantity === "" &&
                    cookingTime === "")) &&
            checkForAddedMachineInputsValidity(machinesList).isValid &&
            !isDuplicateMachine;

        if (isDataValid) {
            setErrors(undefined);
            if (items && items.status && !itemData.status) {
                if (products.length) {
                    setScreen(ScreenType.INACTIVE_ITEM_DENIED);
                    setDataToDisplay(products);
                    triggerShowModal(true, ModalType.INACTIVE_ITEM_DENIED);
                } else {
                    setScreen(ScreenType.ITEM);
                    triggerShowModal(true, ModalType.INACTIVE_SUBMISSION);
                }
                setActions({
                    done: () => {
                        setScreen(undefined);
                        setDataToDisplay([]);
                        if (products.length) {
                            triggerShowModal(false, ModalType.INACTIVE_ITEM_DENIED);
                            navigate(RoutePaths.ViewProducts, {
                                state: { itemId: items?.id, itemName: items?.displayName },
                            });
                        } else {
                            triggerShowModal(false, ModalType.INACTIVE_SUBMISSION);
                            handleUpdateItems();
                        }
                        setIsDirty(false);
                    },
                    cancel: () => {
                        if (products.length) {
                            triggerShowModal(false, ModalType.INACTIVE_ITEM_DENIED);
                        } else {
                            triggerShowModal(false, ModalType.INACTIVE_SUBMISSION);
                        }
                        setScreen(undefined);
                        setDataToDisplay([]);
                        setItemData({ ...itemData, status: true });
                    },
                });
            } else {
                triggerShowModal(true, ModalType.SUBMIT);
                setActions({
                    done: () => (items ? handleUpdateItems() : handleCreateItem()),
                    cancel: () => triggerShowModal(false, ModalType.SUBMIT),
                });
            }
        } else {
            let currentErrors: Partial<Errors> = {};
            if (!isItemNameValid)
                currentErrors.name = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.NAME.LABEL"),
                })}`;
            if (!isIdValid)
                currentErrors.code = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.CODE.LABEL"),
                })}`;
            if (!isDisplayNameValid)
                currentErrors.displayName = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.DISPLAYNAME.LABEL"),
                })}`;
            if (!isImageIdValid) currentErrors.imageId = `${t("UPLOADIMAGE.REQUIRED")}`;
            if (!isCookingStationId)
                currentErrors.cookingStationId = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.STATION.LABEL"),
                })}`;
            if (!isHoldingTimeValid)
                currentErrors.holdingTime = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.HOLDTIME.LABEL"),
                })}`;
            if (!isDrainageTimeValid)
                currentErrors.drainageTime = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.DRAINTIME.LABEL"),
                })}`;
            if (!isItemCategoryValid)
                currentErrors.itemCategory = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.CATEGORY.LABEL"),
                })}`;
            if (!isUnitsValid)
                currentErrors.units = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.UNITS.LABEL"),
                })}`;
            if (!isPreparationTimeValid)
                currentErrors.preparationTime = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.PREPTIME.LABEL"),
                })}`;
            if (!isCrispyTimeValid)
                currentErrors.crispyTime = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.CRISPYTIME.LABEL"),
                })}`;
            if (!isMachineAdded && !machineConfigId) {
                currentErrors.machineConfigId = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.APPLICABLEMACHINE.LABEL"),
                })}`;
            }
            if (isDuplicateMachine) {
                currentErrors.machineConfigId = "Machine details already entered.";
            }
            if (machineConfigId && !isMachineCapacity) {
                currentErrors.machineCapacity = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.CAPACITY.LABEL"),
                })}`;
            }
            if (!cyclicCapacity && machineConfigId && !isRecoveryTime) {
                currentErrors.recoveryTime = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.RECOVERYTIME.LABEL"),
                })}`;
            }
            if (!isResetTime && cyclicCapacity && machineConfigId) {
                currentErrors.resetTime = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.RESETTIME.LABEL"),
                })}`;
            }
            if (isMachineClassId && !isMinQuantity) {
                currentErrors.minQuantity = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.QUANTITY.LABEL"),
                })}`;
            }
            if (isMachineClassId && !isMaxQuantity) {
                currentErrors.maxQuantity = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.QUANTITYMAX.LABEL"),
                })}`;
            }
            if (isMachineClassId && !isCookingTime) {
                currentErrors.cookingTime = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.COOKINGTIME.LABEL"),
                })}`;
            }
            if (
                !cyclicCapacity &&
                !isEnteredRangeValid(addRangeList, Number(minQuantity), Number(maxQuantity))
            ) {
                currentErrors.minQuantity = `${t("ERRORS.DUPLICATE_VALUE")}`;
            }
            if (!minMaxValid(Number(minQuantity), Number(maxQuantity))) {
                currentErrors.maxQuantity = `${t("ERRORS.MAX_VALUE")}`;
            }
            if (cyclicCapacity && !isMinQuantity1) {
                currentErrors["minQuantity:1"] = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.QUANTITY.LABEL"),
                })}`;
            }
            if (cyclicCapacity && !isMaxQuantity1) {
                currentErrors["maxQuantity:1"] = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.QUANTITYMAX.LABEL"),
                })}`;
            }
            if (cyclicCapacity && !isCookingTime1) {
                currentErrors["cookingTime:1"] = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.ITEM.COOKINGTIME.LABEL"),
                })}`;
            }
            if (
                cyclicCapacity &&
                !minMaxValid(Number(itemData["minQuantity:1"]), Number(itemData["maxQuantity:1"]))
            ) {
                currentErrors["maxQuantity:1"] = `${t("ERRORS.MAX_VALUE")}`;
            }
            const AddedMachineError = checkForAddedMachineInputsValidity(
                machinesList,
                currentErrors,
            );
            if (!AddedMachineError.isValid) {
                currentErrors = { ...currentErrors, ...AddedMachineError.currentErrors };
            }
            setErrors({ ...errors, ...currentErrors });
        }
    };

    return (
        <FormWrapper
            heading={
                window.location.href.includes("edit") ? "Edit Item details" : t("ADD.ITEM.HEADING")
            }
            onCancelClick={handleCancel}
            onSubmitClick={handleSubmit}
            submitDisabled={!isDirty}
        >
            <ItemForm
                data={itemData}
                onChange={handleChange}
                itemCategory={itemCategory?.map(({ key, value }) => ({
                    value: String(key),
                    label: value,
                }))}
                errors={errors}
                isEdit={!!items}
                machinesList={machinesList}
                addMachine={addMachineHandler}
                addRange={addRange}
                itemUnits={itemUnits?.map(({ key, value }) => ({ value: key, label: value }))}
                cookingStations={cookingStations?.map(({ displayName, stationId }) => ({
                    value: stationId,
                    label: displayName,
                }))}
                applicableMachines={applicableMachines?.map(({ machineName, machineMake, id }) => ({
                    value: id,
                    label: machineMake + " - " + machineName,
                }))}
                addRangeList={addRangeList}
                deleteRange={deleteRange}
                deleteMachine={deleteMachine}
            />
        </FormWrapper>
    );
};

export default ItemContainer;
