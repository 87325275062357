import { t } from "i18next";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import FormWrapper from "@app/components/FormWrapper";
import UserForm from "@app/components/forms/User.form";
import { RoutePaths } from "@app/routes/index";
import { getBranchesData, getSingleUserBranches } from "@app/services/Endpoints/Branches.API";
import { getLicensee, getSingleUserLicensee } from "@app/services/Endpoints/Licensee.API";
import {
    createAlbaikUser,
    createBranchUser,
    createLincenseeUser,
    getRoles,
    getUserDetails,
    updateAlbaikUser,
    updateBranchUser,
    updateLincenseeUser,
} from "@app/services/Endpoints/User.API";
import { DisplayData, Errors, ModalType, ScreenType } from "@app/types/Common.types";
import { Licensee } from "@app/types/Licensee";
import { Branches } from "@app/types/Service.type";
import { Role, User, UserBranch, UserLevel } from "@app/types/User.type";
import { FormContext } from "@app/utils/contexts/Form.context";
import { isEmail } from "@app/utils/inputValidations";

interface UserContainerProps {
    user?: User;
}

const initialData = {
    id: "",
    name: "",
    userLevel: 0,
    phoneNumber: null,
    isActive: true,
    roleID: undefined,
    licenseeID: undefined,
    branches: undefined,
};

const UserContainer: FC<UserContainerProps> = ({ user }) => {
    const navigate = useNavigate();

    const { isDirty, triggerShowModal, setActions, setIsDirty, setDataToDisplay, setScreen } =
        useContext(FormContext);

    const [userRoles, setUserRoles] = useState<Role[]>([]);
    const [licensees, setLicensees] = useState<Licensee[]>([]);
    const [branches, setBranches] = useState<Branches[]>([]);
    const [userData, setUserData] = useState<User>(initialData);
    const [featchingUser, setFetchingUser] = useState<boolean>(false);
    const [errors, setErrors] = useState<Partial<Errors> | undefined>();
    const [displayData, setDisplayData] = useState<DisplayData[]>([]);

    const isLicensor = Number(userData.userLevel) === UserLevel.Licensor;
    const isLicensee = Number(userData.userLevel) === UserLevel.Licensee;
    const isBranch = Number(userData.userLevel) === UserLevel.Branch;

    const fetchUserRoles = useCallback(() => {
        getRoles(userData.userLevel)
            .then((res) => setUserRoles([...res.data.data]))
            .catch(() => setUserRoles([]));
    }, [userData.userLevel]);

    const fetchLincensees = useCallback(() => {
        getLicensee()
            .then((res) => setLicensees([...res.data.data]))
            .catch(() => setLicensees([]));
    }, []);

    const fetchBranches = useCallback(() => {
        getBranchesData()
            .then((res) => setBranches([...res.data.data]))
            .catch(() => setBranches([]));
    }, []);

    const fetchUserBranches = useCallback(() => {
        if (user?.id)
            getSingleUserBranches(user.id)
                .then((res) => setDisplayData([...(res?.data?.data ?? [])]))
                .catch(() => setDisplayData([]));
    }, [user?.id]);

    const fetchUserLincensees = useCallback(() => {
        if (user?.id)
            getSingleUserLicensee(user.id)
                .then((res) => setDisplayData([...(res?.data?.data ?? [])]))
                .catch(() => setDisplayData([]));
    }, [user?.id]);

    useEffect(() => {
        if (user && !userData.id) {
            const { id, name, userLevel, phoneNumber, roleID, licenseeID, isActive, branches } = {
                ...user,
            };
            setUserData({
                id,
                name,
                userLevel,
                phoneNumber,
                roleID,
                licenseeID,
                isActive,
                branches,
            });
        }
    }, [user, userData]);

    useEffect(() => {
        const { branches } = {
            ...user,
        };
        if (user && isBranch && !userData?.branches?.length) {
            setUserData({
                ...userData,
                branches: isBranch
                    ? branches?.length
                        ? branches
                        : [{ branchID: "", roleID: "" }]
                    : undefined,
            });
        }
    }, [user, isBranch, userData]);

    useEffect(() => {
        setUserRoles([]);
        setLicensees([]);
        setBranches([]);
        if (userData.userLevel) fetchUserRoles();
        if (isLicensee) {
            fetchLincensees();
            fetchUserLincensees();
        }
        if (isBranch) {
            fetchBranches();
            fetchUserBranches();
        }
    }, [
        userData.userLevel,
        fetchUserRoles,
        fetchLincensees,
        fetchBranches,
        isBranch,
        isLicensee,
        fetchUserBranches,
        fetchUserLincensees,
    ]);

    useEffect(() => {
        if (isBranch && !userData.branches) {
            setUserData({ ...userData, branches: [{ branchID: "", roleID: "" }] });
        }
    }, [isBranch, userData]);

    const handleChange = (
        name: string,
        value: string | boolean | UserBranch[],
        fromRemove?: boolean,
    ) => {
        const existingUserData = userData;
        if (name === "userLevel") {
            existingUserData.roleID = undefined;
        }
        if (isLicensor) {
            setUserData({
                ...existingUserData,
                [name]: value,
                licenseeID: undefined,
                branches: undefined,
            });
            setErrors({ ...errors, [name]: "", licenseeID: "", branches: "" });
        } else if (isLicensee) {
            setUserData({ ...existingUserData, [name]: value, branches: undefined });
            setErrors({ ...errors, [name]: "", branches: "" });
        } else {
            // logic to handle add user branch error
            const currentErrors: Partial<Errors> = { bLBranchID: "", bLRoleID: "" };
            let isValidBranchForm = true;

            if (isBranch && name === "branches" && Array.isArray(value) && !fromRemove) {
                const tempData = existingUserData?.branches || [];
                // const lengthVal=tempData.length>1?true:false;
                for (let i = 0; i < tempData.length; i++) {
                    if (!tempData[i].branchID) {
                        currentErrors.bLBranchID = `${t("ERRORS.REQUIRED", {
                            name: "Select Branch",
                        })}`;
                        isValidBranchForm = false;
                    } else {
                        currentErrors.bLBranchID = "";
                    }
                    if (!tempData[i].roleID) {
                        currentErrors.bLRoleID = `${t("ERRORS.REQUIRED", {
                            name: "Select User Role",
                        })}`;
                        isValidBranchForm = false;
                    } else {
                        currentErrors.bLRoleID = "";
                    }
                }
            }

            if (isValidBranchForm) {
                setUserData({
                    ...existingUserData,
                    [name]: value,
                    licenseeID: undefined,
                    roleID: undefined,
                });
            }
            setErrors({
                ...errors,
                ...currentErrors,
                [name]: "",
                licenseeID: "",
                branchRequired: "",
            });
        }
    };

    const handleBlur = (email: string) => {
        if (isEmail(email)) {
            setFetchingUser(true);
            getUserDetails(email)
                .then((res) => {
                    const result = res?.data?.data?.[0];
                    setUserData({
                        ...userData,
                        id: result.mail,
                        name: result.displayName,
                        phoneNumber: result.mobilePhone ?? null,
                    });
                })
                .catch(() => {
                    setErrors({ ...errors, id: `${t("ERRORS.NOT_FOUND", { name: "User ID" })}` });
                    setUserData({ ...initialData, id: email });
                })
                .finally(() => setFetchingUser(false));
        } else {
            setErrors({ ...errors, id: `${t("ERRORS.VALID_EMAIL")}` });
        }
    };

    const createUserCallback = useCallback(
        (data: Partial<User>) => {
            if (isLicensor) return createAlbaikUser(data);
            else if (isLicensee) return createLincenseeUser(data);
            else return createBranchUser(data);
        },
        [isLicensee, isLicensor],
    );

    const updateUserCallback = useCallback(
        (data: Partial<User>) => {
            if (isLicensor) return updateAlbaikUser(data);
            else if (isLicensee) return updateLincenseeUser(data);
            else return updateBranchUser(data);
        },
        [isLicensee, isLicensor],
    );

    const handleCreateUser = () => {
        const formData: Partial<User> = {
            ...userData,
            licenseeID: userData?.licenseeID ? userData?.licenseeID : undefined,
            userLevel: Number(userData.userLevel),
        };
        createUserCallback(formData)
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                setIsDirty(false);
                navigate(RoutePaths.ViewUsers);
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleEditUser = async () => {
        const formData: Partial<User> = {
            ...userData,
            licenseeID: userData?.licenseeID ? userData?.licenseeID : undefined,
            userLevel: Number(userData.userLevel),
        };
        return await updateUserCallback({ ...formData, id: String(formData.id) })
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                setIsDirty(false);
                navigate(RoutePaths.ViewUsers);
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleCancel = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    setUserData(initialData);
                    setErrors(undefined);
                    navigate(RoutePaths.ViewUsers);
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        } else navigate(RoutePaths.ViewUsers);
    };

    const handleSubmit = () => {
        const {
            id,
            name,
            userLevel: filledUserLevel,
            roleID,
            licenseeID,
            branches,
            isActive,
        } = userData;

        const userLevel = Number(filledUserLevel);

        const basicDetailsValid = !!id && !!name && !!userLevel;
        if (branches && branches?.length > 1) {
            const lastIndex = branches?.length - 1;
            if (!branches[lastIndex]?.branchID && !branches[lastIndex]?.roleID) {
                branches.splice(lastIndex, 1);
            }
        }
        const branchesValid =
            (branches?.length && branches.every((branch) => branch.branchID && branch.roleID)) ||
            false;

        let isDataValid = false;
        let inactiveScreenType: ScreenType = ScreenType.USER;
        let modalType: ModalType = ModalType.INACTIVE_SUBMISSION;
        let navigatePath = RoutePaths.ViewUsers;
        let isBranchAdded = false;

        if (isLicensor) {
            isDataValid = basicDetailsValid && !!roleID;
        } else if (isLicensee) {
            isDataValid = !!basicDetailsValid && !!licenseeID && !!roleID;
            if (displayData.length) {
                inactiveScreenType = ScreenType.INACTIVE_LICENSEE_USER_DENIED;
                modalType = ModalType.INACTIVE_LICENSEE_USER_DENIED;
                navigatePath = `/dashboard/${RoutePaths.EditLicensees}`;
            }
        } else if (isBranch) {
            isDataValid = basicDetailsValid && branchesValid;
            // !!branches?.length &&
            // !!branches[0]?.branchID &&
            // !!branches[0]?.roleID;
            if (displayData.length) {
                inactiveScreenType = ScreenType.INACTIVE_BRANCH_USER_DENIED;
                modalType = ModalType.INACTIVE_BRANCH_USER_DENIED;
                navigatePath = RoutePaths.ViewBranches;
            }

            if (!!branches?.length && !!branches[0]?.branchID && !!branches[0]?.roleID) {
                isBranchAdded = true;
            }
        }

        const isInactiveSubmissionWithData = !isActive && !!displayData.length && isDataValid;
        const isInactiveSubmissionWithoutData = !isActive && isDataValid;

        if (isInactiveSubmissionWithData) {
            setScreen(inactiveScreenType);
            setDataToDisplay(displayData);
            triggerShowModal(true, modalType);
            setActions({
                done: () => {
                    triggerShowModal(false, modalType);
                    setScreen(undefined);
                    setDataToDisplay([]);
                    navigate(navigatePath);
                    setIsDirty(false);
                    navigate(navigatePath, {
                        state: {
                            LicenseeId: displayData[0]?.id,
                            userId: user?.id,
                            userName: user?.name,
                        },
                    });
                },
                cancel: () => {
                    triggerShowModal(false, modalType);
                    setScreen(undefined);
                    setDataToDisplay([]);
                    setUserData({ ...userData, isActive: true });
                },
            });
        } else if (isInactiveSubmissionWithoutData) {
            setScreen(inactiveScreenType);
            setDataToDisplay(displayData);
            triggerShowModal(true, modalType);
            setActions({
                done: async () => {
                    await handleEditUser();
                    triggerShowModal(false, modalType);
                    setScreen(undefined);
                    setDataToDisplay([]);
                    navigate(navigatePath);
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, modalType);
                    setScreen(undefined);
                    setDataToDisplay([]);
                    setUserData({ ...userData, isActive: true });
                },
            });
        } else if (isDataValid) {
            triggerShowModal(true, ModalType.SUBMIT);
            const tempUserData = userData;
            if (tempUserData?.branches && tempUserData?.branches.length) {
                const lastBranchObj = tempUserData?.branches[tempUserData?.branches?.length - 1];
                if (!lastBranchObj?.branchID && !lastBranchObj?.roleID)
                    tempUserData?.branches?.splice(tempUserData?.branches?.length - 1, 1);
                setErrors({});
            }

            setActions({
                done: () => (user ? handleEditUser() : handleCreateUser()),
                cancel: () => {
                    triggerShowModal(false, ModalType.SUBMIT);
                    setUserData({ ...tempUserData, isActive: true });
                },
            });
        } else {
            const currentErrors: Partial<Errors> = {};
            if (!id) {
                currentErrors.id = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.USER.EMAIL.LABEL"),
                })}`;
            }
            if (!userLevel) {
                currentErrors.userLevel = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.USER.LEVEL.LABEL"),
                })}`;
            }
            if (!roleID) {
                currentErrors.roleID = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.USER.ROLE.LABEL"),
                })}`;
            }
            if (!licenseeID && isLicensee) {
                currentErrors.licenseeID = `${t("ERRORS.REQUIRED", {
                    name: t("ADD.USER.LICENSEE.LABEL"),
                })}`;
            }
            if (!isBranchAdded && isBranch) {
                currentErrors.branchRequired = `${t("ERRORS.REQUIRED", {
                    name: "Atleast one Branch",
                })}`;
            }
            setErrors({ ...errors, ...currentErrors });
        }
    };

    return (
        <FormWrapper
            heading={!!user ? t("ADD.USER.EDITHEADING") : t("ADD.USER.HEADING")}
            onCancelClick={handleCancel}
            onSubmitClick={handleSubmit}
            submitDisabled={!isDirty || featchingUser}
        >
            <UserForm
                data={userData}
                roles={userRoles}
                licensees={licensees}
                branches={branches}
                onChange={handleChange}
                onBlur={handleBlur}
                loadingUser={featchingUser}
                errors={errors}
                isEdit={!!user}
            />
        </FormWrapper>
    );
};

export default UserContainer;
