import { createContext, FC, ReactNode, useState } from "react";

interface FilterContextProviderProps {
    children: ReactNode;
}

interface FilterContextOutput {
    statusesFilter?: boolean[];
    countriesFilter?: string[];
    licenseesFilter?: string[];
    citiesFilter?: string[];
    machineMakesFilter?: string[];
    stationsFilter?: string[];
    machineTypesFilter?: string[];
    basketsFilter?: string[];
    userLevelsFilter?: string[];
    handleStatusesFilter: (_v: boolean) => void;
    handleCountriesFilter: (_v: string) => void;
    handleCitiesFilter: (_v: string) => void;
    handleLicenseesFilter: (_v: string) => void;
    handleStationsFilter: (_v: string) => void;
    handleUserLevelsFilter: (_v: string) => void;
    handleMachineMakesFilter: (_v: string) => void;
    handleMachineTypesFilter: (_v: string) => void;
    handleBasketsFilter: (_v: string) => void;
    setStatusesFilter: (_v?: boolean[]) => void;
    setCountriesFilter: (_v?: string[]) => void;
    setCitiesFilter: (_v?: string[]) => void;
    setLicenseesFilter: (_v?: string[]) => void;
    setMachineMakesFilter: (_v?: string[]) => void;
    setStationsFilter: (_v?: string[]) => void;
    setMachineTypesFilter: (_v?: string[]) => void;
    setBasketsFilter: (_v?: string[]) => void;
    setUserLevelsFilter: (_v?: string[]) => void;
    clearAll: () => void;
}

export const FilterContext = createContext({} as FilterContextOutput);

const FilterContextProvider: FC<FilterContextProviderProps> = ({ children }) => {
    const [statusesFilter, setStatusesFilter] = useState<boolean[]>();
    const [countriesFilter, setCountriesFilter] = useState<string[]>();
    const [citiesFilter, setCitiesFilter] = useState<string[]>();
    const [licenseesFilter, setLicenseesFilter] = useState<string[]>();
    const [machineMakesFilter, setMachineMakesFilter] = useState<string[]>();
    const [stationsFilter, setStationsFilter] = useState<string[]>();
    const [machineTypesFilter, setMachineTypesFilter] = useState<string[]>();
    const [basketsFilter, setBasketsFilter] = useState<string[]>();
    const [userLevelsFilter, setUserLevelsFilter] = useState<string[]>();

    const handleStatusesFilter = (value: boolean) => {
        const currentStatuses = statusesFilter;
        if (currentStatuses?.includes(value))
            setStatusesFilter([...(currentStatuses?.filter((v) => v != value) ?? [])]);
        else setStatusesFilter([...(currentStatuses ?? []), value]);
    };

    const handleCountriesFilter = (value: string) => {
        const currentCountries = countriesFilter;
        if (currentCountries?.includes(value))
            setCountriesFilter([...(currentCountries?.filter((v) => v != value) ?? [])]);
        else setCountriesFilter([...(currentCountries ?? []), value]);
    };

    const handleCitiesFilter = (value: string) => {
        const currentCities = citiesFilter;
        if (currentCities?.includes(value))
            setCitiesFilter([...(currentCities?.filter((v) => v != value) ?? [])]);
        else setCitiesFilter([...(currentCities ?? []), value]);
    };

    const handleLicenseesFilter = (value: string) => {
        const currentLicensees = licenseesFilter;
        if (currentLicensees?.includes(value))
            setLicenseesFilter([...(currentLicensees?.filter((v) => v != value) ?? [])]);
        else setLicenseesFilter([...(currentLicensees ?? []), value]);
    };

    const handleStationsFilter = (value: string) => {
        const currentStations = stationsFilter;
        if (currentStations?.includes(value))
            setStationsFilter([...(currentStations?.filter((v) => v != value) ?? [])]);
        else setStationsFilter([...(currentStations ?? []), value]);
    };

    const handleUserLevelsFilter = (value: string) => {
        const currentUserLevels = userLevelsFilter;
        if (currentUserLevels?.includes(value))
            setUserLevelsFilter([...(currentUserLevels?.filter((v) => v != value) ?? [])]);
        else setUserLevelsFilter([...(currentUserLevels ?? []), value]);
    };

    const handleMachineMakesFilter = (value: string) => {
        const currentMachineMakes = machineMakesFilter;
        if (currentMachineMakes?.includes(value))
            setMachineMakesFilter([...(currentMachineMakes?.filter((v) => v != value) ?? [])]);
        else setMachineMakesFilter([...(currentMachineMakes ?? []), value]);
    };

    const handleMachineTypesFilter = (value: string) => {
        const currentMachineTypes = machineTypesFilter;
        if (currentMachineTypes?.includes(value))
            setMachineTypesFilter([...(currentMachineTypes?.filter((v) => v != value) ?? [])]);
        else setMachineTypesFilter([...(currentMachineTypes ?? []), value]);
    };

    const handleBasketsFilter = (value: string) => {
        const currentBaskets = basketsFilter;
        if (currentBaskets?.includes(value))
            setBasketsFilter([...(currentBaskets?.filter((v) => v != value) ?? [])]);
        else setBasketsFilter([...(currentBaskets ?? []), value]);
    };

    const clearAll = () => {
        setStatusesFilter(undefined);
        setCountriesFilter(undefined);
        setCitiesFilter(undefined);
        setLicenseesFilter(undefined);
        setMachineMakesFilter(undefined);
        setStationsFilter(undefined);
        setMachineTypesFilter(undefined);
        setBasketsFilter(undefined);
        setUserLevelsFilter(undefined);
    };

    return (
        <FilterContext.Provider
            value={{
                statusesFilter,
                countriesFilter,
                citiesFilter,
                licenseesFilter,
                machineMakesFilter,
                stationsFilter,
                machineTypesFilter,
                basketsFilter,
                userLevelsFilter,
                handleStatusesFilter,
                handleCountriesFilter,
                handleCitiesFilter,
                handleLicenseesFilter,
                handleStationsFilter,
                handleUserLevelsFilter,
                handleMachineMakesFilter,
                handleMachineTypesFilter,
                handleBasketsFilter,
                setStatusesFilter,
                setCountriesFilter,
                setCitiesFilter,
                setLicenseesFilter,
                setMachineMakesFilter,
                setStationsFilter,
                setMachineTypesFilter,
                setBasketsFilter,
                setUserLevelsFilter,
                clearAll,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};

export default FilterContextProvider;
