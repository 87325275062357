import { t } from "i18next";
import { ChangeEvent, FC, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Icons from "@app/assets/Icons";
import LoadingButton from "@app/components/Button";
import { getImage, uploadImage } from "@app/services/Endpoints/ImageUploader";
import { Image } from "@app/types/Image.types";

import "./index.scss";

interface ImageUploadProps {
    onUpload?: (_imageId: string) => void;
    fetchImage?: string;
    isImageUploaded?: boolean;
    fetchImageName?: string;
    error?: string;
}

const ImageUpload: FC<ImageUploadProps> = ({
    onUpload,
    fetchImage,
    isImageUploaded,
    fetchImageName,
    error,
}) => {
    const [uploading, setUploading] = useState<boolean>(false);
    const [isPng, setIsPng] = useState<boolean>(true);
    const [uploadedImage, setUploadedImage] = useState<Image | undefined>(undefined);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleUploadClick = () => {
        inputRef?.current?.click();
    };

    const handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e?.target?.files?.[0];
        const isPNGImage = file?.name.endsWith(".png");
        if (file) {
            if (isPNGImage) {
                setIsPng(true);
                setUploading(true);
                const formData = new FormData();
                formData.append("file", file as Blob);
                uploadImage(formData)
                    .then((res) => {
                        const image = res.data.data;
                        setUploadedImage(image);
                        if (onUpload) {
                            onUpload(image.id);
                        }
                    })
                    .finally(() => {
                        setUploading(false);
                    });
            } else {
                setIsPng(false);
            }
        }
    };

    return (
        <Container className='image-upload'>
            <input
                accept='image/png'
                className='image-upload__input'
                ref={inputRef}
                type='file'
                onChange={handleSelectFile}
            />
            <Row>
                <Col>
                    <LoadingButton
                        label={fetchImage ? "Change" : uploadedImage ? "Change" : "Upload"}
                        onClick={handleUploadClick}
                        disabled={uploading}
                        variant='upload'
                    />
                    {error && (
                        <Row className='py-1'>
                            <div className='py-0 px-0 select-input__error'>
                                <img src={Icons.ErrorGreyIcon} /> <span>{error}</span>
                            </div>
                        </Row>
                    )}
                    {isImageUploaded && (
                        <span className='image-upload__png-image-validation'>
                            <img src={Icons.ErrorGreyIcon} /> {t("UPLOADIMAGE.REQUIRED")}
                        </span>
                    )}
                    <br />
                    {!isPng && (
                        <span className='image-upload__png-image-validation'>
                            <img src={Icons.ErrorGreyIcon} /> {t("UPLOADIMAGE.PNGALLOWED")}
                        </span>
                    )}
                </Col>
                {fetchImage ? (
                    <Col>
                        <img className='image-upload__uploaded-image' src={getImage(fetchImage)} />
                    </Col>
                ) : (
                    uploadedImage && (
                        <Col>
                            <img
                                className='image-upload__uploaded-image'
                                src={getImage(uploadedImage?.id)}
                            />
                        </Col>
                    )
                )}
            </Row>

            <Row>
                <Col sm={6} />
                <Col sm={6} className='image-upload__image-name'>
                    {uploadedImage ? uploadedImage?.name : fetchImageName}
                </Col>
            </Row>
        </Container>
    );
};

export default ImageUpload;
