import { t } from "i18next";
import { ChangeEvent, FC, useContext } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import Icons from "@app/assets/Icons";
import LoadingButton from "@app/components/Button";
import ImageUpload from "@app/components/ImageUpload";
import SelectInput from "@app/components/inputs/Select";
import TextInput from "@app/components/inputs/Text";
import ToggleComponent from "@app/components/inputs/Toggle";
import { Errors } from "@app/types/Common.types";
import {
    ItemFormData,
    ItemMachineDetails,
    ItemMachineList,
    ItemMachineRange,
} from "@app/types/Item";
import { AppContext } from "@app/utils/contexts/App.context";
import { FormContext } from "@app/utils/contexts/Form.context";

interface ItemFormProps {
    data: ItemFormData;
    onChange: (_name: string, _value: string | boolean | number) => void;
    itemCategory: { value: string; label: string }[];
    errors?: Partial<Errors>;
    isEdit?: boolean;
    addMachine: () => void;
    deleteMachine: (_id: string) => void;
    itemUnits: { value: string; label: string }[];
    cookingStations: { value: string; label: string }[];
    applicableMachines: { value: string; label: string }[];
    machinesList: ItemMachineList;
    addRange: (_machineClassId?: string) => void;
    deleteRange: (_minQuantity: string, _machineClassId: string) => void;
    addRangeList: ItemMachineRange[];
}

const ItemForm: FC<ItemFormProps> = ({
    data,
    onChange,
    errors,
    isEdit,
    addMachine,
    itemCategory,
    itemUnits,
    cookingStations,
    applicableMachines,
    addRange,
    machinesList,
    addRangeList,
    deleteRange,
    deleteMachine,
}) => {
    const { setIsDirty } = useContext(FormContext);
    const { userPermissions } = useContext(AppContext);

    const handleImageUpload = (id: string) => {
        onChange("imageId", id);
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setIsDirty(true);
        const { name, value } = e.target;
        if (name.includes("minQuantity") && parseInt(value) <= 0) {
            onChange(name, "");
        } else {
            onChange(name, value);
        }
    };

    const RenderUnits = () => (
        <Form.Label className='item-form--label'>
            {data.units === "0"
                ? t("ADD.ITEM.PIECE.LABEL")
                : data.units === "1"
                ? t("ADD.ITEM.MEAL.LABEL")
                : ""}
        </Form.Label>
    );
    const renderApplicableMachines = (item: ItemMachineDetails, index: string) => {
        const {
            machineConfigId,
            machineCapacity,
            cyclicCapacity,
            recoveryTime,
            resetTime,
            itemMachineTimeRange,
        } = item;
        return (
            <div className='item-form--borderBottom mb-4'>
                <Row className='mb-4 mt-4'>
                    <Col xs={6}>
                        <SelectInput
                            label={`${t("ADD.ITEM.APPLICABLEMACHINE.LABEL")}`}
                            value={machineConfigId}
                            onChange={handleChange}
                            name={machineConfigId}
                            options={[
                                { value: "", label: t("ADD.ITEM.APPLICABLEMACHINE.PLACEHOLDER") },
                                ...applicableMachines,
                            ]}
                            disabled
                        />
                    </Col>
                    <Col xs={3}>
                        <Form.Group className='px-2'>
                            <Form.Label className='text-input__label'>
                                {t("ADD.ITEM.CYCLICSTATUS.LABEL")}
                            </Form.Label>
                            <ToggleComponent
                                isActive={cyclicCapacity}
                                variant='on'
                                onToggle={() => {
                                    setIsDirty(true);
                                    onChange(
                                        `cyclicCapacity-${machineConfigId}:${index}`,
                                        !cyclicCapacity,
                                    );
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <LoadingButton
                            icon={<img src={Icons.DeleteIcon} />}
                            label='Delete'
                            variant='upload'
                            onClick={() => deleteMachine(machineConfigId)}
                            btnClass='viewHeader__add-button item-form--btn'
                        />
                    </Col>
                </Row>
                <Row className='mb-4'>
                    <Col xs={6} className='item-form--colContainer'>
                        <TextInput
                            type='number'
                            label={`${t("ADD.ITEM.CAPACITY.LABEL")}`}
                            placeholder={t("ADD.ITEM.CAPACITY.PLACEHOLDER")}
                            value={machineCapacity}
                            onChange={handleChange}
                            name={`machineCapacity-${machineConfigId}`}
                            error={errors && errors[`machineCapacity-${machineConfigId}`]}
                        />
                        {RenderUnits()}
                    </Col>
                    {cyclicCapacity ? (
                        <Col xs={6}>
                            <TextInput
                                type='duration'
                                label={`${t("ADD.ITEM.RESETTIME.LABEL")}`}
                                placeholder={t("ADD.ITEM.RESETTIME.PLACEHOLDER")}
                                value={resetTime ?? "00:00:00"}
                                onChange={handleChange}
                                name={`resetTime-${machineConfigId}`}
                                error={errors && errors[`resetTime-${machineConfigId}`]}
                            />
                        </Col>
                    ) : (
                        <Col xs={6}>
                            <TextInput
                                type='duration'
                                label={`${t("ADD.ITEM.RECOVERYTIME.LABEL")}`}
                                placeholder={t("ADD.ITEM.RECOVERYTIME.PLACEHOLDER")}
                                value={recoveryTime ?? "00:00:00"}
                                onChange={handleChange}
                                name={`recoveryTime-${machineConfigId}`}
                                error={errors && errors[`recoveryTime-${machineConfigId}`]}
                            />
                        </Col>
                    )}
                </Row>
                {/* <!--Render Machine Ranges--> */}
                {itemMachineTimeRange?.map((range, index) =>
                    renderRanges(range, machineConfigId, String(index), cyclicCapacity),
                )}
                {/* <!--Last Add Section --> */}
                {!cyclicCapacity && (
                    <Row className='mb-4'>
                        <Col xs={3} className='item-form--colContainer'>
                            <TextInput
                                type='number'
                                label={`${t("ADD.ITEM.QUANTITY.LABEL")}`}
                                placeholder='Min'
                                value={item?.minQuantity}
                                onChange={handleChange}
                                name={`minQuantity-${machineConfigId}`}
                                error={errors && errors[`minQuantity-${machineConfigId}`]}
                            />
                            {RenderUnits()}
                            <Form.Label className='item-form--label item-form--toLabel'>
                                To
                            </Form.Label>
                        </Col>
                        <Col xs={3} className='item-form--colContainer item-form--maxQuantity'>
                            <TextInput
                                type='number'
                                label={`${t("ADD.ITEM.QUANTITYMAX.LABEL")}`}
                                placeholder='Max'
                                value={item.maxQuantity}
                                onChange={handleChange}
                                name={`maxQuantity-${machineConfigId}`}
                                error={errors && errors[`maxQuantity-${machineConfigId}`]}
                            />
                            {RenderUnits()}
                        </Col>
                        <Col xs={3}>
                            <TextInput
                                type='duration'
                                label={`${t("ADD.ITEM.COOKINGTIME.LABEL")}`}
                                placeholder=''
                                value={item.cookingTime ?? "00:00:00"}
                                onChange={handleChange}
                                name={`cookingTime-${machineConfigId}`}
                                error={errors && errors[`cookingTime-${machineConfigId}`]}
                            />
                        </Col>
                        <Col xs={3}>
                            <LoadingButton
                                icon={<img src={Icons.PlusIcon} />}
                                label='Add Range'
                                variant='upload'
                                onClick={() => addRange(machineConfigId)}
                                btnClass='viewHeader__add-button item-form--btn'
                            />
                        </Col>
                    </Row>
                )}
            </div>
        );
    };

    const renderRanges = (
        range: ItemMachineRange,
        machineConfigId?: string,
        index?: string,
        cyclicCapacity?: boolean,
        showAddBtn?: boolean,
        hideDeleteBtn?: boolean,
        cyclicCapacitySecondField?: boolean,
    ) => {
        const minQuantity = machineConfigId
            ? `minQuantity-${machineConfigId}${index ? `:${index}` : ""}`
            : `minQuantity${index ? `:${index}` : ""}`;
        const maxQuantity = machineConfigId
            ? `maxQuantity-${machineConfigId}${index ? `:${index}` : ""}`
            : `maxQuantity${index ? `:${index}` : ""}`;
        const cookingTime = machineConfigId
            ? `cookingTime-${machineConfigId}${index ? `:${index}` : ""}`
            : `cookingTime${index ? `:${index}` : ""}`;

        return (
            <Row className='mb-4'>
                <Col xs={3} className='item-form--colContainer'>
                    <TextInput
                        type='number'
                        label={`${t("ADD.ITEM.QUANTITY.LABEL")}`}
                        placeholder='Min'
                        value={cyclicCapacitySecondField ? range[minQuantity] : range.minQuantity}
                        onChange={handleChange}
                        name={minQuantity}
                        error={errors && errors[minQuantity]}
                    />
                    {RenderUnits()}
                    <Form.Label className='item-form--label item-form--toLabel'>To</Form.Label>
                </Col>
                <Col xs={3} className='item-form--colContainer item-form--maxQuantity'>
                    <TextInput
                        type='number'
                        label={`${t("ADD.ITEM.QUANTITYMAX.LABEL")}`}
                        placeholder='Max'
                        value={cyclicCapacitySecondField ? range[maxQuantity] : range.maxQuantity}
                        onChange={handleChange}
                        error={errors && errors[maxQuantity]}
                        name={maxQuantity}
                    />
                    {RenderUnits()}
                </Col>
                <Col xs={3}>
                    <TextInput
                        type='duration'
                        label={`${t("ADD.ITEM.COOKINGTIME.LABEL")}`}
                        placeholder=''
                        value={
                            (cyclicCapacitySecondField ? range[cookingTime] : range.cookingTime) ??
                            "00:00:00"
                        }
                        onChange={handleChange}
                        name={cookingTime}
                        error={errors && errors[cookingTime]}
                    />
                </Col>
                {!cyclicCapacity && !hideDeleteBtn && (
                    <Col xs={3}>
                        <LoadingButton
                            icon={<img src={Icons.DeleteIcon} />}
                            label='Delete'
                            variant='upload'
                            onClick={() => deleteRange(minQuantity, machineConfigId || "")}
                            btnClass='viewHeader__add-button item-form--btn'
                        />
                    </Col>
                )}
                {showAddBtn && !cyclicCapacity && (
                    <Col xs={3}>
                        <LoadingButton
                            icon={<img src={Icons.PlusIcon} />}
                            label='Add Range'
                            variant='upload'
                            onClick={() => addRange()}
                            btnClass='viewHeader__add-button item-form--btn'
                        />
                    </Col>
                )}
                {cyclicCapacity && (
                    <Col xs={3} className='item-form--cycle'>
                        Cycle {index == "1" ? 2 : 1}
                    </Col>
                )}
            </Row>
        );
    };

    return (
        <Container fluid className='py-4 px-5'>
            <Row className='mb-4'>
                <Col xs={9}>
                    <TextInput
                        label={`${t("ADD.ITEM.NAME.LABEL")}`}
                        placeholder={t("ADD.ITEM.NAME.PLACEHOLDER")}
                        value={data.name}
                        onChange={handleChange}
                        name='name'
                        error={errors?.name}
                        disabled={isEdit}
                    />
                </Col>
                <Col xs={3}>
                    <TextInput
                        label={`${t("ADD.ITEM.CODE.LABEL")}`}
                        placeholder={t("ADD.ITEM.CODE.PLACEHOLDER")}
                        value={data.code}
                        onChange={handleChange}
                        name='code'
                        error={errors?.code}
                        disabled={isEdit}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <TextInput
                        label={`${t("ADD.ITEM.DISPLAYNAME.LABEL")}`}
                        placeholder={t("ADD.ITEM.DISPLAYNAME.PLACEHOLDER")}
                        value={data.displayName}
                        onChange={handleChange}
                        name='displayName'
                        error={errors?.displayName}
                    />
                </Col>
                <Col sm={6} className='py-0'>
                    <div>
                        <Form.Label className='text-input__label mt-0'>
                            {t("UPLOADIMAGE.UPLOAD_LABEL")}
                        </Form.Label>
                        <ImageUpload
                            onUpload={handleImageUpload}
                            fetchImage={data?.imageId}
                            error={errors?.imageId}
                            fetchImageName={data?.imageName}
                        />
                    </div>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col xs={6}>
                    <SelectInput
                        label={`${t("ADD.ITEM.CATEGORY.LABEL")}`}
                        value={String(data.itemCategory)}
                        onChange={handleChange}
                        name='itemCategory'
                        options={[
                            { value: "", label: t("ADD.ITEM.CATEGORY.PLACEHOLDER") },
                            ...itemCategory,
                        ]}
                        error={errors?.itemCategory}
                    />
                </Col>
                <Col xs={3}>
                    <SelectInput
                        label={`${t("ADD.ITEM.STATION.LABEL")}`}
                        value={data.cookingStationId}
                        onChange={handleChange}
                        name='cookingStationId'
                        options={[
                            { value: "", label: t("ADD.ITEM.STATION.PLACEHOLDER") },
                            ...cookingStations,
                        ]}
                        error={errors?.cookingStationId}
                    />
                </Col>
                <Col xs={3}>
                    <SelectInput
                        label={`${t("ADD.ITEM.UNITS.LABEL")}`}
                        value={data.units}
                        onChange={handleChange}
                        name='units'
                        options={[
                            { value: "", label: t("ADD.ITEM.UNITS.PLACEHOLDER") },
                            ...itemUnits,
                        ]}
                        error={errors?.units}
                    />
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col xs={3}>
                    <TextInput
                        type='duration'
                        label={`${t("ADD.ITEM.PREPTIME.LABEL")}`}
                        placeholder={t("ADD.ITEM.PREPTIME.PLACEHOLDER")}
                        value={data.preparationTime ?? "00:00:00"}
                        onChange={handleChange}
                        name='preparationTime'
                        error={errors?.preparationTime}
                    />
                </Col>
                <Col xs={3}>
                    <TextInput
                        type='duration'
                        label={`${t("ADD.ITEM.DRAINTIME.LABEL")}`}
                        placeholder={t("ADD.ITEM.DRAINTIME.PLACEHOLDER")}
                        value={data.drainageTime ?? "00:00:00"}
                        onChange={handleChange}
                        name='drainageTime'
                        error={errors?.drainageTime}
                    />
                </Col>
                <Col xs={3}>
                    <TextInput
                        type='duration'
                        label={`${t("ADD.ITEM.CRISPYTIME.LABEL")}`}
                        placeholder={t("ADD.ITEM.CRISPYTIME.PLACEHOLDER")}
                        value={data.crispyTime ?? "00:00:00"}
                        onChange={handleChange}
                        name='crispyTime'
                        error={errors?.crispyTime}
                    />
                </Col>
                <Col xs={3}>
                    <TextInput
                        type='duration'
                        label={`${t("ADD.ITEM.HOLDTIME.LABEL")}`}
                        placeholder={t("ADD.ITEM.HOLDTIME.PLACEHOLDER")}
                        value={data.holdingTime ?? "00:00:00"}
                        onChange={handleChange}
                        name='holdingTime'
                        error={errors?.holdingTime}
                    />
                </Col>
            </Row>
            <Row className='mb-4 item-form--mt40'>
                <Col xs={12}>
                    <div className='item-form--font500'>{t("ADD.ITEM.MACHINE_HEADING")}</div>
                </Col>
            </Row>
            {/* <!--Render Added Applicable Machines--> */}
            {Object.keys(machinesList)?.map((key: string, index: number) =>
                renderApplicableMachines(machinesList[key], index + ""),
            )}
            {/* <!--Render Machine Form Part--> */}
            <Row className='mb-4'>
                <Col xs={6}>
                    <SelectInput
                        label={`${t("ADD.ITEM.APPLICABLEMACHINE.LABEL")}`}
                        value={data.machineConfigId || ""}
                        onChange={handleChange}
                        name='machineConfigId'
                        options={[
                            { value: "", label: t("ADD.ITEM.APPLICABLEMACHINE.PLACEHOLDER") },
                            ...applicableMachines,
                        ]}
                        error={errors?.machineConfigId}
                    />
                </Col>
                <Col xs={3}>
                    <Form.Group className='px-2'>
                        <Form.Label className='text-input__label'>
                            {t("ADD.ITEM.CYCLICSTATUS.LABEL")}
                        </Form.Label>
                        <ToggleComponent
                            isActive={data?.cyclicCapacity || false}
                            variant='on'
                            onToggle={() => {
                                setIsDirty(true);
                                onChange("cyclicCapacity", !data.cyclicCapacity);
                            }}
                        />
                    </Form.Group>
                </Col>
                <Col xs={3}>
                    <LoadingButton
                        icon={<img src={Icons.PlusIcon} />}
                        label='Add Machine'
                        variant='upload'
                        onClick={addMachine}
                        btnClass='viewHeader__add-button item-form--btn'
                    />
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col xs={6} className='item-form--colContainer'>
                    <TextInput
                        type='number'
                        label={`${t("ADD.ITEM.CAPACITY.LABEL")}`}
                        placeholder={t("ADD.ITEM.CAPACITY.PLACEHOLDER")}
                        value={data.machineCapacity || ""}
                        onChange={handleChange}
                        name='machineCapacity'
                        error={errors?.machineCapacity}
                    />
                    {RenderUnits()}
                </Col>
                {data.cyclicCapacity ? (
                    <Col xs={6}>
                        <TextInput
                            type='duration'
                            label={`${t("ADD.ITEM.RESETTIME.LABEL")}`}
                            placeholder={t("ADD.ITEM.RESETTIME.PLACEHOLDER")}
                            value={data.resetTime ?? "00:00:00"}
                            onChange={handleChange}
                            name='resetTime'
                            error={errors?.resetTime}
                        />
                    </Col>
                ) : (
                    <Col xs={6}>
                        <TextInput
                            type='duration'
                            label={`${t("ADD.ITEM.RECOVERYTIME.LABEL")}`}
                            placeholder={t("ADD.ITEM.RECOVERYTIME.PLACEHOLDER")}
                            value={data.recoveryTime ?? "00:00:00"}
                            onChange={handleChange}
                            name='recoveryTime'
                            error={errors?.recoveryTime}
                        />
                    </Col>
                )}
            </Row>
            {/* <!--Render Added Ranges --> */}
            {addRangeList?.map((range, index) => renderRanges(range, "", String(index)))}
            {/* <!--Add Range Form--> */}
            {renderRanges(
                data as unknown as ItemMachineRange,
                "",
                "",
                data.cyclicCapacity,
                true,
                true,
            )}
            {/* <!--Range ROW For Cyclic Capacity--> */}
            {data.cyclicCapacity &&
                renderRanges(
                    data as unknown as ItemMachineRange,
                    "",
                    "1",
                    data.cyclicCapacity,
                    false,
                    true,
                    true,
                )}
            {isEdit && userPermissions?.Deactivate_Item && (
                <Row className='mt-4'>
                    <Col>
                        <Form.Group className='d-flex align-items-end gap-4'>
                            <Form.Label className='text-input__label'>
                                {t("MACHINE.CREATE.STATUS.LABEL")}
                            </Form.Label>
                            <ToggleComponent
                                isActive={data?.status}
                                onToggle={() => {
                                    setIsDirty(true);
                                    onChange("status", !data.status);
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default ItemForm;
