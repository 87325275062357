import { FC } from "react";

import Icons from "@app/assets/Icons";

import "./index.scss";

interface ToggleProps {
    isActive: boolean;
    onToggle?: (_val: boolean) => void;
    variant?: "on" | "active";
    isDisabled?: boolean;
}
const Toggle: FC<ToggleProps> = ({ isActive, onToggle, variant = "active", isDisabled }) => {
    const onClick = () => {
        onToggle?.(!isActive);
    };

    return (
        <div
            className={`${isActive ? "custom-toggle" : "custom-toggle--off"}${
                variant === "on" ? " custom-toggle--small" : ""
            }${isDisabled ? " custom-toggle--disabled" : ""}`}
            onClick={() => !isDisabled && onClick()}
        >
            {isActive ? (
                <>
                    <span className={"custom-toggle__on"}>
                        {variant === "on" ? "On" : "Active"}
                    </span>
                    <img className={"custom-toggle__icon-on"} src={Icons.ToggleTickIcon} />
                </>
            ) : (
                <>
                    <img className={"custom-toggle__icon-off"} src={Icons.ToggleCrossIcon} />
                    <span className={"custom-toggle__off"}>
                        {variant === "on" ? "Off" : "Inactive"}
                    </span>
                </>
            )}
        </div>
    );
};
export default Toggle;
