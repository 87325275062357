import { MasterAPIService } from "@app/services/Methods/api";
import { MachineMakeRespose, MachineTypeResponse } from "@app/types/API.types";
import { MachineFormData } from "@app/types/Machine";

export const getMachines = (
    sortBy?: string,
    sortOrder?: number,
    pageNumber?: number,
    pageSize?: number,
    name?: string,
    filters?: string,
) =>
    MasterAPIService.get(
        `/machine?${sortBy ? `sortBy=${sortBy}&` : ""}${
            sortOrder ? `sortOrder=${sortOrder}&` : ""
        }${pageNumber ? `pageNumber=${pageNumber}&` : ""}${
            pageSize ? `pageSize=${pageSize}&` : ""
        }${name ? `name=${name}&` : ""}${filters ? filters : ""}`,
    );

export const getMachineManufacturers = () =>
    MasterAPIService.get<MachineMakeRespose>("/machine/manufacturers");

export const getMachineTypes = () => MasterAPIService.get<MachineTypeResponse>("/machine/types");

export const getMachineStatus = () => MasterAPIService.get("/machine/status");

export const getMachineBaskets = () => MasterAPIService.get("/machine/no-of-baskets-list");

export const createMachine = (data: MachineFormData) =>
    MasterAPIService.post<MachineFormData>("/machine/create", data);

export const updateMachine = (id: string, data: Partial<MachineFormData>) =>
    MasterAPIService.patch<MachineFormData>(`/machine/${id}`, data);

export const getMachineItems = (machineId?: string) =>
    MasterAPIService.get(`/item/getItemsForView?MachineConfigId=${machineId}`);
