import { ReactElement } from "react";

import BranchFilters from "@app/components/filters/Branch.filter";
import CustomizationFilters from "@app/components/filters/Customization.filter";
import ItemFilters from "@app/components/filters/Item.filter";
import LicenseeFilter from "@app/components/filters/Licensee.filter";
import MachineFilters from "@app/components/filters/Machine.filter";
import UserFilters from "@app/components/filters/User.filter";
import { RouteNames } from "@app/routes/index";

const FiltersData = (pathname: string) => {
    const filterOptions = [
        {
            route: RouteNames.Licensees,
            filterComponent: <LicenseeFilter />,
        },
        {
            route: RouteNames.Branches,
            filterComponent: <BranchFilters />,
        },
        {
            route: RouteNames.Machines,
            filterComponent: <MachineFilters />,
        },
        {
            route: RouteNames.Customizations,
            filterComponent: <CustomizationFilters />,
        },
        {
            route: RouteNames.Items,
            filterComponent: <ItemFilters />,
        },
        {
            route: RouteNames.Products,
            filterComponent: <CustomizationFilters />,
        },
        {
            route: RouteNames.Roles,
            filterComponent: <UserFilters />,
        },
        {
            route: RouteNames.Users,
            filterComponent: <UserFilters />,
        },
    ];

    return (filterOptions.find((filter) => pathname?.includes(filter.route))?.filterComponent ??
        null) as ReactElement;
};

export default FiltersData;
