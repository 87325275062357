import { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import UserContainer from "@app/containers/user";
import { RoutePaths } from "@app/routes/index";
import { getAlbaikUser, getBranchUser, getLincenseeUser } from "@app/services/Endpoints/User.API";
import { User, UserLevel } from "@app/types/User.type";

const EditUserPage: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [userData, setUserData] = useState<User>();

    const getUserCallback = useCallback((id: string, userLevel: number) => {
        if (userLevel === UserLevel.Licensor) return getAlbaikUser(id);
        else if (userLevel === UserLevel.Licensee) return getLincenseeUser(id);
        else return getBranchUser(id);
    }, []);

    useEffect(() => {
        if (location.state?.userEditData) {
            const user = { ...location?.state?.userEditData };
            getUserCallback(user.id, user.userLevel).then((res) =>
                setUserData({ ...res.data.data, userLevel: user.userLevel }),
            );
        } else navigate(RoutePaths.ViewUsers);
    }, [location.state, navigate, getUserCallback]);

    return <UserContainer user={userData} />;
};

export default EditUserPage;
