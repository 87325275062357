import { t } from "i18next";
import { FC, ReactElement, useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Icons from "@app/assets/Icons";
import Avatar from "@app/components/Avatar";
import Header from "@app/components/Header";
import BootstrapModal from "@app/components/Modal";
import { ModalType, ScreenType } from "@app/types/Common.types";
import { AppContext } from "@app/utils/contexts/App.context";
import { FormContext } from "@app/utils/contexts/Form.context";
import { LicenseeContext } from "@app/utils/contexts/Licensee.context";
import useWindowSize from "@app/utils/hooks/useWindowSize";

import SidebarData from "./sidebarData";

import "./index.scss";

interface LayoutProps {
    children: ReactElement;
    titlePrimary: string;
    titleSecondary: string;
}

const Layout: FC<LayoutProps> = ({ children, titlePrimary, titleSecondary }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { width } = useWindowSize();
    const {
        actions,
        shouldOpenModal,
        modalType,
        isDirty,
        screen,
        setActions,
        triggerShowModal,
        setIsDirty,
        dataToDisplay,
    } = useContext(FormContext);

    const { appUser } = useContext(AppContext);
    const { selectedBranch } = useContext(LicenseeContext);
    const [openSidebar, setOpenSidebar] = useState<boolean>(true);

    const isTab = Number(width) < 1200;
    const isActivePath = (path: string) => pathname?.includes(path);
    const sidebarCollapsed = isTab ? openSidebar && isTab : !openSidebar;

    return (
        <div className='app-layout'>
            <Header
                primaryText={titlePrimary}
                secondaryText={titleSecondary}
                rightAction={
                    <div className='app-layout__actions'>
                        <Avatar
                            label={String(appUser?.name)}
                            email={appUser?.id}
                            labelStyleName='app-layout__label'
                            variant='logout'
                        />
                    </div>
                }
            />
            <div
                className={
                    sidebarCollapsed ? "app-layout__sidebar--collapsed" : "app-layout__sidebar"
                }
            >
                <div>
                    <div className='app-layout__sidebar-header'>
                        <img
                            src={Icons.DoubleBackIcon}
                            onClick={() => setOpenSidebar(!openSidebar)}
                            className={
                                sidebarCollapsed
                                    ? "app-layout__arrows--reverse"
                                    : "app-layout__arrows"
                            }
                        />
                    </div>
                    {SidebarData(selectedBranch).map(
                        (route) =>
                            !route.disableSideLink &&
                            route.label && (
                                <button
                                    key={route.label}
                                    className={
                                        isActivePath(`/licensee/dashboard/${route.path}`) &&
                                        !route.staticLink
                                            ? "app-layout__sidebar-link--active"
                                            : `app-layout__sidebar-link ${
                                                  route.staticLink
                                                      ? "py-3 my-4 pointer-disabled border-yellow"
                                                      : ""
                                              }`
                                    }
                                    onClick={
                                        !route.staticLink
                                            ? () => {
                                                  toast.dismiss();
                                                  if (isDirty) {
                                                      triggerShowModal(true, ModalType.CANCEL);
                                                      setActions({
                                                          done: () => {
                                                              navigate(
                                                                  `/licensee/dashboard/${route.path}`,
                                                              );
                                                              triggerShowModal(
                                                                  false,
                                                                  ModalType.CANCEL,
                                                              );
                                                              setIsDirty(false);
                                                          },
                                                          cancel: () => {
                                                              triggerShowModal(
                                                                  false,
                                                                  ModalType.CANCEL,
                                                              );
                                                          },
                                                      });
                                                  } else
                                                      navigate(`/licensee/dashboard/${route.path}`);
                                              }
                                            : undefined
                                    }
                                >
                                    <Avatar
                                        label={route.label}
                                        reverse
                                        customIconStyle={
                                            route.staticLink
                                                ? "app-layout__sidebar-icon--yellow"
                                                : "app-layout__sidebar-icon"
                                        }
                                        labelStyleName={`${
                                            isActivePath(`/dashboard/${route.path}`) &&
                                            !route.staticLink
                                                ? "app-layout__sidebar-label"
                                                : route.staticLink
                                                ? "app-layout__sidebar-label--yellow"
                                                : ""
                                        } ${
                                            sidebarCollapsed
                                                ? "app-layout__sidebar-label--hidden"
                                                : "app-layout__sidebar-label--shown"
                                        }`}
                                    />
                                </button>
                            ),
                    )}
                </div>
            </div>
            <div className='app-layout__wrapper'>{children}</div>
            <BootstrapModal
                header={t(`MODAL.${modalType}.HEADER`, {
                    name: t(`PAGES.${ScreenType[screen as ScreenType]}`),
                })}
                open={shouldOpenModal}
                onClose={() => actions.cancel?.()}
                showFooter
                doneCTALabel={`${t("COMMON.CANCEL")}`}
                cancelCTALabel={`${
                    screen && screen === ScreenType.INACTIVE_LICENSEE_USER_DENIED
                        ? t("COMMON.VIEW_LICENSEE")
                        : screen && dataToDisplay.length
                        ? t("COMMON.VIEW_ALL")
                        : t("COMMON.CONFIRM")
                }`}
                onDone={() => {
                    actions.done?.();
                }}
                onCancel={() => actions.cancel?.()}
            >
                <Container>
                    {(!screen || !dataToDisplay.length) && (
                        <Row>
                            <Col className='d-flex justify-content-center mt-2 mb-4'>
                                <img
                                    className='app-layout__modal-icon'
                                    src={Icons.ExclamationIcon}
                                />
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col sm={12}>
                            <big>
                                <p className='m-0 text-center'>
                                    {screen
                                        ? t(`MODAL.INACTIVE_SUBMISSION.BODY.${screen}`, {
                                              name: dataToDisplay[0]?.name,
                                          })
                                        : t(`MODAL.${modalType}.BODY`)}
                                </p>
                            </big>
                        </Col>
                        {screen && (
                            <Col sm={12} className='pt-3 d-flex  justify-content-center'>
                                <ul className='d-flex flex-column align-items-start'>
                                    {dataToDisplay?.map((data, index) => (
                                        <li key={index}>
                                            <p className='mb-1 text-center' key={data.id}>
                                                <big>{data.name}</big>
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        )}
                    </Row>
                </Container>
            </BootstrapModal>
        </div>
    );
};

export default Layout;
