import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CustomizationContainer from "@app/containers/customization";
import { RoutePaths } from "@app/routes/index";
import { Customization } from "@app/types/Customization.types";

const EditCustomizationPage: FC = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [editCustomisation, setEditCustomisation] = useState<Customization>();

    useEffect(() => {
        const { customisationEditData } = state || {};
        if (customisationEditData) {
            setEditCustomisation(customisationEditData);
        }
    }, [state]);

    useEffect(() => {
        if (!state?.customisationEditData) {
            navigate(RoutePaths.ViewCustomizations);
        }
    }, [state, navigate]);

    return <CustomizationContainer customization={editCustomisation} />;
};

export default EditCustomizationPage;
