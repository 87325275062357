import { FC } from "react";
import { Spinner } from "react-bootstrap";

import "./index.scss";

interface LoaderProps {
    size?: "sm" | "lg" | "xl";
}

const Loader: FC<LoaderProps> = ({ size = "xl" }) => (
    <div className='loader'>
        <Spinner className={`loader__icon loader__icon--${size}`} />
    </div>
);

export default Loader;
