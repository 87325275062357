/* eslint-disable autofix/no-unused-vars */
import { FC } from "react";
import { Col, Row } from "react-bootstrap";

import Icons from "@app/assets/Icons";
import { HeadersMapInterface } from "@app/types/Common.types";

import "./index.scss";

interface TableHeaderProps {
    headersMap: HeadersMapInterface;
    sortByHandler: (headerKey: string) => void;
    sortByObj: { sortBy: string; sortOrder: number };
    enableIconColumn: boolean;
}

const TableHeader: FC<TableHeaderProps> = ({
    headersMap,
    sortByObj,
    sortByHandler,
    enableIconColumn,
}) => (
    <Row className='table'>
        {Object.keys(headersMap)?.map((headerKey: string, index) => {
            const { label, layout, disableSort } = headersMap[headerKey];
            return (
                <Col key={headerKey} className='table__content' sm={layout.sm} lg={layout.lg}>
                    <span className={`${!index && "table__first-label"}`}>{label}</span>
                    {!disableSort &&
                        (headerKey === sortByObj.sortBy ? (
                            <img
                                className='table__asc-Desc-Arrow table__cursorPointer'
                                src={sortByObj.sortOrder ? Icons.DownArrowIcon : Icons.UpArrowIcon}
                                onClick={
                                    !disableSort && headerKey
                                        ? () => sortByHandler(headerKey)
                                        : () => null
                                }
                            />
                        ) : (
                            <img
                                className='table__cursorPointer table__doubleArrow'
                                src={Icons.DoubleArrowIcon}
                                onClick={
                                    !disableSort && headerKey
                                        ? () => sortByHandler(headerKey)
                                        : () => null
                                }
                            />
                        ))}
                </Col>
            );
        })}
        {enableIconColumn && (
            <Col
                className='table__icon-column'
                data-testid='icon-column'
                key='iconsColumn'
                sm='1'
                lg='1'
            />
        )}
    </Row>
);

export default TableHeader;
