import { ChangeEvent, FC, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import Icons from "@app/assets/Icons";
import LoadingButton from "@app/components/Button";
import Loader from "@app/components/Loader";
import BootstrapModal from "@app/components/Modal";
import Checkbox from "@app/components/inputs/Checkbox";
import SelectInput from "@app/components/inputs/Select";
import TextInput from "@app/components/inputs/Text";
import { RoutePaths } from "@app/routes/index";
import { getImage } from "@app/services/Endpoints/ImageUploader";
import { SelectProductErrors } from "@app/types/Common.types";
import { SelectProductData } from "@app/types/selectProduct.types";
import { AppContext } from "@app/utils/contexts/App.context";

interface SelectProductFormProps {
    headerName: string[];
    checked: string[];
    onClickHeaderCheckBox: () => void;
    onClickRowCheckBox: (_c: SelectProductData) => void;
    onChangeAssemblyStation: (_e: ChangeEvent<HTMLSelectElement>, _productId: string) => void;
    OnChangeInputFields: (_e: ChangeEvent<HTMLInputElement>, _productId: string) => void;
    OnClickCancel: () => void;
    OnClickSubmit: () => void;
    OnClickBack: () => void;
    submitDisabled?: boolean;
    error?: SelectProductErrors;
    assemblyStations: { value: string; label: string }[];
    selectedProducts: SelectProductData[];
}

const SelectProductForm: FC<SelectProductFormProps> = ({
    checked,
    onClickHeaderCheckBox,
    onClickRowCheckBox,
    headerName,
    OnChangeInputFields,
    OnClickCancel,
    submitDisabled,
    OnClickSubmit,
    OnClickBack,
    error,
    assemblyStations,
    selectedProducts,
    onChangeAssemblyStation,
}) => {
    const { isLoading, showLoader } = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();
    const { userPermissions } = useContext(AppContext);

    return (
        <Container fluid className='selectSalesChannel'>
            <Row>
                {error?.atLeastOneSelected && (
                    <div className='selectSalesChannel__error'>
                        <img src={Icons.ErrorGreyIcon} /> <span>{error.atLeastOneSelected}</span>
                    </div>
                )}
            </Row>
            <Row className='selectSalesChannel__header'>
                <Col
                    key='check-box'
                    className={"selectSalesChannel__header__content selectSalesChannel__column"}
                    md={1}
                    lg={1}
                >
                    <Checkbox
                        label={""}
                        checked={checked.length > 0 && checked?.length === selectedProducts?.length}
                        onClick={() => onClickHeaderCheckBox()}
                    />
                </Col>
                <Col
                    key={headerName[0]}
                    className={"selectSalesChannel__header__content"}
                    md={4}
                    lg={4}
                >
                    {headerName[0]}
                </Col>

                <Col
                    key={headerName[1]}
                    className={"selectSalesChannel__header__content"}
                    md={4}
                    lg={4}
                >
                    {headerName[1]}
                </Col>

                <Col
                    key={assemblyStations ? headerName[2] : headerName[1]}
                    className={"selectSalesChannel__header__content"}
                    md={assemblyStations ? 2 : 4}
                    lg={assemblyStations ? 2 : 4}
                >
                    {headerName[2]}
                </Col>
            </Row>
            <div className='selectSalesChannel__page'>
                {selectedProducts?.map((data, index) => (
                    <Row
                        className={`selectSalesChannel__rowWrap ${
                            index % 2 === 0 ? "selectSalesChannel__rowWrap__grey-bg" : ""
                        }`}
                        key={data.productId}
                    >
                        <Col className='selectSalesChannel__column' md={1} lg={1}>
                            <Checkbox
                                label={""}
                                checked={checked.includes(data.productId)}
                                onClick={() => onClickRowCheckBox(data)}
                            />
                        </Col>
                        <Col key={index} md={4} lg={4}>
                            <img
                                className='selectProducts__chicken-icon'
                                src={getImage(data.imageId)}
                            />
                            {data.productName}
                        </Col>

                        <Col xs={4} className='selectProducts__select-assembly-station'>
                            <SelectInput
                                label={""}
                                value={data.assemblyStationId}
                                onChange={(e) => onChangeAssemblyStation(e, data.productId)}
                                name='assemblyStationId'
                                options={[
                                    { value: "", label: "Select Assembly Station" },
                                    ...assemblyStations,
                                ]}
                                disabled={!checked.includes(data.productId)}
                            />
                        </Col>

                        <Col md={2} lg={2}>
                            <TextInput
                                key={data.productId}
                                placeholder={"Enter Time"}
                                value={data.assemblyCapacity != 0 ? data.assemblyCapacity : ""}
                                type='number'
                                onChange={(e) => OnChangeInputFields(e, data.productId)}
                                name={data.productId}
                                disabled={!checked.includes(data.productId)}
                                error={
                                    error?.assemblyCapacity.includes(data.productId)
                                        ? "Assembly capacity is required"
                                        : ""
                                }
                            />
                        </Col>
                    </Row>
                ))}
            </div>

            <Row className='selectSalesChannel__button-wrapper'>
                <Col sm={2}>
                    {(location.state?.NavigateViaMachine ||
                        location.state?.NavigateBackFromSalesChannel) && (
                        <LoadingButton
                            btnClass='activate-deactivate__modal-btn-wrapper--continue w-170 label-black'
                            label={"Back"}
                            variant='back'
                            onClick={() => {
                                OnClickBack();
                            }}
                        />
                    )}
                </Col>
                <Col sm={6} />
                <Col sm={2}>
                    <LoadingButton
                        btnClass='salechannel__modal-btn-wrapper--continue w-170 label-black'
                        label={"Cancel"}
                        variant='cancel'
                        disabled={submitDisabled || isLoading}
                        onClick={() => OnClickCancel()}
                    />
                </Col>
                <Col sm={2}>
                    {userPermissions && userPermissions["Create_Kitchen Setup"] && (
                        <LoadingButton
                            btnClass='btn_darkRed w-170 label-white'
                            label={
                                location.state?.NavigateViaMachine ||
                                location.state?.NavigateBackFromSalesChannel
                                    ? "Next"
                                    : "Submit"
                            }
                            variant='submit'
                            disabled={
                                location.state?.NavigateBackFromSalesChannel
                                    ? false
                                    : submitDisabled || isLoading
                            }
                            onClick={() => {
                                location.state?.NavigateBackFromSalesChannel
                                    ? !submitDisabled
                                        ? OnClickSubmit()
                                        : navigate(`/${RoutePaths.SelectSaleChannels}`, {
                                              state: {
                                                  NavigateViaSelectProduct:
                                                      "NavigateViaSelectProduct",
                                              },
                                          })
                                    : !submitDisabled
                                    ? OnClickSubmit()
                                    : navigate(`/${RoutePaths.SelectSaleChannels}`, {
                                          state: {
                                              NavigateViaSelectProduct: "NavigateViaSelectProduct",
                                          },
                                      });

                                // ? navigate(`/${RoutePaths.SelectSaleChannels}`, {
                                //       state: {
                                //           NavigateViaSelectProduct: "NavigateViaSelectProduct",
                                //       },
                                //   })
                                // : OnClickSubmit();
                            }}
                        />
                    )}
                </Col>
            </Row>
            {isLoading && (
                <BootstrapModal
                    contentClassName='form-wrapper__modal-body'
                    backdropClassName='form-wrapper__modal'
                    open
                    size='sm'
                >
                    {showLoader && <Loader />}
                </BootstrapModal>
            )}
        </Container>
    );
};

export default SelectProductForm;
