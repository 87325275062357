import { format, isBefore, parseISO, setHours, setMinutes, setSeconds } from "date-fns";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import Icons from "@app/assets/Icons";
import LoadingButton from "@app/components/Button";
import BootstrapModal from "@app/components/Modal";
import TextInput from "@app/components/inputs/Text";
import {
    changeBranchLicenseExpiryDate,
    createBranchLicense,
    getBranchLicenseeData,
    revokeBranchLicense,
} from "@app/services/Endpoints/BranchLicensee.API";
import { Branch, BranchLicense } from "@app/types/Branch.types";
import { ModalType } from "@app/types/Common.types";
import { FormContext } from "@app/utils/contexts/Form.context";

interface ManageLicenseProps {
    branch?: Branch;
    isOpen: boolean;
    onClose: () => void;
}

const initialData: Partial<BranchLicense> = {
    licenseKey: undefined,
    expiryDate: null,
    expiryTime: "00:00:00",
};

const ManageLicense: FC<ManageLicenseProps> = ({ branch, isOpen, onClose }) => {
    const { isDirty, triggerShowModal, setActions, setIsDirty } = useContext(FormContext);

    const [licenseData, setLicenseData] = useState<Partial<BranchLicense>>(initialData);
    const [dateError, setDateError] = useState<string>("");
    const [timeError, setTimeError] = useState<string>("");

    useEffect(() => {
        if (branch?.id && isOpen)
            getBranchLicenseeData(branch?.id)
                .then((res) => {
                    if (res?.data?.data) {
                        const { expiryDate = "" } = { ...res?.data?.data };
                        const expiryDateOnly = new Date(expiryDate);
                        setLicenseData({
                            ...res?.data?.data,
                            expiryDate: expiryDateOnly,
                            expiryTime: expiryDateOnly,
                        });
                    } else setLicenseData(initialData);
                })
                .catch(() => setLicenseData(initialData));
    }, [branch, isOpen]);

    const handleClose = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    onClose();
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        } else {
            onClose();
        }
    };

    const formatTimeToString = (val: string | null | undefined): string => {
        const isValidTimeFormat = (time: string | null | undefined) => {
            const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
            return timeFormatRegex.test(time ?? "");
        };

        let formattedTime: string;

        if (isValidTimeFormat(val)) {
            formattedTime = String(val);
        } else {
            formattedTime = format(new Date(val ?? ""), "HH:mm:ss");
        }
        return formattedTime;
    };

    const isDataValid = (): boolean => {
        const dateMissing = !licenseData.expiryDate;
        const timeMissing = !licenseData.expiryTime;
        if (dateMissing) {
            setDateError("Expiry date is mandatory.");
        }
        if (timeMissing) {
            setTimeError("Expiry time is mandatory.");
        }
        if (!dateMissing && !timeMissing) {
            const timeSelected = [
                ...(formatTimeToString(licenseData.expiryTime)?.split(":") ?? []),
            ];
            let dateSelected = new Date(licenseData.expiryDate ?? "");
            dateSelected = setHours(dateSelected, Number(timeSelected[0]));
            dateSelected = setMinutes(dateSelected, Number(timeSelected[1]));
            dateSelected = setSeconds(dateSelected, Number(timeSelected[2]));
            if (isBefore(dateSelected, new Date())) {
                setTimeError("Expiry time should be in future.");
                return false;
            }
            return true;
        }
        return false;
    };

    const handleGenerateNew = () => {
        setIsDirty(true);
        if (branch?.id && isDataValid() && licenseData.expiryDate) {
            const date = new Date(licenseData.expiryDate).toISOString();
            const formattedTime = formatTimeToString(licenseData.expiryTime);
            triggerShowModal(true, ModalType.SUBMIT);
            setActions({
                done: () =>
                    createBranchLicense(
                        branch?.id,
                        format(parseISO(date), "yyyy-MM-dd"),
                        formattedTime ?? "",
                    )
                        .then((res) => {
                            setIsDirty(false);
                            triggerShowModal(false, ModalType.SUBMIT);
                            const { expiryDate = "" } = { ...res?.data?.data };
                            const expiryDateOnly = new Date(expiryDate);
                            setLicenseData({
                                ...res?.data?.data,
                                expiryDate: expiryDateOnly,
                                expiryTime: expiryDateOnly,
                            });
                            onClose();
                        })
                        .catch(() => setLicenseData({ ...licenseData })),
                cancel: () => triggerShowModal(false, ModalType.SUBMIT),
            });
        }
    };

    const handleExtendExpiry = () => {
        setIsDirty(true);
        if (branch?.id && isDataValid() && licenseData.expiryDate) {
            const date = new Date(licenseData.expiryDate).toISOString();
            const formattedTime = formatTimeToString(licenseData.expiryTime);
            triggerShowModal(true, ModalType.SUBMIT);
            setActions({
                done: () =>
                    changeBranchLicenseExpiryDate(
                        branch?.id,
                        format(parseISO(date), "yyyy-MM-dd"),
                        formattedTime ?? "",
                    )
                        .then((res) => {
                            setIsDirty(false);
                            triggerShowModal(false, ModalType.SUBMIT);
                            const { expiryDate = "" } = { ...res?.data?.data };
                            const expiryDateOnly = new Date(expiryDate);
                            setLicenseData({
                                ...res?.data?.data,
                                expiryDate: expiryDateOnly,
                                expiryTime: expiryDateOnly,
                            });
                            onClose();
                        })
                        .catch(() => {
                            setLicenseData({ ...licenseData });
                        }),
                cancel: () => triggerShowModal(false, ModalType.SUBMIT),
            });
        }
    };

    const handleRevokeLicense = () => {
        if (branch?.id) {
            setIsDirty(true);
            triggerShowModal(true, ModalType.SUBMIT);
            setActions({
                done: () =>
                    revokeBranchLicense(branch?.id).then((res) => {
                        setIsDirty(false);
                        triggerShowModal(false, ModalType.SUBMIT);
                        const { expiryDate = "" } = { ...res?.data?.data };
                        const expiryDateOnly = new Date(expiryDate);
                        setLicenseData({
                            ...res?.data?.data,
                            expiryDate: expiryDateOnly,
                            expiryTime: expiryDateOnly,
                        });
                        onClose();
                    }),

                cancel: () => triggerShowModal(false, ModalType.SUBMIT),
            });
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(String(licenseData?.licenseKey));
        toast.success("Key copied to clipboard");
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLicenseData({
            ...licenseData,
            [`${e.target.name}`]:
                e.target.name === "expiryDate"
                    ? e.target.value
                        ? new Date(e.target.value).toString()
                        : e.target.value
                    : e.target.value,
        });
        setIsDirty(true);
        setDateError("");
        setTimeError("");
    };
    const formatLicenseKey = (key: string) => {
        if (!key) return "";
        return key.replace(/(\d{4})(?=\d)/g, "$1-");
    };

    return (
        <BootstrapModal header='Manage License' open={isOpen} onClose={handleClose}>
            <Container>
                <Row className='add-branch__licenseKeyField'>
                    <TextInput
                        type='number'
                        label='License Key'
                        placeholder='xxxx-xxxx-xxxx-xxxx'
                        value={
                            licenseData?.licenseKey
                                ? formatLicenseKey(String(licenseData?.licenseKey))
                                : null
                        }
                        disabled={!!licenseData?.licenseKey}
                        error={
                            licenseData.licenseKey && !licenseData.active
                                ? "License Key Revoked"
                                : undefined
                        }
                    />
                    {licenseData.licenseKey && !!licenseData.active && (
                        <img
                            className='add-branch__copyIcon'
                            src={Icons.copyIcon}
                            onClick={handleCopy}
                        />
                    )}
                </Row>
                <Row className='mt-4'>
                    <TextInput
                        type='date'
                        label='Expiry Date'
                        placeholder='dd/mm/yyyy'
                        value={licenseData?.expiryDate as string | number | null}
                        onChange={handleChange}
                        name='expiryDate'
                        error={dateError}
                    />
                </Row>
                <Row className='mt-4'>
                    <TextInput
                        type='time'
                        label='Expiry Time'
                        placeholder=''
                        value={licenseData?.expiryTime as string | number | null}
                        onChange={handleChange}
                        name='expiryTime'
                        error={timeError}
                    />
                </Row>
                <Row className='add-branch__manageLicenseBtns'>
                    <LoadingButton
                        btnClass='add-branch__loading-button'
                        variant='cancel'
                        label='Revoke'
                        disabled={!licenseData.licenseKey || !licenseData.active}
                        onClick={handleRevokeLicense}
                    />
                    <LoadingButton
                        btnClass='add-branch__loading-button'
                        variant='submit'
                        label='Change Expiry'
                        onClick={handleExtendExpiry}
                        disabled={!licenseData?.licenseKey || !isDirty}
                    />
                    <LoadingButton
                        btnClass='add-branch__loading-button'
                        variant='add'
                        label='Generate New'
                        onClick={handleGenerateNew}
                    />
                </Row>
            </Container>
        </BootstrapModal>
    );
};

export default ManageLicense;
