import { t } from "i18next";
import { ChangeEvent, FC, useContext } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import Icons from "@app/assets/Icons";
import Checkbox from "@app/components/inputs/Checkbox";
import SelectInput from "@app/components/inputs/Select";
import TextInput from "@app/components/inputs/Text";
import Toggle from "@app/components/inputs/Toggle";
import { Errors } from "@app/types/Common.types";
import { MachineFormData } from "@app/types/Machine";
import { Station } from "@app/types/Station.types";
import { AppContext } from "@app/utils/contexts/App.context";
import { FormContext } from "@app/utils/contexts/Form.context";

interface MachineFormProps {
    data: MachineFormData;
    onChange: (_name: string, _value: string | string[] | number) => void;
    machineMakeOptions: { value: string; label: string }[];
    machineTypeOptions: { value: string; label: string }[];
    stations: Station[];
    errors?: Partial<Errors>;
    isEdit?: boolean;
}

const MachineForm: FC<MachineFormProps> = ({
    data,
    onChange,
    machineMakeOptions,
    machineTypeOptions,
    stations,
    errors,
    isEdit,
}) => {
    const { setIsDirty } = useContext(FormContext);
    const { userPermissions } = useContext(AppContext);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setIsDirty(true);
        const { name, value } = e.target;
        onChange(name, value);
    };

    const handleCheckboxClick = (stationId: string) => {
        setIsDirty(true);
        if (data.applicableStations.includes(stationId))
            onChange(
                "applicableStations",
                data.applicableStations?.filter((id) => id !== stationId),
            );
        else onChange("applicableStations", [...data.applicableStations, stationId]);
    };

    return (
        <Container fluid className='py-4 px-5'>
            <Row className='mb-5'>
                <Col xs={12}>
                    <SelectInput
                        label={t("MACHINE.CREATE.MAKE.LABEL")}
                        value={data?.machineMake}
                        onChange={handleChange}
                        name='machineMake'
                        options={[
                            { value: "", label: t("MACHINE.CREATE.MAKE.PLACEHOLDER") },
                            ...machineMakeOptions,
                            {
                                value: "new machine manufacturer",
                                label: t("MACHINE.CREATE.MAKE.NEW"),
                            },
                        ]}
                        error={errors?.machineMake}
                        disabled={isEdit}
                    />
                </Col>
                {data.machineMake === "new machine manufacturer" && (
                    <Col xs={12} className='mt-4'>
                        <TextInput
                            placeholder={t("MACHINE.CREATE.MAKE.NEW_PLACEHOLDER")}
                            value={data?.newMachineManufacturer ? data?.newMachineManufacturer : ""}
                            onChange={handleChange}
                            name='newMachineManufacturer'
                            error={errors?.newMachineManufacturer}
                        />
                    </Col>
                )}
            </Row>
            <Row className='mb-5'>
                <Col xs={6}>
                    <TextInput
                        label={`${t("MACHINE.CREATE.NAME.LABEL")}`}
                        placeholder={t("MACHINE.CREATE.NAME.PLACEHOLDER")}
                        value={data?.machineName}
                        onChange={handleChange}
                        name='machineName'
                        error={errors?.machineName}
                        disabled={isEdit}
                    />
                </Col>
                <Col xs={6} />
            </Row>
            <Row className='mb-5'>
                <Col xs={6}>
                    <TextInput
                        label={`${t("MACHINE.CREATE.BASKET.LABEL")}`}
                        placeholder={t("MACHINE.CREATE.BASKET.PLACEHOLDER")}
                        value={String(data?.noOfBaskets)}
                        onChange={handleChange}
                        name='noOfBaskets'
                        error={errors?.noOfBaskets}
                        type='number'
                    />
                </Col>
                <Col xs={6}>
                    <SelectInput
                        label={t("MACHINE.CREATE.TYPE.LABEL")}
                        value={data?.machineType}
                        onChange={handleChange}
                        name='machineType'
                        options={[
                            { value: "", label: t("MACHINE.CREATE.TYPE.PLACEHOLDER") },
                            ...machineTypeOptions,
                        ]}
                        error={errors?.machineType}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label className='text-input__label'>
                            {t("MACHINE.CREATE.STATION.LABEL")}
                        </Form.Label>
                        <Row className='mt-2'>
                            {stations.map((station) => (
                                <Col className='mb-2' key={station.stationId}>
                                    <Checkbox
                                        checked={data.applicableStations.includes(
                                            station.displayName,
                                        )}
                                        label={station.displayName}
                                        onClick={() => handleCheckboxClick(station.displayName)}
                                    />
                                </Col>
                            ))}
                        </Row>
                        {errors?.applicableStations && (
                            <Form.Text className='text-input__error'>
                                <img src={Icons.ErrorGreyIcon} />{" "}
                                <span>{errors.applicableStations}</span>
                            </Form.Text>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            {isEdit && userPermissions?.Deactivate_Machine && (
                <Row className='mt-4'>
                    <Col>
                        <Form.Group>
                            <Form.Label className='text-input__label'>
                                {t("MACHINE.CREATE.STATUS.LABEL")}
                            </Form.Label>
                            <Toggle
                                isActive={data?.status === 1}
                                onToggle={() => {
                                    setIsDirty(true);
                                    onChange("status", data.status === 1 ? 2 : 1);
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default MachineForm;
