export enum PermisionNames {
    // eslint-disable-next-line
    CREATE = "C",
    // eslint-disable-next-line
    READ = "R",
    // eslint-disable-next-line
    EDIT = "E",
    // eslint-disable-next-line
    DEACTIVATE = "D",
}

export enum UserLevel {
    // eslint-disable-next-line
    None = 0,
    // eslint-disable-next-line
    Licensor = 1,
    // eslint-disable-next-line
    Licensee = 2,
    // eslint-disable-next-line
    Branch = 4,
}

export enum FeatureName {
    // eslint-disable-next-line
    BRANCH = "BRANCH",
    // eslint-disable-next-line
    CUSTOMIZATION = "CUSTOMIZATION",
    // eslint-disable-next-line
    ITEM = "ITEM",
    // eslint-disable-next-line
    LICENSEE = "LICENSEE",
    // eslint-disable-next-line
    MACHINE = "MACHINE",
    // eslint-disable-next-line
    PRODUCT = "PRODUCT",
    // eslint-disable-next-line
    USER = "USER",
    // eslint-disable-next-line
}

export interface Permission {
    displayName: string;
    id: string;
    isAllowed: boolean;
    name: PermisionNames;
    permisionID: string;
}

export interface Feature {
    displayName: string;
    id: string;
    name: FeatureName;
    permissions: Partial<Permission>[];
    userLevel: UserLevel;
}

export interface Role {
    sequenceNo: number;
    id: string;
    name: string;
    userLevel: UserLevel;
    isActive: boolean;
    features?: Partial<Feature>[];
    isSystemRole?: boolean;
}

export interface RoleFormFeatures {
    [key: string]: {
        [key: string]: {
            id: string;
            isAllowed: boolean;
            isDisabled: boolean;
        };
    };
}

export interface RoleFormData {
    name: string;
    userLevel: UserLevel;
    isActive: boolean;
    features: RoleFormFeatures;
}

export const rolesEnum = {
    0: "",
    1: "Albaik",
    2: "Licensee",
    4: "Branch",
};

export interface UserDetails {
    displayName: string;
    mail: string;
    mobilePhone?: string;
    roleId?: string;
    id: string;
    jobTitle: string | null;
}

export interface UserBranch {
    branchID: string;
    roleID: string;
}

export interface User {
    id: string;
    name: string;
    phoneNumber: string | null;
    role?: Role;
    userLevel: UserLevel;
    phoneNo?: string;
    isActive: boolean;
    roleID?: string;
    licenseeID?: string;
    branches?: UserBranch[];
    roleId?: string;
}
