import { ChangeEvent, FC } from "react";
import { Form } from "react-bootstrap";

import "./index.scss";
import Icons from "@app/assets/Icons";

interface SelectInputProps {
    label: string;
    value?: string;
    onChange?: (_e: ChangeEvent<HTMLSelectElement>) => void;
    name: string;
    options: { value: string; label: string; key?: string }[];
    error?: string;
    disabled?: boolean;
}

const SelectInput: FC<SelectInputProps> = ({
    label,
    value,
    onChange,
    name,
    error,
    options,
    disabled,
}) => (
    <Form.Group className='select-input'>
        <Form.Label className='select-input__label'>{label}</Form.Label>
        <Form.Select
            value={value}
            onChange={onChange}
            name={name}
            className='select-input__input'
            disabled={disabled}
            autoComplete='off'
        >
            {options.map((opt, index) => (
                <option key={opt.key ?? opt.value} value={opt.value} disabled={!index}>
                    {opt.label}
                </option>
            ))}
        </Form.Select>
        {error && (
            <Form.Text className='select-input__error'>
                <img src={Icons.ErrorGreyIcon} /> <span>{error}</span>
            </Form.Text>
        )}
    </Form.Group>
);

export default SelectInput;
