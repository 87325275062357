import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import { toast } from "react-toastify";

import { ResponseTypes } from "@app/types/Common.types";
import { generateErrorMessage, shouldAutoClose } from "@app/utils/errorsHelpers";

export const msalConfig = {
    auth: {
        clientId: `${process.env.REACT_APP_MSAL_CLIENT_ID}`,
        authority: process.env.REACT_APP_MSAL_AUTHORITY_ID,
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};

export const MSALInstance = new PublicClientApplication(msalConfig);

export const MasterAPIService = axios.create({
    baseURL: `${process.env.REACT_APP_MASTER_API_URL}/${process.env.REACT_APP_ENV}/api`,
    timeout: 60000,
});

export const OperationalAPIService = axios.create({
    baseURL: `${process.env.REACT_APP_OPERATIONAL_API_URL}/${process.env.REACT_APP_ENV}/api`,
    timeout: 60000,
});

export const TransactionalAPIService = axios.create({
    baseURL: `${process.env.REACT_APP_TRANSACTIONAL_API_URL}/${process.env.REACT_APP_ENV}/api`,
    timeout: 60000,
});

export const MSGraphAPIService = axios.create({
    baseURL: `${process.env.REACT_APP_MSAL_GRAPH_API_URL}`,
    timeout: 60000,
});

MSGraphAPIService.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user-token")}`;
    return config;
});

MasterAPIService.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("server-token")}`;
    return config;
});

MSGraphAPIService.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            localStorage.clear();
            location.reload();
        }
        return Promise.reject(error.response);
    },
);

OperationalAPIService.interceptors.request.use((config) => {
    const branch = JSON.parse(localStorage.getItem("SelectedBranch") || "");
    config.headers.Authorization = `Bearer ${localStorage.getItem("server-token")}`;
    config.headers.branchId = branch.id;
    return config;
});

MasterAPIService.interceptors.response.use(
    (response) => {
        const messageCode = response.data.message_code;
        if (
            messageCode.includes(ResponseTypes.CREATED) ||
            messageCode.includes(ResponseTypes.UPDATED)
        )
            toast.success(response.data.message, {
                autoClose: shouldAutoClose(messageCode),
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
            });
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/";
        }
        const messageCode = error?.response?.data?.message_code;
        const messages = error?.response?.data?.message;
        if (
            messageCode === ResponseTypes.INVALID_INPUT ||
            messageCode === ResponseTypes.INVALID_REQUEST ||
            messageCode === ResponseTypes.DUPLICATE_ENTITY ||
            messageCode === ResponseTypes.UNAUTHORIZED ||
            messageCode === ResponseTypes.INVALID_ROLE
        )
            toast.error(generateErrorMessage(messages), {
                autoClose: shouldAutoClose(messageCode),
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
            });

        return Promise.reject(error.response);
    },
);

OperationalAPIService.interceptors.request.use((config) => {
    const branch = JSON.parse(localStorage.getItem("SelectedBranch") || "");
    config.headers.Authorization = `Bearer ${localStorage.getItem("server-token")}`;
    config.headers.branchId = branch.id;
    return config;
});

OperationalAPIService.interceptors.response.use(
    (response) => {
        const messageCode = response.data.message_code;
        if (
            messageCode.includes(ResponseTypes.CREATED) ||
            messageCode.includes(ResponseTypes.UPDATED) ||
            messageCode.includes(ResponseTypes.TANJARA_TRAY_ADDED) ||
            messageCode.includes(ResponseTypes.MACHINE_DELETED)
        )
            toast.success(response.data.message, {
                autoClose: shouldAutoClose(messageCode),
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
            });
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/";
        }
        const messageCode = error?.response?.data?.message_code;
        const messages = error?.response?.data?.message;
        if (
            messageCode === ResponseTypes.INVALID_INPUT ||
            messageCode === ResponseTypes.INVALID_REQUEST ||
            messageCode === ResponseTypes.DUPLICATE_ENTITY ||
            messageCode === ResponseTypes.UNAUTHORIZED ||
            messageCode === ResponseTypes.PRODUCT_NOT_FOUND ||
            messageCode === ResponseTypes.INVALID_ROLE ||
            messageCode === ResponseTypes.MACHINE_NOT_DELETED
        )
            toast.error(generateErrorMessage(messages), {
                autoClose: shouldAutoClose(messageCode),
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
            });
        return Promise.reject(error.response);
    },
);
