import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import LicenseeContainer from "@app/containers/licensee";
import { RoutePaths } from "@app/routes/index";
import { getLicenseeByID } from "@app/services/Endpoints/Licensee.API";
// import { Licensee } from "@app/types/Licensee";

const EditLicenseePage: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [licensee, setLicensee] = useState();

    useEffect(() => {
        if (!location.state?.LicenseeId) {
            navigate(RoutePaths.ViewLicensees);
        } else {
            getLicenseeByID(location.state?.LicenseeId)
                .then((res) => {
                    console.log("res", res?.data.data);
                    setLicensee(res.data.data);
                })
                .catch((err) => console.error(err));
        }
    }, [location.state, navigate]);

    return <LicenseeContainer licensee={licensee} />;
};

export default EditLicenseePage;
