import { t } from "i18next";
import { ChangeEvent, FC, useContext } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import Icons from "@app/assets/Icons";
import SelectInput from "@app/components/inputs/Select";
import TextInput from "@app/components/inputs/Text";
import Toggle from "@app/components/inputs/Toggle";
import { Errors } from "@app/types/Common.types";
import { Feature, PermisionNames, RoleFormData, UserLevel } from "@app/types/User.type";
import { FormContext } from "@app/utils/contexts/Form.context";

import "./index.scss";

interface UserRoleFormProps {
    data: RoleFormData;
    features: Feature[];
    onChange: (_name: string, _value: string | boolean) => void;
    onToggle: (
        _featureId: string,
        _permissionId: string,
        _permissionName: PermisionNames,
        _val: boolean,
    ) => void;
    errors?: Partial<Errors>;
    isEdit?: boolean;
}

const UserRoleForm: FC<UserRoleFormProps> = ({
    data,
    onChange,
    errors,
    features,
    onToggle,
    isEdit,
}) => {
    const { setIsDirty } = useContext(FormContext);

    const userLevelOptions = Object.values(UserLevel)
        .map((key, index, array) => {
            if (typeof key === "string") {
                return {
                    value: String(array[array.length / 2 + index]),
                    label: t(`USER_ROLE.LEVEL.${key.toUpperCase()}`),
                };
            }
        })
        .filter(Boolean) as { value: string; label: string }[];

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setIsDirty(true);
        const { name, value } = e.target;
        onChange(name, value);
    };

    const renderSpecificiedPermission = (
        permissionType: string,
        permissionName: PermisionNames,
        feature: Feature,
    ) => {
        const selectedPermission = feature?.permissions?.find(
            (permission) => permission.name === permissionName,
        );

        const permissionId = String(selectedPermission?.id);
        const selectedFormFeature = data?.features[`${feature?.id}`];
        const selectedFormPermission = selectedFormFeature?.[`${permissionId}`];

        return selectedPermission ? (
            <Col
                className='d-flex justify-content-center py-3'
                xs={2}
                id={permissionType}
                key={permissionId}
            >
                <Toggle
                    variant='on'
                    isActive={selectedFormPermission?.isAllowed}
                    onToggle={() => {
                        setIsDirty(true);
                        onToggle(
                            feature.id,
                            permissionId,
                            permissionName,
                            !selectedFormPermission?.isAllowed,
                        );
                    }}
                    isDisabled={selectedFormPermission?.isDisabled}
                />
            </Col>
        ) : (
            <Col xs={2} id={permissionType} className='d-flex justify-content-center py-3'>
                <Toggle variant='on' isActive={selectedFormPermission?.isAllowed} isDisabled />
            </Col>
        );
    };

    return (
        <Container fluid className='py-4 px-5'>
            <Row className='mb-5'>
                <Col xs={6}>
                    <TextInput
                        label={`${t("USER_ROLE.ROLE.LABEL")}`}
                        placeholder={t("USER_ROLE.ROLE.PLACEHOLDER")}
                        value={data?.name}
                        onChange={handleChange}
                        name='name'
                        error={errors?.name}
                        disabled={isEdit}
                    />
                </Col>
                <Col xs={6}>
                    <SelectInput
                        label={t("USER_ROLE.LEVEL.LABEL")}
                        value={String(data?.userLevel)}
                        onChange={handleChange}
                        name='userLevel'
                        options={userLevelOptions}
                        error={errors?.userLevel}
                    />
                </Col>
            </Row>
            {!!features?.length && (
                <Row>
                    <Container fluid>
                        <Row className='mb-4'>
                            <Col xs={4} className='role-form__header'>
                                {t("USER_ROLE.ACCESS.LABEL")}
                            </Col>
                            <Col className='text-center role-form__header' xs={2}>
                                {t("USER_ROLE.ACCESS.CREATE")}
                            </Col>
                            <Col className='text-center role-form__header' xs={2}>
                                {t("USER_ROLE.ACCESS.READ")}
                            </Col>
                            <Col className='text-center role-form__header' xs={2}>
                                {t("USER_ROLE.ACCESS.EDIT")}
                            </Col>
                            <Col className='text-center role-form__header' xs={2}>
                                {t("USER_ROLE.ACCESS.DEACTIVATE")}
                            </Col>
                        </Row>
                        {features?.map((feature) => (
                            <Row key={feature.id}>
                                <Col xs={4} className='d-flex align-items-center'>
                                    {feature.displayName}
                                </Col>
                                {renderSpecificiedPermission(
                                    "CREATE",
                                    PermisionNames.CREATE,
                                    feature,
                                )}
                                {renderSpecificiedPermission("READ", PermisionNames.READ, feature)}
                                {renderSpecificiedPermission("EDIT", PermisionNames.EDIT, feature)}
                                {renderSpecificiedPermission(
                                    "DEACTIVATE",
                                    PermisionNames.DEACTIVATE,
                                    feature,
                                )}
                            </Row>
                        ))}
                    </Container>
                </Row>
            )}
            {errors?.features && (
                <Form.Text className='select-input__error'>
                    <img src={Icons.ErrorGreyIcon} /> <span>{errors?.features}</span>
                </Form.Text>
            )}
            {isEdit && (
                <Row className='mt-4'>
                    <Col>
                        <Form.Group>
                            <Form.Label className='text-input__label'>
                                {t("MACHINE.CREATE.STATUS.LABEL")}
                            </Form.Label>
                            <Toggle
                                isActive={data?.isActive}
                                onToggle={() => {
                                    setIsDirty(true);
                                    onChange("isActive", !data?.isActive);
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default UserRoleForm;
