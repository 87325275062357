import { FC, ReactElement, SyntheticEvent } from "react";
import { Button } from "react-bootstrap";

import Loader from "@app/components/Loader";

import "./index.scss";

interface LoadingButtonProps {
    label: string;
    onClick?: (_e: SyntheticEvent<HTMLButtonElement>) => void;
    btnClass?: string;
    disabled?: boolean;
    icon?: ReactElement;
    isReverse?: boolean;
    variant: "submit" | "cancel" | "add" | "upload" | "back";
    isLoading?: boolean;
    fullWidth?: boolean;
}

const LoadingButton: FC<LoadingButtonProps> = ({
    label,
    onClick,
    btnClass,
    disabled,
    icon,
    isReverse,
    variant,
    isLoading,
    fullWidth,
}) => (
    <Button
        className={`loading-button${
            isReverse ? "loading-button--reverse" : ""
        } loading-button--${variant} ${btnClass ?? ""} ${fullWidth ? "w-100" : ""}`}
        onClick={onClick}
        disabled={disabled}
    >
        {isLoading ? (
            <Loader size='sm' />
        ) : (
            <>
                <div>{icon}</div>
                <div>{label}</div>
            </>
        )}
    </Button>
);

export default LoadingButton;
