import { MsalProvider } from "@azure/msal-react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "bootstrap/dist/css/bootstrap.min.css";

import reportWebVitals from "./reportWebVitals";
import AppRoutes from "./routes/index";
import { MSALInstance } from "./services/Methods/api";
import AppContextProvider from "./utils/contexts/App.context";

import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <AppContextProvider>
        <BrowserRouter>
            <ToastContainer
                position='top-right'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='light'
            />
            <MsalProvider instance={MSALInstance}>
                <AppRoutes />
            </MsalProvider>
        </BrowserRouter>
    </AppContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
