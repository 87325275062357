import { FC } from "react";

import "./index.scss";

interface InactiveSubmissionProps {
    title: string;
    subTitle: string;
}

const InactiveSubmission: FC<InactiveSubmissionProps> = ({ title, subTitle }) => (
    <div className='inactive-submission'>
        <p>
            {title}
            <span>{subTitle}</span>
        </p>
    </div>
);

export default InactiveSubmission;
