import { t } from "i18next";
import { FC, useContext } from "react";
import { Container } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";

import Icons from "@app/assets/Icons";
import NotFoundPage from "@app/pages/auth/NotFound/index.page";
import SplashScreenPage from "@app/pages/auth/Splash/index.page";
import LicenseeRoutes from "@app/pages/licensee/index.page";
import DashboardRoutes from "@app/pages/main/index.page";
import { UserLevel } from "@app/types/User.type";
import { AppContext } from "@app/utils/contexts/App.context";

export const RouteNames = {
    Home: "/",
    Login: "login",
    Dashboard: "dashboard",
    Add: "add",
    Licensees: "licensee",
    Branches: "branches",
    Machines: "machines",
    Customizations: "customizations",
    Items: "items",
    Products: "products",
    Roles: "roles",
    Users: "users",
    Edit: "edit",
    SystemConfiguration: "systemConfiguration",
    LicenseeDashboard: "licensee/dashboard",
    SalesChannels: "sales-channels",
    BranchSettings: "branch-settings",
};

export const RoutePaths = {
    ViewLicensees: `/${RouteNames.Dashboard}/${RouteNames.Licensees}`,
    EditLicensees: `${RouteNames.Licensees}/${RouteNames.Edit}`,
    ViewMachines: `/${RouteNames.Dashboard}/${RouteNames.Machines}`,
    ViewCustomizations: `/${RouteNames.Dashboard}/${RouteNames.Customizations}`,
    ViewProducts: `/${RouteNames.Dashboard}/${RouteNames.Products}`,
    AddLicensees: `${RouteNames.Licensees}/${RouteNames.Add}`,
    AddBranches: `${RouteNames.Branches}/${RouteNames.Add}`,
    EditBranches: `${RouteNames.Branches}/${RouteNames.Edit}`,
    ViewBranches: `/${RouteNames.Dashboard}/${RouteNames.Branches}`,
    AddMachines: `${RouteNames.Machines}/${RouteNames.Add}`,
    EditMachines: `${RouteNames.Machines}/${RouteNames.Edit}`,
    EditProduct: `${RouteNames.Products}/${RouteNames.Edit}`,
    AddCustomizations: `${RouteNames.Customizations}/${RouteNames.Add}`,
    EditCustomizations: `${RouteNames.Customizations}/${RouteNames.Edit}`,
    AddItems: `${RouteNames.Items}/${RouteNames.Add}`,
    ViewItems: `/${RouteNames.Dashboard}/${RouteNames.Items}`,
    EditItems: `${RouteNames.Items}/${RouteNames.Edit}`,
    AddProducts: `${RouteNames.Products}/${RouteNames.Add}`,
    ViewRoles: `/${RouteNames.Dashboard}/${RouteNames.Roles}`,
    AddRoles: `${RouteNames.Roles}/${RouteNames.Add}`,
    EditRoles: `${RouteNames.Roles}/${RouteNames.Edit}`,
    AddUser: `${RouteNames.Users}/${RouteNames.Add}`,
    EditUser: `${RouteNames.Users}/${RouteNames.Edit}`,
    ViewUsers: `/${RouteNames.Dashboard}/${RouteNames.Users}`,
    SystemConfiguration: `${RouteNames.SystemConfiguration}`,
    LicenseeBranches: `${RouteNames.LicenseeDashboard}/${RouteNames.Branches}`,
    SelectMachines: `${RouteNames.LicenseeDashboard}/${RouteNames.Machines}`,
    SelectProducts: `${RouteNames.LicenseeDashboard}/${RouteNames.Products}`,
    SelectSaleChannels: `${RouteNames.LicenseeDashboard}/${RouteNames.SalesChannels}`,
    BranchSettings: `${RouteNames.LicenseeDashboard}/${RouteNames.BranchSettings}`,
};

const AppRoutes: FC = () => {
    const { appUser, isInitiliazing } = useContext(AppContext);

    return (
        <Routes>
            <Route index element={<SplashScreenPage />} />
            {appUser?.userLevel === UserLevel.Licensor && (
                <Route path={`${RouteNames.Dashboard}/*`} element={<DashboardRoutes />} />
            )}
            {appUser?.userLevel === UserLevel.Licensee && (
                <Route path={`${RouteNames.LicenseeDashboard}/*`} element={<LicenseeRoutes />} />
            )}
            <Route
                path={"*"}
                element={
                    isInitiliazing ? (
                        <Container fluid className='splash-screen'>
                            <img src={Icons.AlbaikLogoLarge} />
                            <h3>{t("LANDING.TITLE")}</h3>
                        </Container>
                    ) : (
                        <NotFoundPage />
                    )
                }
            />
        </Routes>
    );
};

export default AppRoutes;
