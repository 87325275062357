import { FC, useContext, useEffect, useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";

import Checkbox from "@app/components/inputs/Checkbox";
import { getCountries } from "@app/services/Endpoints/Country.API";
import { Country } from "@app/types/Countries.types";
import { FilterContext } from "@app/utils/contexts/Filters.context";

const LicenseeFilters: FC = () => {
    const {
        handleStatusesFilter,
        statusesFilter,
        setStatusesFilter,
        countriesFilter,
        handleCountriesFilter,
        setCountriesFilter,
    } = useContext(FilterContext);

    const [countries, setCountries] = useState<Country[]>([]);
    const [key, setKey] = useState<string | undefined | null>();

    useEffect(() => {
        getCountries()
            .then((res) => setCountries([...res.data.data]))
            .catch(() => setCountries([]));
    }, []);

    return (
        <Accordion
            activeKey={key}
            onSelect={(k) => setKey(String(k))}
            flush
            className='filters__item'
        >
            <Accordion.Item eventKey='0' className=''>
                <Accordion.Header className='filters__item-header'>
                    <span>Status</span>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            position: "absolute",
                            right: "20%",
                        }}
                        variant='text'
                        onClick={(e) => {
                            e.stopPropagation();
                            setStatusesFilter(undefined);
                        }}
                    >
                        Clear
                    </Button>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        <Col xs={12} className='filters__checkbox'>
                            <Checkbox
                                label='Active'
                                checked={!!statusesFilter?.includes(true)}
                                onClick={() => handleStatusesFilter(true)}
                            />
                        </Col>
                        <Col xs={12} className='filters__checkbox'>
                            <Checkbox
                                label='Inactive'
                                checked={!!statusesFilter?.includes(false)}
                                onClick={() => handleStatusesFilter?.(false)}
                            />
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
                <Accordion.Header className='filters__item-header'>
                    <span>Country</span>
                    <Button
                        className=''
                        style={{
                            color: "#fff",
                            textDecoration: "underline",
                            padding: 0,
                            outline: "none",
                            fontSize: "11px",
                            position: "absolute",
                            right: "20%",
                        }}
                        variant='text'
                        onClick={() => setCountriesFilter(undefined)}
                    >
                        Clear
                    </Button>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        {countries?.map((item, index) => (
                            <Col key={index} xs={12} className='filters__checkbox'>
                                <Checkbox
                                    label={item.name}
                                    checked={!!countriesFilter?.includes(item.id)}
                                    onClick={() => handleCountriesFilter(item.id)}
                                />
                            </Col>
                        ))}
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default LicenseeFilters;
