import { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ProductContainer from "@app/containers/product";
import { RoutePaths } from "@app/routes/index";
import { getProductById } from "@app/services/Endpoints/Product.API";
import { CreateProduct } from "@app/types/Product.types";

const EditProductPage: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [editProduct, setEditProduct] = useState<CreateProduct>();

    const fetchProductById = useCallback((id: string) => {
        getProductById(id)
            .then((res) => {
                if (res.data.data.items.length === 0) {
                    res.data.data.items = [
                        {
                            itemId: "",
                            quantity: "",
                            isPrimary: false,
                        },
                    ];
                }
                console.log(res.data.data.holdingTime);
                if (res.data.data.directDispensing) {
                    res.data.data.holdingTime = 0;
                    res.data.data.assemblyCapacity = 0;
                }
                setEditProduct({ ...res.data.data, id });
            })
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        if (!location.state?.productEditData) {
            navigate(RoutePaths.ViewProducts);
        } else {
            fetchProductById(location.state?.productEditData);
        }
    }, [location.state, navigate, fetchProductById]);

    return <ProductContainer product={editProduct} />;
};

export default EditProductPage;
