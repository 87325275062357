import AddBranchPage from "@app/pages/main/pages/AddBranch/index.page";
import AddCustomizationPage from "@app/pages/main/pages/AddCustomisation/index.page";
import AddItemPage from "@app/pages/main/pages/AddItem/index.page";
import AddLinceseePage from "@app/pages/main/pages/AddLicensee/index.page";
import AddMachinePage from "@app/pages/main/pages/AddMachine/index.page";
import AddProductPage from "@app/pages/main/pages/AddProduct/index.page";
import AddUserPage from "@app/pages/main/pages/AddUser";
import AddUserRolePage from "@app/pages/main/pages/AddUserRole/index.page";
import EditBranchPage from "@app/pages/main/pages/EditBranch/index.page";
import EditCustomizationPage from "@app/pages/main/pages/EditCustomization/index.page";
import EditItemPage from "@app/pages/main/pages/EditItem/index.page";
import EditLicenseePage from "@app/pages/main/pages/EditLicensee/index.page";
import EditMachinePage from "@app/pages/main/pages/EditMachine/index.page";
import EditProductPage from "@app/pages/main/pages/EditProduct/index.page";
import EditUserRolePage from "@app/pages/main/pages/EditRole/index.page";
import EditUserPage from "@app/pages/main/pages/EditUser";
import SystemConfigurationPage from "@app/pages/main/pages/SystemConfiguration/index.page";
import ViewBranchPage from "@app/pages/main/pages/ViewBranch/index.page";
import ViewCustomizationPage from "@app/pages/main/pages/ViewCustomisation/index.page";
import ViewItemPage from "@app/pages/main/pages/ViewItem/index.page";
import ViewLinceseePage from "@app/pages/main/pages/ViewLicensee/index.page";
import ViewMachinePage from "@app/pages/main/pages/ViewMachine/index.page";
import ViewProductPage from "@app/pages/main/pages/ViewProduct/index.page";
import ViewUserRolePage from "@app/pages/main/pages/ViewUserRole/index.page";
import ViewUsersPage from "@app/pages/main/pages/ViewUsers/index.page";
import { RouteNames, RoutePaths } from "@app/routes/index";
import { SidebarOptions, UserPermissions } from "@app/types/Common.types";

const SidebarData = (userPermissions?: UserPermissions): SidebarOptions[] => [
    {
        path: RouteNames.Licensees,
        label: "Licensees",
        primaryText: "View",
        secondaryText: "Licensees",
        element: <ViewLinceseePage />,
        disableSideLink: !userPermissions?.Read_Licensee,
        authorized: userPermissions?.Read_Licensee,
    },
    {
        path: RoutePaths.AddLicensees,
        label: "Add Licensee",
        primaryText: "Add",
        secondaryText: "Licensee",
        element: <AddLinceseePage />,
        disableSideLink: true,
        authorized: userPermissions?.Create_Licensee,
    },
    {
        path: RoutePaths.EditLicensees,
        label: "Edit Licensee",
        primaryText: "Edit",
        secondaryText: "Licensee",
        element: <EditLicenseePage />,
        disableSideLink: true,
        authorized: userPermissions?.Edit_Licensee,
    },
    {
        path: RouteNames.Branches,
        label: "Branches",
        primaryText: "View",
        secondaryText: "Branches",
        element: <ViewBranchPage />,
        disableSideLink: !userPermissions?.Read_Branch,
        authorized: userPermissions?.Read_Branch,
    },
    {
        path: RoutePaths.AddBranches,
        label: "Add Branch",
        primaryText: "Add",
        secondaryText: "Branch",
        element: <AddBranchPage />,
        disableSideLink: true,
        authorized: userPermissions?.Create_Branch,
    },
    {
        path: RoutePaths.EditBranches,
        label: "Edit Branch",
        primaryText: "Edit",
        secondaryText: "Branch",
        element: <EditBranchPage />,
        disableSideLink: true,
        authorized: userPermissions?.Edit_Branch,
    },
    {
        path: RouteNames.Machines,
        label: "Machines",
        primaryText: "View",
        secondaryText: "Machines",
        element: <ViewMachinePage />,
        disableSideLink: !userPermissions?.Read_Machine,
        authorized: userPermissions?.Read_Machine,
    },
    {
        path: RoutePaths.AddMachines,
        label: "Add Machine",
        primaryText: "Add",
        secondaryText: "Machine",
        element: <AddMachinePage />,
        disableSideLink: true,
        authorized: userPermissions?.Create_Machine,
    },
    {
        path: RoutePaths.EditMachines,
        label: "Edit Machine",
        primaryText: "Edit",
        secondaryText: "Machine",
        element: <EditMachinePage />,
        disableSideLink: true,
        authorized: userPermissions?.Edit_Machine,
    },
    {
        path: RouteNames.Customizations,
        label: "Customizations",
        primaryText: "View",
        secondaryText: "Customizations",
        element: <ViewCustomizationPage />,
        disableSideLink: !userPermissions?.Read_Customization,
        authorized: userPermissions?.Read_Customization,
    },
    {
        path: RoutePaths.AddCustomizations,
        label: "Add Customization",
        primaryText: "Add",
        secondaryText: "Customization",
        element: <AddCustomizationPage />,
        disableSideLink: true,
        authorized: userPermissions?.Create_Customization,
    },
    {
        path: RoutePaths.EditCustomizations,
        label: "Edit Customization",
        primaryText: "Edit",
        secondaryText: "Customization",
        element: <EditCustomizationPage />,
        disableSideLink: true,
        authorized: userPermissions?.Edit_Customization,
    },
    {
        path: RouteNames.Items,
        label: "Items",
        primaryText: "View",
        secondaryText: "Items",
        element: <ViewItemPage />,
        disableSideLink: !userPermissions?.Read_Item,
        authorized: userPermissions?.Read_Item,
    },
    {
        path: RoutePaths.AddItems,
        label: "Add Item",
        primaryText: "Add",
        secondaryText: "Item",
        element: <AddItemPage />,
        disableSideLink: true,
        authorized: userPermissions?.Create_Item,
    },
    {
        path: RoutePaths.EditItems,
        label: "Edit Item",
        primaryText: "Edit",
        secondaryText: "Item",
        element: <EditItemPage />,
        disableSideLink: true,
        authorized: userPermissions?.Edit_Item,
    },
    {
        path: RouteNames.Products,
        label: "Products",
        primaryText: "View",
        secondaryText: "Products",
        element: <ViewProductPage />,
        disableSideLink: !userPermissions?.Read_Product,
        authorized: userPermissions?.Read_Product,
    },
    {
        path: RoutePaths.EditProduct,
        label: "Edit Product",
        primaryText: "Edit",
        secondaryText: "Product",
        element: <EditProductPage />,
        disableSideLink: true,
        authorized: userPermissions?.Edit_Product,
    },
    {
        path: RoutePaths.AddProducts,
        label: "Add Product",
        primaryText: "Add",
        secondaryText: "Product",
        element: <AddProductPage />,
        disableSideLink: true,
        authorized: userPermissions?.Create_Product,
    },
    {
        path: RouteNames.Roles,
        label: "User Roles",
        primaryText: "View",
        secondaryText: "User Roles",
        element: <ViewUserRolePage />,
        disableSideLink: !userPermissions?.Create_User_Role,
        authorized: userPermissions?.Create_User_Role,
    },
    {
        path: RoutePaths.AddRoles,
        label: "Add User Role",
        primaryText: "Add",
        secondaryText: "User Role",
        element: <AddUserRolePage />,
        disableSideLink: true,
        authorized: userPermissions?.Create_User_Role,
    },
    {
        path: RoutePaths.EditRoles,
        label: "Edit User Role",
        primaryText: "Edit",
        secondaryText: "User Role",
        element: <EditUserRolePage />,
        disableSideLink: true,
        authorized: userPermissions?.Edit_User_Role,
    },
    {
        path: RouteNames.Users,
        label: "Users",
        primaryText: "View",
        secondaryText: "Users",
        element: <ViewUsersPage />,
        disableSideLink: !userPermissions?.Read_User,
        authorized: userPermissions?.Read_User,
    },
    {
        path: RoutePaths.AddUser,
        label: "Users",
        primaryText: "Add",
        secondaryText: "User",
        element: <AddUserPage />,
        disableSideLink: true,
        authorized: userPermissions?.Create_User,
    },
    {
        path: RoutePaths.EditUser,
        label: "Users",
        primaryText: "Edit",
        secondaryText: "User",
        element: <EditUserPage />,
        disableSideLink: true,
        authorized: userPermissions?.Edit_User,
    },
    {
        path: RoutePaths.SystemConfiguration,
        label: "System Configuration",
        primaryText: "System",
        secondaryText: "Configuration",
        element: <SystemConfigurationPage />,
        disableSideLink: !userPermissions?.Create_System_Config,
        authorized: userPermissions?.Create_System_Config,
    },
];

export default SidebarData;
