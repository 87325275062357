import "./index.scss";
import { FC, ReactNode, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import LoadingButton from "@app/components/Button";
import { AppContext } from "@app/utils/contexts/App.context";

interface ModalProps {
    open: boolean;
    onClose?: () => void;
    showFooter?: boolean;
    header?: ReactNode | string;
    children: ReactNode;
    size?: "sm" | "lg" | "xl";
    onDone?: () => void;
    onCancel?: () => void;
    doneDisabled?: boolean;
    doneCTALabel?: string;
    cancelCTALabel?: string;
    contentClassName?: string;
    backdropClassName?: string;
    submitVariant?: "cancel" | "submit" | "add" | "upload";
    cancelVariant?: "cancel" | "submit" | "add" | "upload";
}

const BootstrapModal: FC<ModalProps> = ({
    header,
    children,
    showFooter,
    open,
    onClose,
    size,
    onDone,
    onCancel,
    doneDisabled,
    doneCTALabel = "Done",
    cancelCTALabel = "Cancel",
    contentClassName,
    backdropClassName,
    submitVariant,
    cancelVariant,
}) => {
    const { isLoading } = useContext(AppContext);

    return (
        <Modal
            show={open}
            onHide={() => (onClose ? onClose() : undefined)}
            size={size}
            aria-labelledby='contained-modal-title-vcenter'
            centered
            contentClassName={contentClassName}
            backdropClassName={backdropClassName}
        >
            {header && (
                <Modal.Header className='popupmodel__header' closeButton closeVariant='white'>
                    <Modal.Title
                        className='popupmodel__headercontent'
                        id='contained-modal-title-vcenter'
                    >
                        {header}
                    </Modal.Title>
                </Modal.Header>
            )}
            <Modal.Body>
                <div className='popupmodel__bodycontainer'>{children}</div>
            </Modal.Body>
            {showFooter && (
                <Modal.Footer className='popupmodel__btNone'>
                    <Row className='popupmodel__footerContainer'>
                        <Col>
                            <LoadingButton
                                disabled={doneDisabled}
                                label={doneCTALabel}
                                variant={cancelVariant || "cancel"}
                                onClick={onCancel}
                            />
                        </Col>
                        <Col>
                            <LoadingButton
                                label={cancelCTALabel}
                                variant={submitVariant || "submit"}
                                onClick={onDone}
                                disabled={isLoading}
                            />
                        </Col>
                    </Row>
                </Modal.Footer>
            )}
        </Modal>
    );
};
export default BootstrapModal;
