import { t } from "i18next";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Icons from "@app/assets/Icons";
import Loader from "@app/components/Loader";
import Pagination from "@app/components/Pagination";
import TableHeader from "@app/components/TableHeader";
import ViewUtilityHeader from "@app/components/ViewUtilityHeader";
import { RouteNames } from "@app/routes/index";
import { getCustomisationsData } from "@app/services/Endpoints/Customization";
import { HeadersMapInterface } from "@app/types/Common.types";
import { Customisation } from "@app/types/Service.type";
import { AppContext } from "@app/utils/contexts/App.context";
import { FilterContext } from "@app/utils/contexts/Filters.context";

import "./index.scss";

let totalPages = 0;
const sortByEnum = {
    DESC: 1,
    ASC: 0,
};

const ViewCustomizationPage: FC = () => {
    const { userPermissions } = useContext(AppContext);
    const { statusesFilter } = useContext(FilterContext);
    const navigate = useNavigate();
    const [customisations, setCustomisations] = useState<Customisation[]>([]);
    const [sortByObj, setSortByObj] = useState({ sortBy: "status", sortOrder: sortByEnum.DESC });
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loader, setLoader] = useState<boolean>(false);
    const [searchVal, setSearchVal] = useState<string>("");
    const [isScrollComing, setIsScrollComing] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useRef<any>();
    const HeadersMap: HeadersMapInterface = {
        serialNo: {
            label: t("VIEW.CUSTOMISATION.SERIALNO"),
            layout: { lg: 1, sm: 1 },
            disableSort: true,
        },
        name: { label: t("VIEW.CUSTOMISATION.NAME"), layout: { lg: 2, sm: 2 } },
        displayName: {
            label: t("VIEW.CUSTOMISATION.DISPLAYNAME"),
            layout: { lg: 3, sm: 3 },
        },
        code: { label: t("VIEW.CUSTOMISATION.CUSTOMISATIONID"), layout: { lg: 3, sm: 3 } },
        status: { label: t("VIEW.CUSTOMISATION.STATUS"), layout: { lg: 2, sm: 2 } },
    };

    useEffect(() => {
        setPageNumber(1);
    }, [statusesFilter]);

    useEffect(() => {
        const filters = statusesFilter?.map((status) => `&status=${status}`).join("");
        getCustomisations(
            sortByObj.sortBy,
            sortByObj.sortOrder,
            pageNumber,
            pageSize,
            searchVal,
            filters,
        );
    }, [pageNumber, pageSize, sortByObj, searchVal, statusesFilter]);

    useEffect(() => {
        if (ref.current?.offsetWidth != ref.current?.clientWidth) {
            setIsScrollComing(true);
        } else {
            setIsScrollComing(false);
        }
    });

    const getCustomisations = (
        sortBy: string,
        sortOrder: number,
        pageNumber: number,
        pageSize: number,
        name?: string,
        filters?: string,
    ) => {
        setLoader(true);
        getCustomisationsData(sortBy, sortOrder, pageNumber, pageSize, name, filters)
            .then((res) => {
                totalPages = res.data.totalPages;
                const result = res?.data?.data;
                if (result) {
                    setCustomisations([...result]);
                } else {
                    setCustomisations([]);
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setLoader(false));
    };

    const sortByHandler = (headerKey: string) => {
        setSortByObj({ sortBy: headerKey, sortOrder: Number(!sortByObj.sortOrder) });
    };

    const onChangeRecordsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setPageSize(Number(value));
        setPageNumber(1);
    };

    const onPageChange = (pageNumber: number) => {
        setPageNumber(Number(pageNumber));
    };
    const onEdit = (customisationData: Customisation) => {
        navigate(RouteNames.Edit, {
            state: { customisationEditData: customisationData },
        });
    };
    const redirectToAddCustomisation = () => {
        navigate(RouteNames.Add);
    };

    const handleSearch = (val: string) => {
        setSearchVal(val);
    };
    return (
        <Container fluid className='customisation'>
            <ViewUtilityHeader
                onChange={onChangeRecordsPerPage}
                placeholder='Search for Customization name'
                btnLabel={t("VIEW.CUSTOMISATION.ADDCUSTOMISATION")}
                onClick={
                    userPermissions?.Create_Customization ? redirectToAddCustomisation : undefined
                }
                onSearch={handleSearch}
            />

            <TableHeader
                headersMap={HeadersMap}
                sortByHandler={sortByHandler}
                sortByObj={sortByObj}
                enableIconColumn
            />

            <div
                className={`customisation--page ${isScrollComing ? "customisation--isScroll" : ""}`}
                id='listing-Outercontainer'
                ref={ref}
            >
                {loader ? (
                    <Loader />
                ) : customisations.length ? (
                    customisations.map((item, index) => (
                        <Row
                            key={index}
                            className={`customisation__rowWrap ${
                                index % 2 == 0 && "customisation__rowGrey"
                            }`}
                        >
                            <Col className='customisation--pl20' sm='1' lg='1'>
                                {pageNumber == 1 && index < 9 ? "0" : ""}
                                {(pageNumber - 1) * 10 + (index + 1)}
                            </Col>
                            <Col sm='2' lg='2'>
                                {item.name}
                            </Col>

                            <Col sm='3' lg='3'>
                                {item.displayName}
                            </Col>
                            <Col sm='3' lg='3'>
                                {item.code}
                            </Col>
                            <Col sm='2' lg='2'>
                                {item.status
                                    ? t("VIEW.CUSTOMISATION.ACTIVE")
                                    : t("VIEW.CUSTOMISATION.INACTIVE")}
                            </Col>
                            <Col sm='1' lg='1'>
                                {userPermissions?.Edit_Customization && (
                                    <img
                                        className='customisation__enablepointer'
                                        src={Icons.EditIcon}
                                        onClick={() => onEdit(item)}
                                        height='20px'
                                    />
                                )}
                            </Col>
                        </Row>
                    ))
                ) : (
                    <div className='customisation--noData'>{t("APIMSG.NODATA")}</div>
                )}
            </div>
            <Pagination
                sortByObj={sortByObj}
                onPageChange={onPageChange}
                totalPages={totalPages}
                pageSize={pageSize}
                pageNumber={pageNumber}
            />
        </Container>
    );
};

export default ViewCustomizationPage;
