import { MasterAPIService } from "@app/services/Methods/api";
import { SystemConfigurationResponse } from "@app/types/API.types";
import { SystemConfigurationFormData } from "@app/types/SystemConfiguration";

export const addSystemConfiguration = (systemConfiguration: SystemConfigurationFormData) =>
    MasterAPIService.post<SystemConfigurationResponse>(
        "/common/SaveSystemConfiguration",
        systemConfiguration,
    );

export const getSystemConfiguration = () =>
    MasterAPIService.get<SystemConfigurationResponse>("/common/GetSystemConfiguration");
