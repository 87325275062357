import { MasterAPIService } from "@app/services/Methods/api";
import {
    CitiesResponse,
    CityResponse,
    CountryResponse,
    RegionResponse,
} from "@app/types/API.types";

export const getCountries = () => MasterAPIService.get<CountryResponse>("/common/countries");

export const getRegions = (countryId: string) =>
    MasterAPIService.get<RegionResponse>(`/common/regions/${countryId}`);

export const getCountryCities = (countryId: string) =>
    MasterAPIService.get<CityResponse>(`/common/cities/${countryId}`);

export const getRegionCities = (countryId: string, regionId: string) =>
    MasterAPIService.get<CityResponse>(`/common/cities/${countryId}/${regionId}`);

export const getCities = (params?: string) =>
    MasterAPIService.get<CitiesResponse>(`/common/cities${params ? `?${params}` : ""}`);
