import { t } from "i18next";
import { ChangeEvent, FC, useContext } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import Icons from "@app/assets/Icons";
import LoadingButton from "@app/components/Button";
import SelectInput from "@app/components/inputs/Select";
import TextInput from "@app/components/inputs/Text";
import ToggleComponent from "@app/components/inputs/Toggle";
import { Errors } from "@app/types/Common.types";
import { LicenseeFormData } from "@app/types/Licensee";
import { UserDetails } from "@app/types/User.type";
import { AppContext } from "@app/utils/contexts/App.context";
import { FormContext } from "@app/utils/contexts/Form.context";

interface LicenseeFormProps {
    data: LicenseeFormData;
    onChange: (_name: string, _value: string | boolean) => void;
    countryOptions: { value: string; label: string }[];
    rolesOptions: { value: string; label: string }[];
    errors?: Partial<Errors>;
    isEdit?: boolean;
    onBlur: (_value: string) => void;
    currentAdminDetails: Partial<UserDetails>;
    addAdmin: () => void;
    licenseeAdminList: { [key: string]: Partial<UserDetails> };
    onDeleteAdmin: (_item: Partial<UserDetails>) => void;
    disableAddAdmin: boolean;
}

const LicenseeForm: FC<LicenseeFormProps> = ({
    data,
    onChange,
    errors,
    countryOptions,
    rolesOptions,
    isEdit,
    onBlur,
    currentAdminDetails,
    addAdmin,
    licenseeAdminList,
    onDeleteAdmin,
    disableAddAdmin,
}) => {
    const { setIsDirty } = useContext(FormContext);
    const { userPermissions } = useContext(AppContext);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setIsDirty(true);
        const { name, value } = e.target;
        onChange(name, value);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target;
        onBlur(value);
    };

    const renderLicenseeAdmin = (item: Partial<UserDetails>) => (
        <div className='mt-4 mb-4'>
            <Row className='mb-4'>
                <Col xs={9}>
                    <TextInput
                        label={`${t("ADD.LICENSEE.ADMIN.LABEL")}`}
                        placeholder={t("ADD.LICENSEE.ADMIN.PLACEHOLDER")}
                        value={item.mail || ""}
                        onChange={handleChange}
                        name={`admin-${item.mail}`}
                        error={errors?.adminEmail}
                        disabled
                    />
                </Col>
                <Col xs={3} className='py-4 px-2'>
                    <div className='role-form__userInfo'>{item.displayName}</div>
                    <div className='role-form__userInfo'>{item.mobilePhone}</div>
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <SelectInput
                        label={`${t("ADD.LICENSEE.ROLE.LABEL")}`}
                        value={item?.roleId || ""}
                        onChange={handleChange}
                        name={item.mail || ""}
                        options={[
                            { value: "", label: t("ADD.LICENSEE.ROLE.PLACEHOLDER") },
                            ...rolesOptions,
                        ]}
                        error={errors?.role}
                    />
                </Col>
                <Col xs={3} className='mt-4 py-1'>
                    <LoadingButton
                        icon={<img src={Icons.DeleteIcon} />}
                        label='Delete'
                        variant='upload'
                        onClick={() => {
                            setIsDirty(true);
                            onDeleteAdmin(item);
                        }}
                        btnClass='viewHeader__add-button role-form--btn'
                    />
                </Col>
            </Row>
        </div>
    );
    return (
        <Container fluid className='py-4 px-5'>
            <Row className='mb-4'>
                <Col xs={12}>
                    <TextInput
                        label={`${t("ADD.LICENSEE.NAME.LABEL")}`}
                        placeholder={t("ADD.LICENSEE.NAME.PLACEHOLDER")}
                        value={data.name || ""}
                        onChange={handleChange}
                        name='name'
                        error={errors?.name}
                        disabled={isEdit}
                    />
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col xs={12}>
                    <TextInput
                        label={`${t("ADD.LICENSEE.ID.LABEL")}`}
                        placeholder={t("ADD.LICENSEE.ID.PLACEHOLDER")}
                        value={data.code || ""}
                        onChange={handleChange}
                        name='code'
                        error={errors?.code}
                        disabled={isEdit}
                    />
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col xs={12}>
                    <SelectInput
                        label={`${t("ADD.LICENSEE.COUNTRY.LABEL")}`}
                        value={data.countryId}
                        onChange={handleChange}
                        name='countryId'
                        options={[
                            { value: "", label: t("ADD.LICENSEE.COUNTRY.PLACEHOLDER") },
                            ...countryOptions,
                        ]}
                        error={errors?.countryId}
                        disabled={isEdit}
                    />
                </Col>
            </Row>

            {Object.keys(licenseeAdminList)?.map((key: string) =>
                renderLicenseeAdmin(licenseeAdminList[key]),
            )}

            <Row className='mb-4'>
                <Col xs={9}>
                    <TextInput
                        label={`${t("ADD.LICENSEE.ADMIN.LABEL")}`}
                        placeholder={t("ADD.LICENSEE.ADMIN.PLACEHOLDER")}
                        value={data.admin || ""}
                        onChange={handleChange}
                        name='admin'
                        error={errors?.admin}
                        onBlur={handleBlur}
                    />
                </Col>
                <Col xs={3} className='py-4 px-2'>
                    <div className='role-form__userInfo'>{currentAdminDetails.displayName}</div>
                    <div className='role-form__userInfo'>{currentAdminDetails.mobilePhone}</div>
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <SelectInput
                        label={`${t("ADD.LICENSEE.ROLE.LABEL")}`}
                        value={data.roleId || ""}
                        onChange={handleChange}
                        name='roleId'
                        options={[
                            { value: "", label: t("ADD.LICENSEE.ROLE.PLACEHOLDER") },
                            ...rolesOptions,
                        ]}
                        error={errors?.roleId}
                    />
                </Col>
                <Col xs={3} className='mt-4 py-1'>
                    <LoadingButton
                        icon={<img src={Icons.PlusIcon} className='viewHeader__plusIcon' />}
                        label='Add Admin'
                        variant='upload'
                        onClick={addAdmin}
                        btnClass='viewHeader__add-button role-form--btn'
                        disabled={disableAddAdmin}
                    />
                </Col>
            </Row>
            {isEdit && userPermissions?.Deactivate_Licensee && (
                <Row className='mt-4'>
                    <Col>
                        <Form.Group className='d-flex align-items-end gap-4'>
                            <Form.Label className='text-input__label'>
                                {t("MACHINE.CREATE.STATUS.LABEL")}
                            </Form.Label>
                            <ToggleComponent
                                isActive={data?.isActive}
                                onToggle={() => {
                                    setIsDirty(true);
                                    onChange("isActive", !data.isActive);
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default LicenseeForm;
