import { t } from "i18next";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FormWrapper from "@app/components/FormWrapper";
import MachineForm from "@app/components/forms/Machine.form";
import { RoutePaths } from "@app/routes/index";
import {
    createMachine,
    getMachineItems,
    getMachineManufacturers,
    getMachineTypes,
    updateMachine,
} from "@app/services/Endpoints/Machine.API";
import { getStations } from "@app/services/Endpoints/Station";
import { DisplayData, Errors, ModalType, ScreenType } from "@app/types/Common.types";
import { MachineFormData } from "@app/types/Machine";
import { Machines } from "@app/types/Service.type";
import { Station, StationList } from "@app/types/Station.types";
import { FormContext } from "@app/utils/contexts/Form.context";

const initialFormData: MachineFormData = {
    machineMake: "",
    newMachineManufacturer: "",
    machineName: "",
    noOfBaskets: "",
    machineType: "",
    applicableStations: [],
};

interface MachineContainerProps {
    machine?: Machines;
}

const MachineContainer: FC<MachineContainerProps> = ({ machine }) => {
    const navigate = useNavigate();

    const { triggerShowModal, setActions, isDirty, setIsDirty, setScreen, setDataToDisplay } =
        useContext(FormContext);

    const [machineMakes, setMachineMakes] = useState<{ machineMake: string }[]>([]);
    const [machineTypes, setMachineTypes] = useState<{ name: string }[]>([]);
    const [cookingStations, setCookingStations] = useState<Station[]>([]);
    const [newMachineData, setNewMachineData] = useState<MachineFormData>(initialFormData);
    const [machineItems, setMachineItems] = useState<DisplayData[]>([]);
    const [errors, setErrors] = useState<Partial<Errors>>();

    const fetchMachineMakes = useCallback(() => {
        getMachineManufacturers()
            .then((res) => setMachineMakes([...res.data.data]))
            .catch(() => setMachineMakes([]));
    }, []);

    const fetchMachineTypes = useCallback(() => {
        getMachineTypes()
            .then((res) => setMachineTypes([...res.data.data]))
            .catch(() => setMachineTypes([]));
    }, []);

    const fetchStations = useCallback(() => {
        getStations().then((res) => {
            const cookingStations =
                res?.data?.data?.find((station: StationList) => station.stationType === "Cooking")
                    .stations ?? [];
            setCookingStations([...cookingStations]);
        });
    }, []);

    const fetchMachineItems = useCallback(() => {
        if (machine?.id) {
            getMachineItems(machine?.id)
                .then((res) => setMachineItems([...(res?.data?.data ?? [])]))
                .catch(() => setMachineItems([]));
        }
    }, [machine]);

    useEffect(() => {
        fetchMachineMakes();
        fetchMachineTypes();
        fetchStations();
        fetchMachineItems();
    }, [fetchMachineMakes, fetchMachineTypes, fetchStations, fetchMachineItems]);

    useEffect(() => {
        if (machine) {
            const {
                machineMake,
                machineName,
                noOfBaskets,
                machineType,
                applicableStations,
                status,
            } = {
                ...machine,
            };
            setNewMachineData({
                machineMake,
                machineName,
                noOfBaskets,
                machineType,
                applicableStations: applicableStations?.map((station) => station.name),
                newMachineManufacturer: "",
                status: Number(status),
            });
        }
    }, [machine]);

    const handleChange = (name: string, value: string | string[] | number) => {
        const data: MachineFormData = { ...newMachineData };
        if (name === "machineMake" && value !== "new machine manufacturer")
            data.newMachineManufacturer = "";
        if (errors?.[name]) {
            setErrors({ ...errors, [name]: "" });
        }
        setNewMachineData({ ...data, [name]: value });
    };

    const handleCancel = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    setNewMachineData(initialFormData);
                    setErrors(undefined);
                    navigate(RoutePaths.ViewMachines);
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        } else navigate(RoutePaths.ViewMachines);
    };

    const handleCreateMachine = () => {
        createMachine({ ...newMachineData, noOfBaskets: Number(newMachineData.noOfBaskets) })
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                navigate(RoutePaths.ViewMachines);
                setIsDirty(false);
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleUpdateMachine = () =>
        updateMachine(String(machine?.id), {
            ...newMachineData,
            noOfBaskets: Number(newMachineData.noOfBaskets),
            // Active = 1, Inactive = 2
            status: newMachineData.status ? newMachineData.status : 2,
            newMachineManufacturer: undefined,
        })
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                navigate(RoutePaths.ViewMachines);
                setIsDirty(false);
            })
            .catch((error) => {
                console.log(error);
            });

    const handleSubmit = () => {
        const {
            machineMake,
            newMachineManufacturer,
            applicableStations,
            machineName,
            machineType,
            noOfBaskets,
            status,
        } = newMachineData;
        const isMakeValid =
            machineMake === "new machine manufacturer" ? !!newMachineManufacturer : !!machineMake;
        const isNameValid = !!machineName;
        const isTypeValid = !!machineType;
        const isBasketValid = Number(noOfBaskets) > 0;
        const isBasketZero = Number(noOfBaskets) === 0;
        const isStationValid = applicableStations.length > 0;
        const isDataValid =
            isNameValid && isMakeValid && isStationValid && isTypeValid && isBasketValid;
        const isInactiveSubmissionWithItems =
            Number(status) === 2 && machineItems.length && isDataValid;
        const isInactiveSubmissionWithoutItems = Number(status) === 2 && isDataValid;

        if (isInactiveSubmissionWithItems) {
            setScreen(ScreenType.INACTIVE_MACHINE_DENIED);
            setDataToDisplay(machineItems);
            triggerShowModal(true, ModalType.INACTIVE_MACHINE_DENIED);
            setActions({
                done: () => {
                    triggerShowModal(false, ModalType.INACTIVE_MACHINE_DENIED);
                    setScreen(undefined);
                    setDataToDisplay([]);
                    navigate(RoutePaths.ViewItems, {
                        state: { machineId: machine?.id, machineName: machine?.machineName },
                    });
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.INACTIVE_MACHINE_DENIED);
                    setScreen(undefined);
                    setDataToDisplay([]);
                    setNewMachineData({ ...newMachineData, status: 1 });
                },
            });
        } else if (isInactiveSubmissionWithoutItems) {
            setScreen(ScreenType.MACHINE);
            setDataToDisplay(machineItems);
            triggerShowModal(true, ModalType.INACTIVE_SUBMISSION);
            setActions({
                done: async () => {
                    await handleUpdateMachine();
                    triggerShowModal(false, ModalType.INACTIVE_SUBMISSION);
                    setScreen(undefined);
                    setDataToDisplay([]);
                    navigate(RoutePaths.ViewMachines);
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.INACTIVE_SUBMISSION);
                    setScreen(undefined);
                    setDataToDisplay([]);
                    setNewMachineData({ ...newMachineData, status: 1 });
                },
            });
        } else if (isDataValid) {
            setErrors(undefined);
            triggerShowModal(true, ModalType.SUBMIT);
            setActions({
                done: () => (machine ? handleUpdateMachine() : handleCreateMachine()),
                cancel: () => {
                    triggerShowModal(false, ModalType.SUBMIT);
                    setNewMachineData({ ...newMachineData, status: 1 });
                },
            });
        } else {
            const currentErrors: Partial<Errors> = {};
            if (!isMakeValid && machineMake !== "new machine manufacturer")
                currentErrors.machineMake = `${t("ERRORS.REQUIRED", {
                    name: t("MACHINE.CREATE.MAKE.LABEL"),
                })}`;
            if (
                !isMakeValid &&
                machineMake === "new machine manufacturer" &&
                !newMachineManufacturer
            )
                currentErrors.newMachineManufacturer = `${t("ERRORS.REQUIRED", {
                    name: t("MACHINE.CREATE.MAKE.NEW"),
                })}`;
            if (!isNameValid)
                currentErrors.machineName = `${t("ERRORS.REQUIRED", {
                    name: t("MACHINE.CREATE.NAME.LABEL"),
                })}`;
            if (!isTypeValid)
                currentErrors.machineType = `${t("ERRORS.REQUIRED", {
                    name: t("MACHINE.CREATE.TYPE.LABEL"),
                })}`;
            if (!isBasketValid)
                currentErrors.noOfBaskets = `${t("ERRORS.REQUIRED", {
                    name: t("MACHINE.CREATE.BASKET.LABEL"),
                })}`;
            if (isBasketZero)
                currentErrors.noOfBaskets = `${t("ERRORS.LESS_THAN_ONE", {
                    name: t("MACHINE.CREATE.BASKET.LABEL"),
                    number: 0,
                })}`;
            if (!isStationValid)
                currentErrors.applicableStations = `${t("ERRORS.REQUIRED", {
                    name: t("MACHINE.CREATE.STATION.LABEL"),
                })}`;
            setErrors({ ...errors, ...currentErrors });
        }
    };

    return (
        <FormWrapper
            heading={
                window.location.href.includes("edit")
                    ? "Edit Machine details"
                    : t("MACHINE.CREATE.HEADING")
            }
            onCancelClick={handleCancel}
            onSubmitClick={handleSubmit}
            submitDisabled={!isDirty}
        >
            <MachineForm
                data={newMachineData}
                onChange={handleChange}
                machineMakeOptions={machineMakes?.map(({ machineMake }) => ({
                    value: machineMake,
                    label: machineMake,
                }))}
                machineTypeOptions={machineTypes?.map(({ name }) => ({ value: name, label: name }))}
                stations={cookingStations}
                errors={errors}
                isEdit={!!machine}
            />
        </FormWrapper>
    );
};

export default MachineContainer;
